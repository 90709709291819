import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Stack,
  Typography,
  Alert,
  AlertTitle,
  Skeleton,
  Box,
  MenuItem,
  TextField,
  Drawer,
  Paper,
  Hidden,
  Grid,
  Tooltip
} from "@mui/material";
import _ from "lodash";
import Carousel from "components/common/carousel";
import Thumbnail from "components/common/Thumbnail";
import { useAxios, customAxios } from "hooks/useAxios";
import MySwal from "utils/Swal";
import useResponsive from "hooks/useResponsive";
import TemplateDrawer from "./TemplateDrawer";
import { saveAs } from "file-saver";
import { useAuth } from "hooks/useAuth";
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';

const allFilter = () => {
  return true;
};

const badgeFilter = (template) => {
  if (template?.dimensions) {
    const aspectRatio = template.dimensions.width / template.dimensions.height;
    return aspectRatio < 1.1 && aspectRatio > 0.9;
  }
  return false;
};

const certificateFilter = (template) => {
  if (template?.dimensions) {
    const aspectRatio = template.dimensions.width / template.dimensions.height;
    return aspectRatio >= 1.1 || aspectRatio <= 0.9;
  }
  return false;
};

const documentFilter = (template) => {
  if (template?.templateType !== "HTML") {
    return true;
  }
  return false;
};

export default function TemplateCarousel(props) {
  const { user } = useAuth();
  const { title, fetchEndpoint, firstSlide, isPrivate, type } = props;
  const { isMobile, isTablet, isDesktop } = useResponsive();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const draftId = searchParams.get("draftId");
  const [filterType, setFilterType] = useState(type || "All");
  const [showAll, setShowAll] = useState(false);
  const [data, isLoading, error, refresh] = useAxios(fetchEndpoint);
  const searchText = searchParams.get("search");

  const filterMap = {
    All: allFilter,
    Badge: badgeFilter,
    Certificate: certificateFilter,
    "Document (.docx)": documentFilter,
  };

  const deleteTemplate = (templateId) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "Do you want to delete the template?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then(async ({ isConfirmed }) => {
      if (isConfirmed) {
        const response = await customAxios.delete(
          `certificate/deleteCertificateTemplate?templateId=${templateId}`,
        );
        if (response) {
          refresh();

          MySwal.fire({
            text: "Template deleted successfully!",
            icon: "success",
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
    });
  };

  const downloadTemplate = async (templateId) => {
    const response = await customAxios.get(
      `certificate/dowloadCertificateTemplate?docType=${templateId}`,
    );

    saveAs(response?.data?.response?.certificateTemplates);
  };

  const handleClick = async (template) => {
    let draftIdTemp = draftId;
    if (!draftIdTemp) {
      const { data } = await customAxios.post("draft", {
        draft: { templateId: template?._id },
      });
      draftIdTemp = data._id;
    } else {
      await customAxios.put(`draft/${draftIdTemp}`, {
        draft: { templateId: template?._id },
      });
    }
    navigate(
      `${process.env.REACT_APP_BASE_ROUTE}/issuance/create/settings/?draftId=${draftIdTemp}`,
    );
  };

  const filteredTemplates =
    data?.response?.certificateTemplates
      ?.filter?.(filterMap[filterType])
      ?.filter?.(
        (template) =>
          !searchText?.trim?.() ||
          template?.title
            ?.toLowerCase?.()
            ?.includes?.(searchText?.trim?.()?.toLowerCase()),
      ) || [];

  const sharedWithinOrgFlag = (
    <Box sx={{ position: 'absolute', top: '-25px', left: '-56px', backgroundColor: 'orange', color: '#fff', padding: '30px 50px 0px', transform: 'rotate(-42deg)', lineHeight: '1' }}>
      <Tooltip title="Shared within organization">
        <ShareOutlinedIcon fontSize="small" />
      </Tooltip>
    </Box>
  )

  return (
    <Paper elevation={0} sx={{ pt: 3, pb: 2, px: 4 }}>
      <Stack
        spacing={1.5}
        sx={!isMobile && { ".swiper-slide": { width: "220px" } }}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h6">{title}</Typography>
          {!type && (
            <Stack direction="row" alignItems="center" spacing={1}>
              <Hidden smDown>
                <Typography variant="body2">Filter: </Typography>
              </Hidden>
              <TextField
                select
                value={filterType}
                size="small"
                className="extra-small"
                onChange={(e) => setFilterType(e.target.value)}
                sx={{ width: { xs: "120px", sm: "170px" } }}
              >
                {Object.keys(filterMap).map((filterName) => (
                  <MenuItem key={filterName} value={filterName}>
                    {filterName}
                  </MenuItem>
                ))}
              </TextField>
            </Stack>
          )}
        </Box>
        <Box minHeight={182}>
          {error ? (
            <Alert severity="error">
              <AlertTitle>Unable to load templates</AlertTitle>
              {error?.response?.data?.message}
            </Alert>
          ) : showAll ? (
            <Grid container spacing={4}>
              {firstSlide ? (
                <Grid item xs={6} sm={4} md={3} lg={2.4}>
                  {firstSlide}
                </Grid>
              ) : null}
              {filteredTemplates.map((template) => (
                <Grid key={template?._id} item xs={6} sm={4} md={3} lg={2.4}>
                  <Thumbnail
                    url={template?.thumb?.large}
                    templateId={template?._id}
                    height="140px"
                    title={template?.title}
                    isPrivate={isPrivate}
                    isDocx={template?.templateType !== "HTML"}
                    showOptions
                    deleteTemplate={deleteTemplate}
                    downloadTemplate={downloadTemplate}
                    onClick={() => handleClick(template)}
                    flag={(user.company.id === template.company && template?.scope !== 'USER') && sharedWithinOrgFlag}
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            <Carousel
              slidesPerView={"auto"}
              slides={
                isLoading
                  ? _.times(5, (i) => (
                    <Skeleton key={i} variant="rectangular" height={182} />
                  ))
                  : [
                    ...(firstSlide ? [firstSlide] : []),
                    ...filteredTemplates.map((template) => (
                      <Thumbnail
                        key={template?._id}
                        url={template?.thumb?.large}
                        templateId={template?._id}
                        height="140px"
                        title={template?.title}
                        isPrivate={isPrivate}
                        isDocx={template?.templateType !== "HTML"}
                        showOptions
                        deleteTemplate={deleteTemplate}
                        downloadTemplate={downloadTemplate}
                        onClick={() => handleClick(template)}
                        flag={(user.company.id === template.company && template?.scope !== 'USER') && sharedWithinOrgFlag}
                      />
                    )),
                  ]
              }
            />
          )}
        </Box>
        <Stack direction="row" justifyContent="flex-end">
          <Typography
            variant="subtitle2"
            color="primary"
            sx={
              (isDesktop && filteredTemplates?.length > 5) ||
                (isTablet && filteredTemplates?.length > 4) ||
                (isMobile && filteredTemplates?.length > 2)
                ? { cursor: "pointer", userSelect: "none" }
                : { pointerEvents: "none", opacity: 0 }
            }
            onClick={() => setShowAll(!showAll)}
          >
            {showAll ? "Show less" : "Show all"}
          </Typography>
        </Stack>
        {!isLoading && (
          <Drawer
            anchor="right"
            open={data?.response?.certificateTemplates?.find(
              (temp) => temp?._id === searchParams.get("preview"),
            )}
            onClose={() => {
              searchParams.delete("preview");
              setSearchParams(searchParams);
            }}
          >
            <TemplateDrawer
              template={data?.response?.certificateTemplates?.find(
                (temp) => temp?._id === searchParams.get("preview"),
              )}
              isPrivate={isPrivate}
              deleteTemplate={deleteTemplate}
              downloadTemplate={downloadTemplate}
            />
          </Drawer>
        )}
      </Stack>
    </Paper>
  );
}

import React from "react";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import TabLayout from "layouts/TabLayout";
import ScheduledCredsTable from "components/ScheduledCredsTable";

export default function ScheduledDocs() {
  const { draftId } = useParams();
  return (
    <TabLayout>
      <Box p={2} height="100%">
        <ScheduledCredsTable draftId={draftId} />
      </Box>
    </TabLayout>
  );
}

import React from "react";
import Login from "components/Credulocker/Login";
import { useCredulockerAuth } from "hooks/useCredulockerAuth";
import { Box } from "@mui/material";
import Dashboard from "components/Credulocker/Dashboard";

export default function Credulocker() {
  const { user } = useCredulockerAuth();
  return <Box bgcolor="white.main">{user ? <Dashboard /> : <Login />}</Box>;
}

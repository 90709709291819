import React, { Fragment, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Box,
  Button,
  IconButton,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";

export default function TemplateDrawer(props) {
  const { template, isPrivate, deleteTemplate, downloadTemplate } = props;
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [imgLoading, setImgLoading] = useState(true);

  return (
    <Box width="40vw" pb={2}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        px={2}
        py={1.6}
        sx={{
          position: "sticky",
          top: 0,
          backgroundColor: "white.main",
          borderBottom: "1px solid",
          borderColor: "border.main",
        }}
      >
        <Stack direction="row" spacing={2}>
          <CardMembershipIcon />
          <Typography variant="h5">Certificate</Typography>
        </Stack>
        <IconButton
          size="small"
          disableRipple
          onClick={() => {
            searchParams.delete("preview");
            setSearchParams(searchParams);
          }}
        >
          <CloseIcon />
        </IconButton>
      </Stack>
      <Box bgcolor="primary.surface" p={2}>
        {imgLoading ? (
          <Skeleton
            variant="rectangular"
            height="calc((30vw - 32px) * 794 / 1123)"
          />
        ) : null}
        <img
          src={template?.thumb?.large}
          style={{ display: imgLoading ? "none" : "initial" }}
          width="100%"
          alt={template?.title}
          onLoad={() => setImgLoading(false)}
        />
      </Box>
      <Stack direction="column" spacing={1} p={2}>
        <Typography variant="h2">{template?.title}</Typography>
        {isPrivate && (
          <Fragment>
            {/* <Typography variant="body2">
              Created By: {template?.createdBy}
            </Typography> */}
            <Typography variant="body2">
              Created On: {moment(template?.createdAt).format("ll")}
            </Typography>
          </Fragment>
        )}
      </Stack>
      <Stack direction="row" justifyContent="center" spacing={2} mt={1}>
        <Button
          variant="contained"
          size="small"
          disableElevation
          onClick={() =>
            navigate(
              `${process.env.REACT_APP_BASE_ROUTE}/issuance/create/settings`,
            )
          }
        >
          Use This
        </Button>
        {isPrivate && template?.templateType === "HTML" && (
          <Button
            variant="outlined"
            size="small"
            disableElevation
            onClick={() =>
              navigate(
                `${process.env.REACT_APP_BASE_ROUTE}/issuance/create/design/certificate/${template?._id}?type=edit`,
                { state: { templateName: template?.title } },
              )
            }
          >
            Edit
          </Button>
        )}
        {!isPrivate && (
          <Button
            variant="outlined"
            size="small"
            disableElevation
            onClick={() =>
              navigate(
                `${
                  process.env.REACT_APP_BASE_ROUTE
                }/issuance/create/design/${"certificate"}/${
                  template?._id
                }?type=edit`,
                { state: { templateName: template?.title } },
              )
            }
          >
            Customize
          </Button>
        )}
        {template?.templateType !== "HTML" && (
          <Button
            variant="outlined"
            size="small"
            disableElevation
            onClick={() => downloadTemplate(template?._id)}
          >
            Download
          </Button>
        )}
        {isPrivate && (
          <Button
            variant="outlined"
            size="small"
            disableElevation
            onClick={() => deleteTemplate(template?._id)}
          >
            Delete
          </Button>
        )}
      </Stack>
    </Box>
  );
}

import React, { useState } from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  Hidden,
  Link,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import CreateTemplateIcon from "assets/images/create_certificate.svg";
import UploadTemplateIcon from "assets/images/upload_certificate.svg";
import { customAxios } from "hooks/useAxios";
import MySwal from "utils/Swal";

export default function NewTemplate(props) {
  const { templateType } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const [errorMsg, setErrorMsg] = useState();

  const imageInputChangeHandler = async (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile.size > 5242880) {
      setErrorMsg("File size cannot be more than 5mb!");
      e.target.value = null;
      setTimeout(() => {
        setErrorMsg();
      }, 5000);
      return;
    }
    if (
      selectedFile.type ===
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("companyId", "FixMe");
      MySwal.showLoader("Template uploading...");
      const response = await customAxios.post("template", formData);
      if (response) {
        MySwal.fire({
          text: "Template uploaded successfully!",
          allowEscapeKey: false,
          allowOutsideClick: false,
          icon: "success",
          showConfirmButton: false,
          timer: 2000,
        });
      }
      setTimeout(function () {
        window.location.reload(); // FixMe
      }, 2000);
    } else if (
      selectedFile.type === "image/png" ||
      selectedFile.type === "image/jpeg"
    ) {
      const reader = new FileReader();
      reader.onload = function (evt) {
        const img = evt.target.result;
        const image = new Image();
        image.onload = function () {
          const width = this.width;
          const height = this.height;
          navigate(
            `${
              process.env.REACT_APP_BASE_ROUTE
            }/issuance/create/design/${templateType}/new?type=upload&filter=VARS${
              location.search && "&" + location.search.substring(1)
            }`,
            {
              state: {
                backgroundImg: img,
                dimension: {
                  width,
                  height,
                },
              },
            },
          );
        };
        image.src = img;
      };
      reader.readAsDataURL(selectedFile);
    } else {
      setErrorMsg("Incorrect File Type!");
    }
    e.target.value = null;
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{ flexDirection: { xs: "column", md: "row" } }}
    >
      <Stack
        alignItems="center"
        direction="column"
        spacing={2}
        sx={{ width: { md: "50%" } }}
        px={8}
        py={4}
      >
        <img src={CreateTemplateIcon} alt="Create Template" style={{ width: 160 }} />
        <Stack alignItems="center" direction="column" spacing={1}>
          <Typography textAlign="center" variant="h5">
            Design Template
          </Typography>
          <Typography textAlign="center" variant="body2">
            A robust template builder to elevate your creativity!
          </Typography>
        </Stack>
        <Link
          component={RouterLink}
          to={`${
            process.env.REACT_APP_BASE_ROUTE
          }/issuance/create/design/${templateType}/new?type=create${
            location.search && "&" + location.search.substring(1)
          }`}
        >
          <Button variant="contained" disableElevation>
            Create
          </Button>
        </Link>
      </Stack>
      <Hidden mdUp>
        <Divider flexItem />
      </Hidden>
      <Hidden mdDown>
        <Divider orientation="vertical" flexItem />
      </Hidden>
      <Stack
        alignItems="center"
        direction="column"
        spacing={2}
        sx={{ width: { md: "50%" } }}
        p={4}
      >
        <img src={UploadTemplateIcon} alt="Upload Template" style={{ width: 160 }} />
        <Stack alignItems="center" direction="column" spacing={1}>
          <Typography textAlign="center" variant="h5">
            Upload An Existing Design Template
          </Typography>
          <Typography textAlign="center" variant="body2">
            Any PNG, JPG or DOCX, up to 5MB
          </Typography>
        </Stack>
        <Button
          variant="contained"
          disableElevation
          onClick={() => document.getElementById("backgroundInput").click()}
        >
          Upload
        </Button>
        <input
          type="file"
          id="backgroundInput"
          style={{ display: "none" }}
          onChange={imageInputChangeHandler}
        />
        <Typography textAlign="center" variant="body2">
          Recommended - 1123 x 794 pixels
        </Typography>
        <Typography
          textAlign="center"
          variant="caption"
          sx={{ fontStyle: "italic" }}
        >
          Please note: Real-time preview is unavailable for DOCX templates.
        </Typography>
      </Stack>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={Boolean(errorMsg)}
        message={errorMsg}
      />
    </Box>
  );
}

import React from "react";
import {
  Avatar,
  Button,
  Chip,
  Dialog,
  Divider,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useCredulockerAuth } from "hooks/useCredulockerAuth";
import LogoutIcon from "@mui/icons-material/Logout";
import AddIcon from "@mui/icons-material/Add";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import MergeEmailPopup from "./MergeEmailPopup";
import { useAxios } from "hooks/useAxios";

export default function Profile() {
  const { user, logout } = useCredulockerAuth();
  const [addEmailOpen, setAddEmailOpen] = React.useState(false);
  const username = user?.name || user?.email?.[0]?.split("@")[0];

  const [skills] = useAxios(`credulocker/user/skills`, {
    attachCredulockerToken: true,
  });

  return (
    <Paper
      variant="outlined"
      component={Stack}
      sx={{
        bgcolor: "#f8f9fe",
        borderRadius: "10px",
        overflow: "hidden",
        p: 3,
        minHeight: { sm: "calc(100dvh - 32px)" },
      }}
      spacing={3}
    >
      <Stack spacing={2} alignItems="center">
        <Avatar
          sx={{
            height: "72px",
            width: "72px",
            fontSize: "2.5rem",
            borderRadius: "10px",
          }}
        >
          {username.charAt(0).toUpperCase()}
        </Avatar>
        <Stack spacing={0.75}>
          <Typography noWrap variant="h4" align="center">
            {username}
          </Typography>
          <Typography noWrap variant="body2" align="center">
            {user.email[0]}
          </Typography>
        </Stack>
        <Button startIcon={<LogoutIcon />} disableRipple onClick={logout}>
          Logout
        </Button>
      </Stack>
      <Divider />
      <Stack spacing={1} alignItems="flex-start">
        <Typography noWrap variant="subtitle2">
          Merged Email(s):
        </Typography>
        {user?.email?.slice(1)?.map((email) => (
          <Stack key={email} direction="row" alignItems="center" spacing={1}>
            <EmailOutlinedIcon fontSize="small" color="action" />
            <Typography variant="body2" noWrap>
              {email}
            </Typography>
          </Stack>
        ))}
        <Button
          size="small"
          sx={{
            p: 0,
            px: 0.5,
          }}
          startIcon={<AddIcon />}
          onClick={() => setAddEmailOpen(true)}
        >
          Add Email
        </Button>
      </Stack>
      {Array.isArray(skills) && skills?.length && (
        <Stack spacing={1} alignItems="flex-start">
          <Typography noWrap variant="subtitle2">
            Skills Earned:
          </Typography>
          <Stack direction="row" flexWrap="wrap" useFlexGap spacing={1}>
            {skills.map((skill) => (
              <Chip key={skill} label={skill} />
            ))}
          </Stack>
        </Stack>
      )}
      <Dialog
        size="md"
        disableEscapeKeyDown
        sx={{ zIndex: 1000 }}
        open={addEmailOpen}
        onClose={() => setAddEmailOpen(false)}
      >
        <MergeEmailPopup onClose={() => setAddEmailOpen(false)} />
      </Dialog>
    </Paper>
  );
}

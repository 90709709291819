import React from "react";
import {
  Stack,
  Typography,
  IconButton,
  TextField,
  MenuItem,
  Tooltip,
  MenuList,
  ListItemIcon,
  ListItemText,
  Box,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ForwardToInboxOutlinedIcon from "@mui/icons-material/ForwardToInboxOutlined";
import { customAxios } from "hooks/useAxios.js";
import CustomPopover from "components/common/customPopover";
import SendEmailNotificationForm from "components/issuance/SendEmailNotificationForm";
import MySwal from "utils/Swal";
import validator from "validator";
import EditDocumentForm from "./EditDocumentForm";
import CustomDialog from "components/common/CustomDialog";
import useResponsive from "hooks/useResponsive";
import LabelOffOutlinedIcon from "@mui/icons-material/LabelOffOutlined";
import LabelOutlinedIcon from "@mui/icons-material/LabelOutlined";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import { useSearchParams } from "react-router-dom";
import { useAuth } from "hooks/useAuth";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import SendWhatsappNotificationForm from "components/issuance/SendWhatsappNotificationForm";
import NotificationPopup from "./NotificationPopup";
import IosShareOutlinedIcon from "@mui/icons-material/IosShareOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import EyeIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { saveAs } from "file-saver";
import moment from "moment";

export default function ActionBar(props) {
  const {
    table,
    fetchDocs,
    isSeeAll,
    setIsSeeAll,
    showSeeAllToggle = true,
  } = props;
  const { isDesktop, isTablet, isMobile } = useResponsive();
  const { user } = useAuth();
  const [searchParams] = useSearchParams();
  const filter = searchParams.get("filter");
  const tags = Array.from(new Set(user?.tags || []));

  const handleDelete = async () => {
    const selectedRows = table.getSelectedRowModel().rows;
    const ids = selectedRows.map((row) => row.original._id);

    if (ids.length) {
      const { isConfirmed } = await MySwal.fire({
        title: "Are you sure?",
        text: "Do you want to delete the selected documents?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      });

      if (isConfirmed) {
        const response = await customAxios.delete(`signedDocs`, {
          data: {
            documentIds: ids,
            docTag: "vm_removed_from_all",
          },
        });

        if (response) {
          fetchDocs();
          table.resetRowSelection();

          MySwal.fire({
            title: "Document(s) Deleted!",
            text: "The selected Document(s) has been deleted!",
            icon: "success",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
    } else {
      MySwal.fire({
        title: "No Documents Selected",
        text: "Please select documents to perform delete action.",
        icon: "info",
      });
    }
  };

  const areEmailsValid = () => {
    const selectedRows = table.getSelectedRowModel().rows;
    const to = selectedRows.map(({ original }) => ({
      email: original?.sharedWith?.email,
      displayId: original.displayId,
      docId: original._id,
    }));

    const isEmpty = !to.length;
    const hasInvalidEmail = to.some(
      (row) => !validator.isEmail(row?.email || ""),
    );

    if (isEmpty)
      MySwal.fire({
        title: "No Documents Selected",
        text: "Please select documents to send emails.",
        icon: "info",
      });

    if (hasInvalidEmail)
      MySwal.fire({
        title: "Email Missing!",
        text: "Some rows have incorrect or missing email addresses.",
        icon: "error",
      });

    return !isEmpty && !hasInvalidEmail;
  };

  const areWhatsAppNumberValid = () => {
    const selectedRows = table.getSelectedRowModel().rows;
    const displayIds = selectedRows.map(({ original }) => original.displayId);

    const isEmpty = !displayIds.length;
    const hasInvalidEmail = selectedRows.some(
      ({ original }) =>
        !validator.isMobilePhone(original?.sharedWith.whatsappNumber || ""),
    );

    if (isEmpty)
      MySwal.fire({
        title: "No Documents Selected",
        text: "Please select documents to send WhatsApp Notifications.",
        icon: "info",
      });

    if (hasInvalidEmail)
      MySwal.fire({
        title: "WhatsApp Number Missing!",
        text: "Some rows have incorrect or missing WhatsApp numbers.",
        icon: "error",
      });

    return !isEmpty && !hasInvalidEmail;
  };

  const noSelectionMsg = () => {
    MySwal.fire({
      title: "No Documents Selected",
      text: "Please select documents to perform action.",
      icon: "info",
    });
  };

  const removeFromLabel = async () => {
    const selectedRows = table.getSelectedRowModel().rows;
    const docIds = selectedRows.map(({ original }) => original._id);
    const isEmpty = !docIds.length;
    if (isEmpty) {
      MySwal.fire({
        title: "No Documents Selected",
        text: "Please select documents to remove from label.",
        icon: "info",
      });
      return;
    }
    const response = await customAxios.post(
      "signedDocs/removeTagFromDocRouter",
      {
        tag: filter,
        docIds,
      },
    );
    if (response) {
      fetchDocs();
      table.resetRowSelection();

      MySwal.fire({
        title: "Document(s) removed!",
        text: "The selected Document(s) has been removed from label!",
        icon: "success",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  const addToLabel = async (tag) => {
    const selectedRows = table.getSelectedRowModel().rows;
    const docIds = selectedRows.map(({ original }) => original._id);
    const isEmpty = !docIds.length;
    if (isEmpty) {
      MySwal.fire({
        title: "No Documents Selected",
        text: "Please select documents to add label.",
        icon: "info",
      });
      return;
    }
    const response = await customAxios.post(
      "signedDocs/addTagToDocumentRouter",
      {
        tag: tag,
        docIds,
      },
    );
    if (response) {
      fetchDocs();
      table.resetRowSelection();

      MySwal.fire({
        title: "Label attached!",
        text: "The selected Document(s) has been added to the label!",
        icon: "success",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  const downloadCreds = async (cb) => {
    const selectedRows = table.getSelectedRowModel().rows;
    const docIds = selectedRows.map(({ original }) => original._id);
    const isEmpty = !docIds.length;
    if (isEmpty) {
      MySwal.fire({
        title: "No Documents Selected",
        text: "Please select documents to download.",
        icon: "info",
      });
      return;
    }
    MySwal.showLoader("Preparing to download...");
    const response = await customAxios.post(
      "signedDocs/download_zip",
      {
        docIds,
      },
      { responseType: "blob" },
    );
    if (response) {
      const url = URL.createObjectURL(response.data);
      const a = document.createElement("a");
      a.href = url;
      a.download = "download.zip";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      cb();

      table.resetRowSelection();

      MySwal.fire({
        title: "Download started!",
        text: "Download for the selected document(s) has been started!",
        icon: "success",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  const actionButtons = () => {
    return (
      <>
        <Tooltip title="Reload">
          <IconButton onClick={fetchDocs}>
            <RefreshIcon />
          </IconButton>
        </Tooltip>
        <CustomDialog
          AnchorComponent={({ onClick }) => (
            <Tooltip title="Send Email Notification">
              <IconButton onClick={() => areEmailsValid() && onClick()}>
                <ForwardToInboxOutlinedIcon />
              </IconButton>
            </Tooltip>
          )}
          title="Send Email Notification"
          Content={(dialogProps) => (
            <NotificationPopup
              {...dialogProps}
              method="email"
              data={table.getSelectedRowModel().rows.map(({ original }) => ({
                email: original?.sharedWith?.email,
                displayId: original.displayId,
                docId: original._id,
              }))}
              callback={fetchDocs}
              NotificationForm={(notifProps) => (
                <Box pt={1}>
                  <SendEmailNotificationForm {...notifProps} />
                </Box>
              )}
            />
          )}
        />
        <CustomDialog
          AnchorComponent={({ onClick }) => (
            <Tooltip title="Send WhatsApp Notification">
              <IconButton onClick={() => areWhatsAppNumberValid() && onClick()}>
                <WhatsAppIcon />
              </IconButton>
            </Tooltip>
          )}
          title="Send WhatsApp Notification"
          Content={(dialogProps) => (
            <NotificationPopup
              {...dialogProps}
              method="whatsapp"
              data={table.getSelectedRowModel().rows.map(({ original }) => ({
                num: original?.sharedWith?.whatsappNumber,
                displayId: original.displayId,
                docId: original._id,
              }))}
              callback={fetchDocs}
              NotificationForm={(notifProps) => (
                <Box pt={1} sx={{ width: "100%" }}>
                  <SendWhatsappNotificationForm {...notifProps} />
                </Box>
              )}
            />
          )}
        />
        <CustomPopover
          AnchorComponent={(syntax) => (
            <Tooltip title="Add Label">
              <IconButton {...syntax}>
                <LabelOutlinedIcon />
              </IconButton>
            </Tooltip>
          )}
          PopoverComponent={(syntax) => (
            <MenuList {...syntax}>
              {tags.map(
                (tag) =>
                  tag !== filter && (
                    <MenuItem
                      key={tag}
                      onClick={() => {
                        syntax?.handleClose();
                        addToLabel(tag);
                      }}
                    >
                      {tag}
                    </MenuItem>
                  ),
              )}
            </MenuList>
          )}
        />
        <Tooltip title="Delete">
          <IconButton onClick={handleDelete}>
            <DeleteOutlineIcon />
          </IconButton>
        </Tooltip>
      </>
    );
  };

  const seeAllFilter =
    (user?.departments?.length || user?.role === "VM_COMPANY_ADMIN") &&
    showSeeAllToggle ? (
      <TextField
        select
        defaultValue={isSeeAll}
        size="small"
        className="extra-small"
        onChange={(e) => setIsSeeAll(e.target.value)}
        sx={{
          ml: { md: "auto !important" },
          mr: { xs: "8px !important", md: "16px !important" },
          minWidth: "130px",
        }}
      >
        <MenuItem value="false">My Records</MenuItem>
        {user?.role === "VM_COMPANY_ADMIN" && (
          <MenuItem value="true">All Records</MenuItem>
        )}
        {user?.departments?.length &&
          user.departments.map((department) => (
            <MenuItem key={department.departmentName} value={department._id}>
              {department.departmentName}
            </MenuItem>
          ))}
      </TextField>
    ) : null;

  const handleExport = () => {
    const selectedRows = table.getSelectedRowModel().rows;

    const formatCellValue = (value) => {
      if (Array.isArray(value)) {
        return `"${value.join(", ")}"`;
      }
      if (validator.isISO8601(`${value}`)) {
        return moment(value).format("DD-MM-YYYY");
      }
      return value;
    };

    const rowsData = selectedRows.map((row) => {
      let cellValues = row
        .getAllCells()
        .map((cell) => formatCellValue(cell.renderValue()));
      cellValues.shift(); // removing _id

      const getProperty = (obj, path) =>
        path.split(".").reduce((acc, key) => acc?.[key], obj);

      const extractCellValue = (cell, property) =>
        getProperty(cell, property) || 0;

      const accumulateProperty = (index, properties) =>
        properties.reduce(
          (sum, prop) =>
            sum + extractCellValue(JSON.parse(cellValues[index]), prop),
          0,
        );

      const shareColIdx = table.getColumn("share_count").getIndex();
      cellValues[shareColIdx] = accumulateProperty(6, [
        "share.linkedin",
        "share.whatsapp",
        "share.facebook",
        "share.twitter",
      ]);
      const clicksColIdx = table.getColumn("post_click_count").getIndex();
      cellValues[clicksColIdx] = accumulateProperty(7, [
        "postClicks.linkedin",
        "postClicks.whatsapp",
        "postClicks.facebook",
        "postClicks.twitter",
      ]);
      const verifyColIdx = table.getColumn("verification_count").getIndex();
      cellValues[verifyColIdx] = accumulateProperty(8, ["widget", "qr"]);

      cellValues.push(row?.original?.metaData?.Reason);

      return cellValues;
    });

    const headers = table
      .getAllColumns()
      .slice(1)
      .map((col) => col?.columnDef?.header());

    headers.push("Reason");

    const csvData = [headers, ...rowsData];
    const csvString = csvData.map((row) => row.join(",")).join("\n");
    const blob = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), csvString], {
      type: "text/csv;charset=utf-8",
    });
    saveAs(blob, "data.csv");
  };

  const handleRevoke = async () => {
    const selectedRows = table.getSelectedRowModel().rows;
    const docIds = selectedRows.map(({ original }) => original._id);
    MySwal.fire({
      title: "Revoke Access",
      input: "textarea",
      inputPlaceholder: "Type your message here...",
      inputAttributes: {
        "aria-label": "Type your message here",
      },
      showCancelButton: true,
      confirmButtonText: "Revoke",
      showLoaderOnConfirm: true,
      preConfirm: async (message) => {
        await customAxios.post("docs/revoke", { docIds, message });
      },
      allowOutsideClick: () => !MySwal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        MySwal.fire({
          title: "Credentials Revoked!",
          text: "The selected Credentials(s) has been revoked!",
          icon: "success",
          showConfirmButton: false,
          timer: 2000,
        });

        fetchDocs();
        table.resetRowSelection();
      }
    });
  };

  const handleRestore = async () => {
    const selectedRows = table.getSelectedRowModel().rows;
    const docIds = selectedRows.map(({ original }) => original._id);

    const { isConfirmed } = await MySwal.fire({
      title: "Are you sure?",
      text: "Do you want to restore access for the selected documents?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    });

    if (isConfirmed) {
      MySwal.showLoader("Access restoring...");
      await customAxios.post("docs/restoreRevoke", { docIds });

      MySwal.fire({
        title: "Access restored!",
        text: "Access restored for the selected documents",
        icon: "success",
        showConfirmButton: false,
        timer: 2000,
      });

      fetchDocs();
      table.resetRowSelection();
    }
  };

  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      width="100%"
      disableEnforceFocus
    >
      {(isDesktop || isTablet) && actionButtons()}
      <CustomPopover
        AnchorComponent={(syntax) => (
          <Tooltip title="More">
            <IconButton {...syntax}>
              <MoreVertIcon />
            </IconButton>
          </Tooltip>
        )}
        PopoverComponent={(syntax) => (
          <MenuList
            {...syntax}
            sx={
              isMobile && {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexWrap: "wrap",
                rowGap: 1,
              }
            }
          >
            {isMobile && actionButtons()}
            <CustomDialog
              AnchorComponent={({ onClick }) =>
                isMobile ? (
                  <IconButton
                    onClick={
                      table.getSelectedRowModel().rows.length
                        ? onClick
                        : noSelectionMsg
                    }
                  >
                    <EditOutlinedIcon />
                  </IconButton>
                ) : (
                  <MenuItem
                    onClick={
                      table.getSelectedRowModel().rows.length
                        ? onClick
                        : noSelectionMsg
                    }
                  >
                    <ListItemIcon>
                      <EditOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText disableTypography>
                      <Typography variant="body2">Edit Details</Typography>
                    </ListItemText>
                  </MenuItem>
                )
              }
              title="Edit Document"
              Content={(dialogProps) => (
                <EditDocumentForm
                  {...dialogProps}
                  docIds={table
                    .getSelectedRowModel()
                    .rows.map(({ original }) => original._id)}
                  initialValues={
                    table.getSelectedRowModel().rows.length === 1 && {
                      expiryDate:
                        table.getSelectedRowModel().rows[0]?.original?.docInfo
                          ?.expiryDate,
                      text: table.getSelectedRowModel().rows[0]?.original
                        ?.docInfo?.text,
                      skills:
                        table.getSelectedRowModel().rows[0]?.original?.skills,
                    }
                  }
                  callback={fetchDocs}
                />
              )}
              closeOnOutsideClick={false}
            />

            {isMobile ? (
              <IconButton
                onClick={
                  table.getSelectedRowModel().rows.length
                    ? () => handleRevoke()
                    : noSelectionMsg
                }
              >
                <VisibilityOffOutlinedIcon />
              </IconButton>
            ) : (
              <MenuItem
                onClick={
                  table.getSelectedRowModel().rows.length
                    ? () => handleRevoke()
                    : noSelectionMsg
                }
              >
                <ListItemIcon>
                  <VisibilityOffOutlinedIcon />
                </ListItemIcon>
                <ListItemText disableTypography>
                  <Typography variant="body2">Revoke Access</Typography>
                </ListItemText>
              </MenuItem>
            )}

            {isMobile ? (
              <IconButton
                onClick={
                  table.getSelectedRowModel().rows.length
                    ? () => handleRestore()
                    : noSelectionMsg
                }
              >
                <EyeIcon />
              </IconButton>
            ) : (
              <MenuItem
                onClick={
                  table.getSelectedRowModel().rows.length
                    ? () => handleRestore()
                    : noSelectionMsg
                }
              >
                <ListItemIcon>
                  <EyeIcon />
                </ListItemIcon>
                <ListItemText disableTypography>
                  <Typography variant="body2">Restore Access</Typography>
                </ListItemText>
              </MenuItem>
            )}

            {isMobile ? (
              <IconButton
                onClick={
                  table.getSelectedRowModel().rows.length
                    ? () => handleExport()
                    : noSelectionMsg
                }
              >
                <IosShareOutlinedIcon />
              </IconButton>
            ) : (
              <MenuItem
                onClick={
                  table.getSelectedRowModel().rows.length
                    ? () => handleExport()
                    : noSelectionMsg
                }
              >
                <ListItemIcon>
                  <IosShareOutlinedIcon />
                </ListItemIcon>
                <ListItemText disableTypography>
                  <Typography variant="body2">Export to CSV</Typography>
                </ListItemText>
              </MenuItem>
            )}

            {isMobile ? (
              <IconButton
                onClick={
                  table.getSelectedRowModel().rows.length
                    ? () => downloadCreds(syntax.handleClose)
                    : noSelectionMsg
                }
              >
                <DownloadOutlinedIcon />
              </IconButton>
            ) : (
              <MenuItem
                onClick={
                  table.getSelectedRowModel().rows.length
                    ? () => downloadCreds(syntax.handleClose)
                    : noSelectionMsg
                }
              >
                <ListItemIcon>
                  <DownloadOutlinedIcon />
                </ListItemIcon>
                <ListItemText disableTypography>
                  <Typography variant="body2">Download</Typography>
                </ListItemText>
              </MenuItem>
            )}
            {filter && !["SENT", "DRAFT", "OUTBOX"].includes(filter) ? (
              isMobile ? (
                <IconButton
                  onClick={
                    table.getSelectedRowModel().rows.length
                      ? removeFromLabel
                      : noSelectionMsg
                  }
                >
                  <LabelOffOutlinedIcon />
                </IconButton>
              ) : (
                <MenuItem
                  onClick={
                    table.getSelectedRowModel().rows.length
                      ? removeFromLabel
                      : noSelectionMsg
                  }
                >
                  <ListItemIcon>
                    <LabelOffOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText disableTypography>
                    <Typography variant="body2">
                      Remove Label &quot;{filter}&quot;
                    </Typography>
                  </ListItemText>
                </MenuItem>
              )
            ) : null}
            {isMobile && seeAllFilter}
          </MenuList>
        )}
      />
      {(isDesktop || isTablet) && seeAllFilter}
    </Stack>
  );
}

import { TextField, InputAdornment } from "@mui/material";
import React from "react";
import Search from "@mui/icons-material/Search";

export default function SearchBar(props) {
  const { setSearchValue } = props;
  return (
    <TextField
      placeholder="Search"
      type="search"
      size="small"
      onChange={(e) => setSearchValue(e.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment>
            <Search sx={{ ml: -0.5, mr: 0.5, opacity: 0.5 }} />
          </InputAdornment>
        ),
      }}
    />
  );
}

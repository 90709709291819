import React from "react";
import { Grid, Paper, Tabs, Tab, SwipeableDrawer, Box } from "@mui/material";
import { useSearchParams, useLocation, useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";

const variants = {
  light: {
    backgroundColor: "#eee",
    foregroundColor: "#fff",
    textColor: "#000",
  },
  dark: {
    backgroundColor: "#2C2C2C",
    foregroundColor: "#3C3C3C",
    color: "#eee",
  },
};

const a11yProps = (index) => ({
  id: `vertical-tab-${index}`,
  "aria-controls": `vertical-tabpanel-${index}`,
});

const styles = (props) => {
  return {
    tabs: {
      "&.MuiTabs-vertical": {
        overflow: "visible",
        "@media(max-width: 600px)": {
          paddingTop: "16px",
        },
        "& .MuiTabs-scroller": {
          overflowY: "auto !important",
        },
        "& .MuiTab-root": {
          padding: "8px 16px",
          minHeight: "38px",
          zIndex: 1,
          fontSize: "0.8rem",
          textTransform: "none",
          "&.Mui-selected": {
            color: variants[props.variant].color,
            // fontWeight: "700",
          },
          "& .MuiTouchRipple-root": {
            display: "none",
          },
        },
        "& .MuiTabs-indicator": {
          right: "-1px",
          left: 0,
          width: "auto",
          background: variants[props.variant].foregroundColor,
          borderLeft: "5px solid #0d98ba",
          boxShadow:
            "0px 1px 0 0 rgba(0, 0, 0, 0.12), 0px -1px 0 0 rgba(0, 0, 0, 0.12)",
        },
      },
    },
  };
};

export default function CustomTabs(props) {
  const { cta, bottomCta, filtersWidth, filters, variant, drawer, setDrawer } =
    props;

  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery("(max-width:600px)");

  const [searchParams, setSearchParams] = useSearchParams();
  const filterIndex = filters.findIndex(
    (f) => f.id === searchParams.get("filter"),
  );

  const getTabIndex = () => {
    if (filterIndex >= 0) {
      return filterIndex;
    } else {
      return false;
    }
  };
  const [value, setValue] = React.useState(getTabIndex());

  React.useEffect(() => {
    setValue(getTabIndex());
  }, [searchParams]);

  const handleChange = (event, newValue) => {
    if (
      filters[newValue]?.path &&
      location.pathname !== filters[newValue]?.path
    ) {
      const newPath =
        filters[newValue].path + "?filter=" + filters[newValue].id;
      navigate(newPath);
    } else {
      searchParams.set("filter", filters[newValue].id);
      searchParams.delete("searchText");
      setSearchParams(searchParams, {
        replace: true,
        state: { ...location.state },
      });
      setValue(newValue);
    }
    setDrawer && setDrawer(false);
  };

  const navigationTabs = (
    <Tabs
      orientation="vertical"
      value={value}
      onChange={handleChange}
      aria-label="Vertical tabs example"
      sx={styles({ variant }).tabs}
      textColor="inherit"
    >
      {filters.map((filter, index) => (
        <Tab
          key={filter.id}
          data-tut={filter["data-tut"]}
          label={filter.label}
          icon={filter.icon && <filter.icon />}
          sx={filter?.disabled && { pointerEvents: "none", opacity: 1 }}
          {...a11yProps(index)}
        />
      ))}
    </Tabs>
  );

  return (
    <>
      <Grid
        container
        sx={{
          color: variants[variant].color,
          backgroundColor: variants[variant].backgroundColor,
          height: "100%",
        }}
        flexWrap="nowrap"
      >
        {isMobile ? (
          <SwipeableDrawer
            open={drawer}
            onClose={() => setDrawer(false)}
            onOpen={() => setDrawer(true)}
            sx={{ ".MuiDrawer-paper": { minWidth: "200px" } }}
          >
            {navigationTabs}
            {bottomCta}
          </SwipeableDrawer>
        ) : (
          <Grid
            item
            width={filtersWidth || "90px"}
            flexShrink={0}
            height="100%"
            sx={{ overflowY: "auto" }}
          >
            {cta && (
              <Box
                p={isMobile ? 0 : 2}
                {...(isMobile
                  ? { position: "absolute", bottom: 16, right: 16 }
                  : {})}
              >
                {cta}
              </Box>
            )}
            {navigationTabs}
            {bottomCta}
          </Grid>
        )}
        {cta && isMobile && (
          <Box p={0} {...{ position: "absolute", bottom: 16, right: 16 }}>
            {cta}
          </Box>
        )}
        <Grid item xs style={{ minWidth: 0 }}>
          <Paper
            square={true}
            elevation={0}
            sx={{
              height: "calc(100dvh - 62px)",
              overflow: "auto",
              // p: 2,
              borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
              color: variants[variant].color,
              backgroundColor: variants[variant].foregroundColor,
            }}
          >
            {props.children}
          </Paper>
          {/* {filters.map((filter, index) => (
            <TabPanel key={filter.label} value={value} index={index}>
            </TabPanel>
          ))} */}
        </Grid>
      </Grid>
    </>
  );
}

import React from "react";
import { Alert, AlertTitle, Stack, Typography } from "@mui/material";

const CredentialRevokedImg = "https://cdn-stage.itscredible.com/assets/images/credential-revoked.svg"

export default function CredentialRevoked({ revokeMessage }) {
  return (
    <Stack
      height="100vh"
      justifyContent="center"
      alignItems="center"
      spacing={2}
      p={2}
    >
      <img
        src={CredentialRevokedImg}
        alt="Credential-Not-Found"
        height="50%"
        style={{ maxWidth: "100%" }}
      />
      <Stack spacing={3}>
        <Typography variant="h2" align="center">
          Credential access is revoked for the recipient!
        </Typography>
        {revokeMessage && (
          <Alert severity="info">
            <AlertTitle>Issuer Message:</AlertTitle>
            {revokeMessage}
          </Alert>
        )}
      </Stack>
    </Stack>
  );
}

import React from "react";
import { Box, Stack, Typography, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import FolderIcon from "assets/images/folder-icon.png";

export default function NoDataScreen() {
  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Stack spacing={3} alignItems="center">
        <img
          src={FolderIcon}
          height="120"
        />
        <Stack alignItems="center" spacing={2}>
          <Stack alignItems="center" spacing={1}>
            <Typography variant="h4">No Credentials</Typography>
            <Typography variant="body2">
              Issue Your Credentials In Four Easy Steps.
            </Typography>
          </Stack>
          <Typography variant="subtitle1">
            <Link
              component={RouterLink}
              to={`${process.env.REACT_APP_BASE_ROUTE}/issuance/create/selectTemplate`}
            >
              <b>Click Here To Start Issuing</b>
            </Link>
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
}

import React, { useEffect, useState } from "react";
import { InputAdornment, Paper, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useSearchParams } from "react-router-dom";
import _ from "lodash";

export default function SearchTemplateBar(props) {
  const { autoFocus = false } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchText, setSearchText] = useState(searchParams.get("search"));

  const handleSearch = _.debounce(
    () =>
      setSearchParams((searchParams) => {
        searchParams.delete("search");
        if (searchText) {
          searchParams.set("search", searchText);
        }
        return searchParams;
      }),
    500,
  );

  useEffect(() => {
    handleSearch();
  }, [searchText]);

  return (
    <Paper elevation={0}>
      <TextField
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        variant="outlined"
        size="small"
        placeholder="Search Templates..."
        autoFocus={autoFocus}
        autoComplete="off"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </Paper>
  );
}

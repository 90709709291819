// import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";

const useResponsive = () => {
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const isTablet = useMediaQuery((theme) => theme.breakpoints.only("md"));
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return { isDesktop, isTablet, isMobile };
};

export default useResponsive;

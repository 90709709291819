import { customAxios } from "hooks/useAxios";

const getRandomInt = (max) => {
  return Math.floor(Math.random() * max);
};

const blobToDataUrl = (blob) => {
  return new Promise((resolve) => {
    const fr = new FileReader();
    fr.onload = (e) => {
      resolve(e.target.result);
    };
    fr.readAsDataURL(blob);
  });
};

const downloader = async ({ fileUrl, fileName }) => {
  const response = await customAxios.get(fileUrl, {
    withCredentials: false,
    responseType: "blob",
  });
  const url = window.URL.createObjectURL(new Blob([response?.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
  return;
};

const copy = (text) => {
  return navigator.clipboard?.writeText && navigator.clipboard.writeText(text);
};

const randomString = (length) => {
  const chars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  return Array.from(
    { length },
    () => chars[Math.floor(Math.random() * chars.length)],
  ).join("");
};

function searchValueInNestedObject(obj, targetValue, currentPath = [], keysToSkip = []) {
  for (let prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop) && !keysToSkip.includes(prop)) {
      const newPath = currentPath.concat(prop);

      if (
        typeof obj[prop] === "string" &&
        obj[prop].toLowerCase().includes(targetValue.toLowerCase())
      ) {
        return newPath.join(".");
      }

      if (typeof obj[prop] === "object") {
        const result = searchValueInNestedObject(
          obj[prop],
          targetValue,
          newPath,
          keysToSkip
        );
        if (result !== undefined) {
          return result;
        }
      }
    }
  }
  return undefined;
}


export default {
  getRandomInt,
  blobToDataUrl,
  downloader,
  copy,
  randomString,
  searchValueInNestedObject,
};

import React from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import ReactCodeInput from "react-code-input";

export default function VerifyOtp({
  email,
  otp,
  setOtp,
  handleResend = () => {},
  handleBack = () => {},
  handleSubmit = () => {},
  isSending,
  isVerifying,
}) {
  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={2}>
        <Typography variant="body2" align="center">
          We have sent a 6 digit verification code to <b>{email}</b>
        </Typography>
        <Box
          textAlign="center"
          sx={(theme) => ({
            "& .react-code-input": {
              display: "flex !important",
              justifyContent: "center",
              flexWrap: "wrap",
            },
            "& input": {
              height: "40px !important",
              maxWidth: "60px !important",
              minWidth: "30px !important",
              boxShadow: "none !important",
              border: "1px solid #d1d3d4",
              borderRadius: "5px !important",
              padding: "0px !important",
              textAlign: "center",
              userSelect: "none",
              fontSize: `${theme.typography.h1.fontSize} !important`,
              margin: "5px 0 !important",
              flex: 1,
              "&:not(:last-child)": {
                marginRight: "5px !important",
              },
              "-moz-appearance": "textfield",
              "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
                "-webkit-appearance": "none",
                margin: 0,
              },
              "&::placeholder": {
                color: "#aaa",
              },
              "&:focus": {
                outline: "none",
              },
            },
          })}
        >
          <ReactCodeInput
            value={otp}
            fields={6}
            onChange={setOtp}
            type="number"
          />
        </Box>
        <Typography
          variant="caption"
          color="grey.600"
          align="center"
          sx={{ fontStyle: "italic" }}
        >
          Didn&apos;t receive the OTP?{" "}
          <Button disabled={isSending} onClick={handleResend}>
            {isSending ? "Sending..." : "Resend Code"}
          </Button>
        </Typography>
      </Stack>
      <Stack pt={3} direction="row" justifyContent="center" spacing={2}>
        <Button variant="outlined" disableElevation onClick={handleBack}>
          Back
        </Button>
        <Button
          type="submit"
          variant="contained"
          disableElevation
          disabled={isVerifying || otp.length < 6}
        >
          {isVerifying ? "Submitting..." : "Submit"}
        </Button>
      </Stack>
    </form>
  );
}

import React from "react";
import SKILLS from "constants/english/skills_list.json";
import { Autocomplete, TextField } from "@mui/material";

export default function SkillsInput(props) {
  const { defaultValue, onChange, textFieldProps } = props;

  return (
    <Autocomplete
      multiple
      freeSolo
      autoSelect
      popupIcon={false}
      disableClearable
      forcePopupIcon={false}
      options={SKILLS}
      getOptionLabel={(option) => option}
      value={defaultValue}
      onChange={onChange}
      filterSelectedOptions
      renderInput={(params) => (
        <TextField
          {...params}
          {...textFieldProps}
          label="Skills"
          InputLabelProps={{ shrink: true }}
        />
      )}
    />
  );
}

import React from "react";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Tooltip } from "@mui/material";

export default function PrivateCredentialIcon() {
  return (
    <Tooltip title="Private Credential">
      <LockOutlinedIcon fontSize="small" sx={{ opacity: 0.7 }} />
    </Tooltip>
  );
}

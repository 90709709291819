import { Box, Button, Checkbox, FormControlLabel, Stack, Typography } from '@mui/material'
import React from 'react'
import { useSearchParams } from 'react-router-dom';

export const SavePopover = (props) => {
    const { saveTemplate } = props;
    const [searchParams] = useSearchParams();
    const [isSaveACopyChecked, setIsSaveACopyChecked] = React.useState(false);
    const [isShareChecked, setIsShareChecked] = React.useState(false);

    return (
        <Box p={3} sx={{ minWidth: 260 }}>
            <Stack spacing={2}>
                <Typography variant="h4">Save this design</Typography>
                <Stack sx={{marginLeft: '4px !important', '& .MuiCheckbox-root': {padding: '5px' }, '& .MuiFormControlLabel-label': {fontSize: '0.85rem'}}}>
                    {searchParams.get("type") === "edit" && (
                        <FormControlLabel control={<Checkbox checked={isSaveACopyChecked} onChange={() => setIsSaveACopyChecked(prevState => !prevState)} />} label="Save as new template" />
                    )}
                    <FormControlLabel control={<Checkbox checked={isShareChecked} onChange={() => setIsShareChecked(prevState => !prevState)} />} label="Share within organization" />
                </Stack>
                <Stack spacing={1.5}>
                    <Button variant="contained" onClick={() => saveTemplate(isSaveACopyChecked, true, isShareChecked)}>Save & Use This</Button>
                    <Button variant="outlined" onClick={() => saveTemplate(isSaveACopyChecked, false, isShareChecked)}>Save & Close</Button>
                </Stack>
            </Stack>
        </Box>
    )
}

import React, { useEffect } from "react";
import Moveable from "react-moveable";
import {
  Box,
  Paper,
  Typography,
  Tooltip,
  IconButton,
  Skeleton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import constants from "constants/english/builder";
import { useLocation } from "react-router-dom";

const Canvas = (props) => {
  const {
    selectedElement,
    setSelectedElement,
    dimension,
    backgroundImg,
    setBackgroundImg,
    templateBgColor,
    canvasLoading,
  } = props;
  const location = useLocation();

  useEffect(() => {
    document.addEventListener("click", (e) => {
      const targetEl = e.target.closest(".selectable");
      const canvasWrapper = e.target.closest("#template-container");
      if (targetEl) {
        setSelectedElement(e.target);
      } else if (canvasWrapper) {
        setSelectedElement();
      }
    });
  }, []);

  const Editable = {
    name: "editable",
    props: [],
    events: [],
    render(moveable, React) {
      const rect = moveable.getRect();
      const { pos2 } = moveable.state;
      const EditableViewer = moveable.useCSS(
        "div",
        `{
          position: absolute;
          left: 0px;
          top: 0px;
          will-change: transform;
          transform-origin: 0px 0px;
          cursor: pointer;
        }`,
      );
      return (
        <EditableViewer
          key={"editable-viewer"}
          className={"moveable-editable"}
          style={{
            transform: `translate(${pos2[0]}px, ${pos2[1]}px) rotate(${rect.rotation}deg) translate(4px)`,
          }}
        >
          <DeleteIcon
            onClick={() => {
              selectedElement?.remove();
              setSelectedElement();
            }}
          />
        </EditableViewer>
      );
    },
  };

  return canvasLoading ? (
    <Skeleton
      variant="rectangle"
      width={dimension.width}
      height={dimension.height}
    />
  ) : (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="flex-start"
      flexGrow={1}
      bgcolor="primary.surface"
      overflow="hidden"
      height="calc(100dvh - 142px)"
      p={2}
    >
      <Typography variant="body2" mb={1}>
        Dimensions: {Math.round(dimension.width)}px X{" "}
        {Math.round(dimension.height)}px
      </Typography>
      <Box
        position="relative"
        sx={{
          transform: `scale(${1 / dimension.scale})`,
          transformOrigin: "top center",
        }}
      >
        {backgroundImg && !location?.state?.backgroundImg ? (
          <Box
            position="absolute"
            bottom="100%"
            right={0}
            sx={{
              transform: `scale(${dimension.scale})`,
              transformOrigin: "bottom center",
            }}
          >
            <Tooltip title="Remove background image">
              <IconButton size="small" onClick={() => setBackgroundImg()}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
        ) : null}
        <Paper
          elevation={2}
          id="template-container"
          mt={1}
          sx={{
            width: dimension.width + "px",
            height: dimension.height + "px",
            backgroundColor: templateBgColor,
          }}
        >
          {backgroundImg ? (
            <img
              src={backgroundImg}
              style={{ width: "100%", height: "100%", userSelect: "none" }}
            />
          ) : null}
          <Moveable
            target={selectedElement}
            ables={[Editable]}
            props={{
              editable: true,
            }}
            origin={false}
            draggable={true}
            throttleDrag={0}
            onDrag={({ target, left, top }) => {
              target.style.left = `${(left / dimension.width) * 100}%`;
              target.style.top = `${(top / dimension.height) * 100}%`;
            }}
            resizable={true}
            keepRatio={selectedElement?.tagName === "IMG"}
            renderDirections={["se", "s", "e"]}
            throttleResize={0}
            onResize={({ target, width, height, delta }) => {
              if (width > 1) {
                delta[0] && (target.style.width = `${width}px`);
              }
              if (height > 1) {
                delta[1] && (target.style.height = `${height}px`);
              }
            }}
            snappable={true}
            snapDirections={{
              top: true,
              left: true,
              bottom: true,
              right: true,
              middle: true,
              center: true,
            }}
            bounds={
              selectedElement?.tagName === "IMG" &&
              selectedElement?.src !== constants.QR_ICON
                ? {}
                : { left: 0, top: 0, right: 0, bottom: 0, position: "css" }
            }
            snapThreshold={5}
            verticalGuidelines={["0%", "50%", "100%"]}
            horizontalGuidelines={["0%", "50%", "100%"]}
          />
        </Paper>
      </Box>
    </Box>
  );
};

export default Canvas;

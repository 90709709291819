import React from "react";
import { Box, TextField } from "@mui/material";

const MyInputComponent = React.forwardRef(function comp(props, ref) {
  React.useImperativeHandle(ref, () => ({
    focus: () => {},
  }));
  return <Box sx={{ width: "100%" }}>{props.children}</Box>;
});

export default function CustomTextfield(props) {
  return (
    <TextField
      size="small"
      {...props}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        inputComponent: MyInputComponent,
        inputProps: { ...props },
      }}
      sx={{width: '100%'}}
    />
  );
}

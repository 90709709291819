import DataTable from "components/Table";
import { createColumnHelper } from "@tanstack/react-table";
import { customAxios, useAxios } from "hooks/useAxios";
import React from "react";
import moment from "moment";
import ActionBar from "./ActionBar";
import {
  Chip,
  IconButton,
  Link,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import TableSkeleton from "components/common/TableSkeleton";
import CustomDialog from "components/common/CustomDialog";
import SchedulePopup from "components/issuance/SchedulePopup";
import EditIcon from "@mui/icons-material/Edit";
import MySwal from "utils/Swal";

const steps = ["Design", "Credential Details", "Recipients", "Send"];
const StatusBar = (props) => {
  const { data } = props;
  const stepsTemp = [...steps];
  const activeStep = data?.recipients?.isCompleted
    ? 3
    : data?.credentialDetails?.isCompleted
    ? 2
    : data?.templateId || data?.uploadedDoc?.isCompleted
    ? 1
    : 0;
  if (!data?.templateId) {
    stepsTemp[0] = "Upload";
  }
  return (
    <Stepper
      activeStep={activeStep}
      sx={{
        width: 500,
        "& .MuiSvgIcon-root.Mui-completed": { color: "#009977" },
        "& .MuiSvgIcon-root.Mui-active": { color: "rgba(0, 0, 0, 0.38)" },
      }}
    >
      {stepsTemp.map((label) => {
        return (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
};

export default function DraftsTable({ isScheduled = false }) {
  const [data, isLoading, , refresh] = useAxios(
    isScheduled ? "docs/scheduled" : "drafts",
  );

  const editScheduleDateHandler = async (id, date) => {
    MySwal.showLoader("Processing...");
    let response = await customAxios.put(`docs/scheduled/${id}`, {
      scheduledDate: date,
    });

    if (response) {
      refresh();
      MySwal.fire({
        title: "Scheduled Date Updated!",
        text: "The scheduled date for the batch has been updated!",
        icon: "success",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  const columnHelper = createColumnHelper();

  const columnDefinitions = (isScheduled) => [
    columnHelper.accessor((row) => row?.credentialDetails, {
      id: "title",
      header: () => "Title",
      cell: (info) => {
        const data = info.row.original;
        const activeStep = data?.recipients?.isCompleted
          ? "send"
          : data?.credentialDetails?.isCompleted
          ? "recipients"
          : data?.templateId || data?.uploadedDoc?.isCompleted
          ? "settings"
          : "uploadCredentials";
        return (
          <Stack spacing={1} sx={{ width: "250px" }}>
            <Typography variant="h5" noWrap>
              <Link
                component={RouterLink}
                to={
                  isScheduled
                    ? `${process.env.REACT_APP_BASE_ROUTE}/user/scheduled/${info.row.original.draftId}`
                    : `${process.env.REACT_APP_BASE_ROUTE}/issuance/${
                        info.row.original?.templateId ? "create" : "existing"
                      }/${activeStep}/?draftId=${info.row.original._id}`
                }
                sx={{ textDecoration: "none", cursor: "pointer" }}
              >
                {info.getValue()?.title || "Untitled"}
              </Link>
            </Typography>
            <Stack direction="row" flexWrap="wrap" gap={1}>
              {info.getValue()?.tags?.map((label) => (
                <Chip
                  key={label}
                  label={label}
                  filled
                  size="small"
                  sx={{ fontSize: "0.75rem" }}
                />
              ))}
            </Stack>
          </Stack>
        );
      },
    }),
    columnHelper.accessor((row) => row, {
      id: "created",
      meta: {
        filterVariant: "date-range",
      },
      filterFn: "inDateRange",
      header: () => "Created",
      cell: (info) => (
        <>
          <Typography variant="body1">
            {moment(info.getValue().createdAt).format("DD-MM-YYYY")}
          </Typography>
          <Typography variant="caption">
            Last updated:{" "}
            {moment(info.getValue().updatedAt).format("DD-MM-YYYY")}
          </Typography>
        </>
      ),
    }),
    ...(isScheduled
      ? [
          columnHelper.accessor((row) => row, {
            id: "scheduledOn",
            meta: {
              filterVariant: "date-range",
            },
            filterFn: "inDateRange",
            header: () => "Scheduled On",
            cell: (info) => (
              <Stack direction="row" spacing={1} alignItems="center">
                {info.getValue()?.scheduledDate ? (
                  <Stack>
                    <Typography variant="body1">
                      {moment(info.getValue()?.scheduledDate).format(
                        "Do MMMM YYYY",
                      )}
                    </Typography>
                    <Typography variant="caption">
                      {moment(info.getValue()?.scheduledDate).format("h:mm A")}
                    </Typography>
                  </Stack>
                ) : (
                  "-"
                )}
                <CustomDialog
                  AnchorComponent={(syntax) => (
                    <IconButton size="small" {...syntax}>
                      <EditIcon fontSize="small" />
                    </IconButton>
                  )}
                  title="Schedule"
                  Content={(syntax) => (
                    <SchedulePopup
                      {...syntax}
                      defaultValue={moment(
                        info.getValue()?.scheduledDate || new Date(),
                      )}
                      handleSubmit={(newDate) =>
                        editScheduleDateHandler(info.row.original._id, newDate)
                      }
                    />
                  )}
                />
              </Stack>
            ),
          }),
        ]
      : [
          columnHelper.accessor((row) => row, {
            id: "status",
            enableColumnFilter: false,
            header: () => "Status",
            cell: (info) => (
              <StatusBar key={info.row.original._id} data={info.getValue()} />
            ),
          }),
        ]),
  ];

  return isLoading ? (
    <TableSkeleton />
  ) : (
    <DataTable
      columnDefinitions={columnDefinitions(isScheduled)}
      data={
        data?.filter(
          (row) => row?.templateId || row?.uploadedDoc?.ids?.length,
        ) || []
      }
      actionBar={(table) => (
        <ActionBar
          {...{
            table,
            refresh,
            isScheduled,
          }}
        />
      )}
    />
  );
}

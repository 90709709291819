import React, { createContext, useContext, useState } from "react";

const TourContext = createContext();

export const TourProvider = (props) => {
  const [tourStepIdx, setTourStepIdx] = useState(0);
  const [showTour, setShowTour] = React.useState(false);

  return (
    <TourContext.Provider
      value={{ tourStepIdx, setTourStepIdx, showTour, setShowTour }}
    >
      {props.children}
    </TourContext.Provider>
  );
};

export const useTour = () => {
  return useContext(TourContext);
};

import React, { useEffect, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  Box,
  Paper,
  TextField,
  Divider,
  Stack,
  Snackbar,
  Button,
  Grid,
} from "@mui/material";
import ToolTabs from "components/Builder/ToolTabs";
import PropertiesEditor from "components/Builder/PropertiesEditor";
import Canvas from "components/Builder/Canvas";
import constants from "constants/english/builder";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import MySwal from "utils/Swal";
import { useAuth } from "hooks/useAuth";
import { customAxios } from "hooks/useAxios";
import CustomPopover from "components/common/customPopover";
import { SavePopover } from "./SavePopover";
import Compressor from "compressorjs";

const calculateScale = (width, height) => {
  const workspaceWidth = window.innerWidth * 0.7 - 100,
    workspaceHeight = window.innerHeight - 200;
  const scale = Math.max(width / workspaceWidth, height / workspaceHeight);
  return scale;
};

const Builder = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { type: templateType, id: templateId } = useParams();
  const [searchParams] = useSearchParams();
  const draftId = searchParams.get("draftId");
  if (
    searchParams.get("type") === "upload" &&
    !location?.state?.backgroundImg
  ) {
    navigate(
      `${process.env.REACT_APP_BASE_ROUTE}/issuance/create/selectTemplate`,
    );
  }
  const [selectedElement, setSelectedElement] = useState();
  const [dimension, setDimension] = useState({
    width:
      constants.TEMPLATE_DEFAULT_DIMENSION["Certificate"].width /
      constants.TEMPLATE_DEFAULT_DIMENSION["Certificate"].scale,
    height:
      constants.TEMPLATE_DEFAULT_DIMENSION["Certificate"].height /
      constants.TEMPLATE_DEFAULT_DIMENSION["Certificate"].scale,
    scale: constants.TEMPLATE_DEFAULT_DIMENSION["Certificate"].scale,
  });
  const [templateName, setTemplateName] = useState(
    location?.state?.templateName || "Untitled",
  );
  const [backgroundImg, setBackgroundImg] = useState(
    location?.state?.backgroundImg || "",
  );
  const [templateBgColor, setTemplateBgColor] = useState(
    constants.DEFAULT_TEMPLATE_BG,
  );
  const [errorMsg, setErrorMsg] = useState();
  const [shouldShowComponent, setShouldShowComponent] = useState(true);

  const resetComponent = async () => {
    setBackgroundImg();
    setTemplateBgColor(constants.DEFAULT_TEMPLATE_BG);
    setShouldShowComponent(false);
    await new Promise((resolve) => setTimeout(resolve, 100)); // Delay for unmounting
    setShouldShowComponent(true);
  };

  const fetchTemplateHtml = async (id) => {
    try {
      MySwal.showLoader("Fetching template...");
      await resetComponent();
      const res = await customAxios.get(`template/file/${id}`);
      const canvas = document.getElementById("template-container");
      const parsedHtml = new DOMParser().parseFromString(
        res?.data?.response,
        "text/html",
      );
      setTemplateBgColor(parsedHtml?.body?.firstChild?.style?.backgroundColor);

      const tempWidth = parsedHtml?.body?.firstChild?.style?.width?.replace(
        "px",
        "",
      );
      const tempHeight = parsedHtml?.body?.firstChild?.style?.height?.replace(
        "px",
        "",
      );
      const scale = calculateScale(tempWidth, tempHeight);
      setDimension({
        width: tempWidth,
        height: tempHeight,
        scale: scale,
      });

      const childNodes = parsedHtml?.body?.firstChild?.childNodes;
      for (const actualNode of childNodes) {
        const node = actualNode.cloneNode(true);
        if (node.tagName === "IMG") {
          if (node.style.width === "100%" && node.style.userSelect === "none") {
            setBackgroundImg(node.src);
            continue;
          }
        } else {
          const isOnlyText = !(
            node.innerText[0] === "{" &&
            node.innerText[node.innerText.length - 1] === "}"
          );

          node.ondblclick = (e) => {
            e.target.contentEditable = true;
            if (!isOnlyText) {
              e.target.innerHTML = e.target.innerText
                .replaceAll("{", "")
                .replaceAll("}", "");
            }
            e.target.focus();
          };

          node.onblur = (e) => {
            if (!isOnlyText) {
              e.target.innerHTML = "{" + e.target.innerHTML.trim() + "}";
            }
            e.target.innerHTML = e.target.innerHTML
              .replaceAll(/&nbsp;/gi, "")
              .replaceAll(/<div><br><\/div>/gi, "")
              .replaceAll(/<p><br><\/p>/gi, "")
              .replaceAll('<span style="letter-spacing: 0px;">', "")
              .replaceAll("</span>", "")
              .replaceAll(/\s+/g, " ");
            e.target.contentEditable = false;
          };
        }
        canvas.appendChild(node);
      }
      MySwal.close();
    } catch (error) {
      MySwal.fire({
        title: "Unable to fetch template!",
        text: error?.response?.data?.message || error?.message || "",
        icon: "error",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };
  useEffect(() => {
    (async () => {
      const tempId = searchParams.get("templateId");

      if (templateId !== "new" || tempId) {
        await fetchTemplateHtml(tempId || templateId);
      }
    })();
  }, []);

  const insertElement = (varName, isOnlyText = false, icon, width, height, classNames) => {
    const rand = Math.floor(1000 + Math.random() * 9000);
    const id = `var-${rand}`;
    const canvas = document.getElementById("template-container");
    let attr;
    if (!icon) {
      attr = document.createElement("div");
      attr.innerText = isOnlyText ? varName : "{" + varName + "}";
      attr.style.fontFamily = "Arial";
      attr.style.fontSize = "24px";
      attr.style.width = "50%";
      attr.style.textAlign = "center";
      attr.style.color = "#000000";
      attr.style.overflow = "hidden";
      attr.style.left = "25%";
      attr.ondblclick = (e) => {
        e.target.contentEditable = true;
        if (!isOnlyText) {
          e.target.innerHTML = e.target.innerText
            .replaceAll("{", "")
            .replaceAll("}", "");
        }
        e.target.focus();
      };
      attr.onblur = (e) => {
        if (!isOnlyText) {
          e.target.innerHTML = "{" + e.target.innerHTML.trim() + "}";
        }
        e.target.innerHTML = e.target.innerHTML
          .replaceAll(/&nbsp;/gi, "")
          .replaceAll(/<div><br><\/div>/gi, "")
          .replaceAll(/<p><br><\/p>/gi, "")
          .replaceAll('<span style="letter-spacing: 0px;">', "")
          .replaceAll("</span>", "")
          .replaceAll(/\s+/g, " ");
        e.target.contentEditable = false;
      };
    } else {
      attr = document.createElement("span");
      classNames && attr.classList.add(classNames);
      attr.setAttribute("alt", varName);
      attr.style.backgroundImage = `url("${icon}")`;
      attr.style.backgroundSize = 'contain';
      attr.style.backgroundRepeat = 'no-repeat';
      attr.style.backgroundPosition = 'center';
      attr.style.width = (width || 60) + "px";
      attr.style.height = (height || 60) + "px";
      attr.style.left = "45%";
    }
    attr.id = id;
    attr.style.position = "absolute";
    attr.style.cursor = "move";
    attr.style.top = "45%";
    let highestZIndex = 0;
    document
      .querySelectorAll("#template-container .selectable")
      .forEach((element) => {
        highestZIndex = Math.max(highestZIndex, element.style.zIndex);
      });
    attr.style.zIndex = highestZIndex + 1;
    attr.classList.add(`selectable`);
    canvas.appendChild(attr);
    attr.click();
  };

  const imageInputChangeHandler = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile.size > 5242880) {
      setErrorMsg("File size cannot be more than 5mb!");
      setTimeout(() => {
        setErrorMsg();
      }, 5000);
      return;
    }
    new Compressor(selectedFile, {
      quality: 0.8,
      maxWidth: 1920,
      maxHeight: 1920,
      convertSize: 1000000,
      success: (compressedFile) => {
        const reader = new FileReader();
        reader.onload = function (evt) {
          const img = evt.target.result;
          const image = new Image();
          image.onload = function () {
            if (e.target.id === "backgroundInput") {
              setBackgroundImg(img);
            } else {
              const width = 60;
              const height = (this.height / this.width) * width;
              insertElement(selectedFile.name, false, img, width, height);
            }
          };
          image.src = img;
        };
        reader.readAsDataURL(compressedFile);
        e.target.value = null;
      },
    });
  };

  const saveTemplate = async (newCopy = true, redirect = true, shareWithOrg = false) => {
    console.log(shareWithOrg);
    const element = document.getElementById("template-container");

    if (element !== undefined && element != null) {
      MySwal.showLoader("Saving template...");

      element.querySelector(".moveable-control-box")?.remove();
      const translate = (dimension.scale - 1) * 50;
      const html = `<html><head><body style="margin: 0"><div style="-webkit-transform: translate(${translate}%, ${translate}%) scale(${dimension.scale}); width: ${dimension.width}px; height: ${dimension.height}px; background-color: ${templateBgColor}; overflow: hidden;">${element.innerHTML}</div></body></html>`;
      const file = new File([html], templateName + ".html", {
        type: "text/html",
      });

      const formData = new FormData();
      if (!newCopy && templateId !== 'new') {
        formData.append("templateId", JSON.stringify(templateId));
      }
      formData.append("file", file);
      formData.append("title", templateName);
      formData.append("companyId", user?.company?.id);
      formData.append("scope", shareWithOrg ? 'ORG' : 'USER');
      formData.append(
        "dimensions",
        JSON.stringify({
          width: dimension.width * dimension.scale,
          height: dimension.height * dimension.scale,
          scale: dimension.scale,
        }),
      );
      const response = await customAxios.post("template", formData);

      const id = response?.data?.response?._id;

      if (id) {
        MySwal.fire({
          text: "Template saved successfully!",
          allowEscapeKey: false,
          allowOutsideClick: false,
          icon: "success",
          showConfirmButton: false,
          timer: 2000,
        });


        if (redirect) {
          let draftIdTemp = draftId;
          if (!draftIdTemp) {
            const { data } = await customAxios.post("draft", {
              draft: { templateId: id },
            });
            draftIdTemp = data._id;
          }
          navigate(
            `${process.env.REACT_APP_BASE_ROUTE}/issuance/create/settings/?draftId=${draftIdTemp}`,
          );
        } else {
          navigate(-1);
        }
      }
    }
  };

  return (
    <Box>
      <Grid container p={1.5}>
        <Grid item xs={4}>
          <Button
            disableElevation
            variant="outlined"
            startIcon={<ArrowBackIosIcon />}
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
        </Grid>
        <Grid item xs={4}>
          <TextField
            value={templateName}
            onChange={(e) => setTemplateName(e.target.value)}
            variant="outlined"
            className="extra-small"
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <Stack direction="row-reverse" spacing={2}>
            <CustomPopover
              AnchorComponent={(props) => (
                <Button
                  {...props}
                  disableElevation
                  variant="contained"
                  endIcon={<ArrowForwardIosIcon />}
                >
                  Save
                </Button>
              )}
              PopoverComponent={() => <SavePopover saveTemplate={saveTemplate} />}
            />
          </Stack>
        </Grid>
      </Grid>
      <Divider />
      <Box display="flex">
        <ToolTabs
          width={constants.WORKSPACE_WIDTH_PROPORTION.toolbar}
          imageInputChangeHandler={imageInputChangeHandler}
          insertElement={insertElement}
          templateType={templateType}
          fetchTemplateHtml={fetchTemplateHtml}
        />
        <Box
          width={constants.WORKSPACE_WIDTH_PROPORTION.canvasArea}
          display="flex"
          flexDirection="column"
        >
          <Paper elevation={0}>
            <Stack direction="row" spacing={2} p={1.5}>
              <PropertiesEditor
                selectedElement={selectedElement}
                templateBgColor={templateBgColor}
                setTemplateBgColor={setTemplateBgColor}
                setDimension={setDimension}
                setSelectedElement={setSelectedElement}
                imageInputChangeHandler={imageInputChangeHandler}
              />
            </Stack>
          </Paper>
          <Divider />
          {shouldShowComponent && (
            <Canvas
              selectedElement={selectedElement}
              setSelectedElement={setSelectedElement}
              dimension={dimension}
              backgroundImg={backgroundImg}
              setBackgroundImg={setBackgroundImg}
              templateBgColor={templateBgColor}
            />
          )}
        </Box>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={Boolean(errorMsg)}
        message={errorMsg}
      />
    </Box>
  );
};

export default Builder;

import React from "react";
import { Button, Stack, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

const privateDoc =
  "https://cdn-stage.itscredible.com/assets/images/privateDoc.svg";

export default function PrivateCredentialLogin(props) {
  const { showLogin } = props;
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <Stack
      height="100vh"
      justifyContent="center"
      alignItems="center"
      spacing={2}
      p={2}
    >
      <img
        src={privateDoc}
        alt="Private-Credential"
        height="50%"
        style={{ maxWidth: "100%" }}
      />
      <Stack spacing={1}>
        <Typography variant="h2" align="center">
          This Credential is Private!
        </Typography>
        <Typography variant="body1" align="center">
          Private credentials will only be visible to the issuer and the
          recipient.
        </Typography>
      </Stack>
      {showLogin && (
        <Stack direction="row" spacing={3} pt={1}>
          <Button
            variant="outlined"
            onClick={() =>
              navigate(`${process.env.REACT_APP_BASE_ROUTE}/auth/login`, {
                state: { redirectTo: location.pathname },
              })
            }
          >
            Login as an Issuer
          </Button>
          <Button
            variant="outlined"
            onClick={() => (window.location.href = "https://credulocker.com")}
          >
            Login as a Reciepient
          </Button>
        </Stack>
      )}
    </Stack>
  );
}

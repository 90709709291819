import React from "react";
import {
  Divider,
} from "@mui/material";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { UsersTab } from "./UsersTab";
import DepartmentsTab from "./DepartmentsTab";

const UserManagement = () => {
  const [activeTab, setActiveTab] = React.useState('1');

  return (
    <TabContext value={activeTab}>
      <TabList onChange={(e, newValue) => setActiveTab(newValue)} aria-label="user management tabs">
        <Tab label="Users" value="1" />
        <Tab label="Departments" value="2" />
      </TabList>
      <Divider />
      <TabPanel value="1">
        <UsersTab />
      </TabPanel>
      <TabPanel value="2">
        <DepartmentsTab />
      </TabPanel>
    </TabContext>
  );
};

export default UserManagement;

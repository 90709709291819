import React from "react";
import { Chip, Link, Stack, Tooltip, Typography } from "@mui/material";
import Table from "components/Table";
import { customAxios } from "hooks/useAxios.js";
import TableSkeleton from "components/common/TableSkeleton";
import ActionBar from "components/ScheduledCredsTable/ActionBar";
import { createColumnHelper } from "@tanstack/react-table";
import moment from "moment";
import EditableEmailCell from "../CredentialsTable/EditableEmailCell";
import EditableWhatsappCell from "../CredentialsTable/EditableWhatsappCell";
import PrivateCredentialIcon from "components/common/PrivateCredentialIcon";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";

const columnHelper = createColumnHelper();

export default function ScheduledCredsTable(props) {
  const { draftId } = props;
  const [isLoading, setIsLoading] = React.useState(false);
  const [docs, setDocs] = React.useState([]);
  const [credentialsReady, setCredentialsReady] = React.useState([]);

  const columnDefinitions = [
    columnHelper.accessor((row) => row?.sharedWith?.name || row.fileName, {
      id: "name",
      header: () => "Recipient Name",
      cell: (info) => (
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          sx={{ width: "200px" }}
        >
          {info?.row?.original?.visibility === "PRIVATE" && (
            <PrivateCredentialIcon />
          )}
          {info?.row?.original?.isRevoked && (
            <Tooltip
              title={`Credential access is revoked for the recipient. Message: ${info?.row?.original?.revokeMessage}`}
            >
              <VisibilityOffOutlinedIcon
                fontSize="small"
                sx={{ opacity: 0.7 }}
              />
            </Tooltip>
          )}
          <Typography variant="h5" noWrap>
            <Link
              href={`${process.env.REACT_APP_BASE_ROUTE}/qr/${info?.row?.original?.displayId}`}
              target="_blank"
              sx={{ textDecoration: "none", cursor: "pointer" }}
            >
              {info.getValue()}
            </Link>
          </Typography>
        </Stack>
      ),
    }),
    columnHelper.accessor((row) => row?.sharedWith?.email, {
      id: "email",
      header: () => "Email Address",
      cell: EditableEmailCell,
    }),
    columnHelper.accessor((row) => row?.sharedWith?.whatsappNumber, {
      id: "whatsapp",
      header: () => "WhatsApp Number",
      cell: EditableWhatsappCell,
    }),

    columnHelper.accessor((row) => row?.docInfo?.expiryDate, {
      id: "expired_on",
      header: () => "Expiry Date",
      cell: (info) =>
        info.getValue() ? (
          moment(info.getValue()).format("DD-MM-YYYY")
        ) : (
          <div style={{ textAlign: "center" }}>-</div>
        ),
    }),
    columnHelper.accessor((row) => row?.docTags, {
      id: "labels",
      header: () => "Labels",
      cell: (info) =>
        info
          .getValue()
          ?.filter(
            (tag) =>
              tag !== "vm_removed_from_all" && tag !== "vm_removod_from_all",
          )?.length ? (
          info
            .getValue()
            .filter(
              (tag) =>
                tag !== "vm_removed_from_all" && tag !== "vm_removod_from_all",
            )
            .map((tag) => <Chip key={tag} label={tag} sx={{ mx: 0.5 }} />)
        ) : (
          <div style={{ textAlign: "center" }}>-</div>
        ),
    }),
    columnHelper.accessor((row) => row?.skills, {
      id: "skills",
      header: () => "Skills",
      cell: (info) =>
        info.getValue()?.length ? (
          info
            .getValue()
            .map((skill) => <Chip key={skill} label={skill} sx={{ mx: 0.5 }} />)
        ) : (
          <div style={{ textAlign: "center" }}>-</div>
        ),
    }),
  ];

  const fetchDocs = async () => {
    setIsLoading(true);
    let response = await customAxios.get(`docs/scheduled/${draftId}`);
    if (response?.data) {
      setDocs(response.data.docs);
      const isNotReady = response.data.docs.some(
        (doc) => doc.status !== "SCHEDULED",
      );
      setCredentialsReady(!isNotReady);
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    fetchDocs();
  }, [draftId]);

  return (
    <>
      {isLoading ? (
        <TableSkeleton />
      ) : (
        <Table
          columnDefinitions={columnDefinitions}
          data={docs}
          setData={setDocs}
          actionBar={(table) => (
            <ActionBar
              {...{
                table,
                fetchDocs,
                credentialsReady,
              }}
            />
          )}
        />
      )}
    </>
  );
}

import React from "react";
import IssuanceLayout from "layouts/IssuanceLayout";
import ParallelSection from "components/issuance/parallelSections";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import SendEmailNotificationForm from "components/issuance/SendEmailNotificationForm";
import SendWhatsappNotificationForm from "components/issuance/SendWhatsappNotificationForm";
import { useIssuanceDataProvider } from "hooks/useIssuanceDataProvider";
import { customAxios } from "hooks/useAxios";
import {
  Box,
  Button,
  ButtonGroup,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Stack,
  Typography,
} from "@mui/material";
import useResponsive from "hooks/useResponsive";
import MySwal from "utils/Swal";
import { useAuth } from "hooks/useAuth";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ScheduleSendOutlinedIcon from "@mui/icons-material/ScheduleSendOutlined";
import CustomPopover from "components/common/customPopover";
import CustomDialog from "components/common/CustomDialog";
import SchedulePopup from "components/issuance/SchedulePopup";
import moment from "moment";

const finalizeEmail = (body) => {
  const style =
    '"text-decoration: none; padding: 10px 15px; border-radius: 3px; font-size: 16px; display: inline-block; background: #0d98ba; color: #fff;"';
  const hrefPosition = body.indexOf('href="{{DocumentLink}}"');
  let mailBody = `${body.slice(0, hrefPosition)}style=${style}${body.slice(
    hrefPosition,
  )}`;
  mailBody = mailBody.replaceAll("<p>", '<p style="margin: 0">');
  return mailBody;
};

export default function SendCredential() {
  const { issuanceData, setIssuanceData } = useIssuanceDataProvider();
  const { user, reloadUser } = useAuth();
  const navigate = useNavigate();
  const { issuanceType } = useParams();
  const [searchParams] = useSearchParams();
  const draftId = searchParams.get("draftId");
  const { isMobile } = useResponsive();
  const [drawer, setDrawer] = React.useState(false);
  const [formData, setFormData] = React.useState({
    emailSettings: issuanceData?.emailSettings,
    whatsappSettings: issuanceData?.whatsappSettings,
  });
  let sent = false;

  const to = issuanceData?.recipients?.csvData
    ?.filter((obj) => Object.keys(obj).length > 0)
    .map((data) => data?.["Email Address"]);
  const whatsappTo = issuanceData?.recipients?.csvData
    ?.filter((obj) => Object.keys(obj).length > 0)
    .map((data) => data?.["WhatsApp Number"]);

  const notificationPlatform =
    issuanceData?.credentialDetails?.notificationPlatform;

  const handleSubmit = async (scheduledDate = null) => {
    MySwal.showLoader(
      "Processing...",
      "Your credentials are getting ready to be sent.",
    );

    const publishDraft = async () => {
      const response = await customAxios.post(
        `drafts/publish/${issuanceType}`,
        {
          draftId,
          isScheduled: Boolean(scheduledDate),
          scheduledDate,
        },
      );

      if (response) {
        sent = true;
        reloadUser();
        if (scheduledDate) {
          MySwal.fire({
            title: "Scheduled!",
            text: `The credentials are scheduled to be delivered on ${moment(
              scheduledDate,
            ).format("Do MMMM YYYY, h:mm A")}`,
            allowEscapeKey: false,
            allowOutsideClick: false,
            icon: "success",
            showConfirmButton: false,
            timer: 2000,
          });
          navigate(
            `${process.env.REACT_APP_BASE_ROUTE}/user/home/?filter=SCHEDULED`,
          );
        } else {
          MySwal.fire({
            title: "Success!",
            text: "Track progress in 'Outbox'. Delivered credentials appear in 'Sent' folder.",
            allowEscapeKey: false,
            allowOutsideClick: false,
            icon: "success",
            showConfirmButton: false,
            timer: 2000,
          });
          navigate(
            `${process.env.REACT_APP_BASE_ROUTE}/user/home/?filter=OUTBOX`,
          );
        }
      }
    };

    const newEmailSettings = { ...formData.emailSettings, isComplete: true };
    const newWhatsappSettings = {
      ...formData.whatsappSettings,
      isComplete: true,
    };
    newEmailSettings.body = finalizeEmail(newEmailSettings.body);
    await setIssuanceData(
      {
        emailSettings: newEmailSettings,
        whatsappSettings: newWhatsappSettings,
      },
      publishDraft,
    );
  };

  // Save on unmount
  const oldPathName = location.pathname;
  React.useEffect(() => {
    return () => {
      if (oldPathName !== window.location.pathname && !sent) {
        setIssuanceData({
          emailSettings: { ...formData?.emailSettings },
          whatsappSettings: { ...formData?.whatsappSettings },
        });
      }
    };
  }, [formData]);

  const handleSave = (e) => {
    e.target.innerText = "Saving...";
    const callback = () => {
      e.target.innerText = "Saved!";
      setTimeout(() => {
        e.target.innerText = "Save";
      }, 5000);
    };
    setIssuanceData(
      {
        emailSettings: {
          ...formData?.emailSettings,
        },
        whatsappSettings: { ...formData?.whatsappSettings },
      },
      callback,
    );
  };

  return (
    <IssuanceLayout>
      <ParallelSection drawer={drawer} setDrawer={setDrawer}>
        <Stack spacing={3} data-tut="SendCredential">
          {notificationPlatform?.email && (
            <>
              <Typography variant="h6">Email Setup</Typography>
              <SendEmailNotificationForm
                formData={formData?.emailSettings}
                setFormData={setFormData}
                to={to}
              />
            </>
          )}

          <Box mt={2} />

          {notificationPlatform?.whatsapp && (
            <>
              <Typography variant="h6">WhatsApp Setup</Typography>
              <SendWhatsappNotificationForm
                message={formData?.whatsappSettings?.message}
                setMessage={(newMsg) =>
                  setFormData((prevState) => ({
                    ...prevState,
                    whatsappSettings: {
                      ...prevState?.whatsappSettings,
                      message: newMsg,
                    },
                  }))
                }
                to={whatsappTo}
              />
            </>
          )}

          <Stack direction="row" spacing={2} justifyContent="center">
            {isMobile && (
              <Button
                variant="outlined"
                disableElevation
                sx={{ minWidth: 150 }}
                onClick={() => setDrawer(true)}
              >
                Preview
              </Button>
            )}
            <Button
              variant="outlined"
              onClick={handleSave}
              sx={{ minWidth: 100 }}
            >
              Save
            </Button>
            <ButtonGroup
              variant="contained"
              disabled={
                (user?.plan !== "FREE" && user.credits <= 0) ||
                (notificationPlatform?.email &&
                  (!formData?.emailSettings?.body ||
                    !formData?.emailSettings?.subject)) ||
                (notificationPlatform?.whatsapp &&
                  !formData?.whatsappSettings?.message)
              }
              data-tut="SendCredentialSendBtn"
            >
              <Button onClick={() => handleSubmit()}>Send</Button>
              <CustomPopover
                AnchorComponent={(syntax) => (
                  <Button
                    {...syntax}
                    size="small"
                    sx={{ px: "4px !important", minWidth: "0 !important" }}
                  >
                    <ArrowDropDownIcon />
                  </Button>
                )}
                PopoverComponent={(syntax) => (
                  <MenuList {...syntax} autoFocus={false}>
                    <CustomDialog
                      AnchorComponent={(syntax) => (
                        <MenuItem {...syntax}>
                          <ListItemIcon>
                            <ScheduleSendOutlinedIcon color="primary" />
                          </ListItemIcon>
                          <ListItemText>Schedule Send</ListItemText>
                        </MenuItem>
                      )}
                      title="Schedule"
                      Content={(syntax) => (
                        <SchedulePopup
                          {...syntax}
                          handleSubmit={handleSubmit}
                        />
                      )}
                    />
                  </MenuList>
                )}
              />
            </ButtonGroup>
          </Stack>
        </Stack>
      </ParallelSection>
    </IssuanceLayout>
  );
}

import React from "react";
import {
  Box,
  Divider,
  TextField,
  Paper,
  Stack,
  Chip,
} from "@mui/material";
import TextFieldLayout from "components/common/TextfieldLayout";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import WhatsappBg from "assets/images/whatsapp_bg.jpg";

export default function SendWhatsappNotificationForm(props) {
  const { message, setMessage, to } = props;

  return (
    <Stack spacing={3}>
      <TextFieldLayout label="To">
        <Stack
          direction="row"
          flexWrap="wrap"
          pt={1.1}
          pb={0.3}
          pl={1}
          pr={0.5}
          sx={{ overflow: "auto", maxHeight: "150px" }}
        >
          {to?.map((email) => (
            <Box key={email} mr={0.5} mb={0.5}>
              <Chip label={email} size="small" />
            </Box>
          ))}
        </Stack>
      </TextFieldLayout>

      <Box
        p={4}
        sx={{
          border: `1px solid #ddd`,
          borderRadius: 2,
          background: `url(${WhatsappBg})`,
          backgroundSize: "500px",
        }}
      >
        <Paper
          elevation={2}
          sx={{
            maxWidth: "400px",
          }}
        >
          <Box p={2}>
            <TextField
              variant="outlined"
              defaultValue={message}
              onChange={(e) => setMessage(e.target.value)}
              fullWidth
              multiline
            />
            <br />
            <br />
            <span>Access all your documents on CreduLocker.</span>
            <br />
            <a href="#">https://credulocker.com</a>
            <br />
            <br />
            <i>
              Or simply click on the following link to view and download your
              credentials:
            </i>
            <br />
            <a href="#" style={{ pointerEvents: "none" }}>
              https://portal.itscredible.com/qr/document_id
            </a>
          </Box>
          <Divider />
          <Box p={1} display="flex" justifyContent="center" alignItems="center">
            <OpenInNewOutlinedIcon />
            &nbsp;&nbsp;View credential
          </Box>
        </Paper>
      </Box>
    </Stack>
  );
}

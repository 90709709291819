import React from "react";
import { Paper, Skeleton, Stack, Typography } from "@mui/material";
import CopyButton from "components/common/CopyButton";
import { useAxios } from "hooks/useAxios";

const CopyValue = (props) => {
  const { value } = props;
  return (
    <Paper
      variant="outlined"
      sx={{
        backgroundColor: "primary.surface",
        px: 2,
        py: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      {value}
      <CopyButton value={value} />
    </Paper>
  );
};

export default function ApiIntegration() {
  const [data, isLoading] = useAxios("getClientFromCognito");
  const CONTENT = [
    {
      label: "Access Token URL",
      value: `https://auth${
        window.location.host.split(".")[0].toLowerCase() === "portal"
          ? ""
          : "-stage"
      }.itscredible.com/oauth2/token`,
    },
    {
      label: "Content-Type",
      value: "application/x-www-form-urlencoded",
    },
    {
      label: "grant_type",
      value: "client_credentials",
    },
    {
      label: "client_id",
      value: data?.response?.UserPoolClient?.ClientId,
    },
    {
      label: "client_secret",
      value: data?.response?.UserPoolClient?.ClientSecret,
    },
  ];
  return (
    <Stack py={3} spacing={3}>
      <Typography variant="body1">
        Please use the following resources to get the access token to use the
        APIs.
      </Typography>
      {CONTENT.map((elem) => (
        <Stack key={elem.label} direction="column" spacing={0.5}>
          <Typography variant="body1">{elem.label}</Typography>
          {isLoading ? (
            <Skeleton variant="rectangular" height={54} />
          ) : (
            <CopyValue value={elem.value} />
          )}
        </Stack>
      ))}
    </Stack>
  );
}

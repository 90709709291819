import React from "react";
import { Stepper, Step, Typography, StepButton } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate, useLocation } from "react-router-dom";

const StepProgress = (props) => {
  const { steps } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <Stepper activeStep={steps.findIndex((s) => s.isActive)} data-tut="IssuanceStepper">
      {steps.map((step) => (
        <Step key={step.id} completed={step.isCompleted}>
          <StepButton
            color="inherit"
            onClick={() =>
              navigate(`${step.url}/${location.search}`, {
                state: location.state,
              })
            }
            disableRipple
          >
            {!isMobile && <Typography variant="body2">{step.label}</Typography>}
          </StepButton>
        </Step>
      ))}
    </Stepper>
  );
};

export default StepProgress;

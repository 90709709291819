import React from "react";
import { Button, Link, Stack, Typography } from "@mui/material";
import { useAuth } from "hooks/useAuth";
import AddIcon from "@mui/icons-material/Add";
import { Link as RouterLink, useNavigate } from "react-router-dom";

export default function Wallet(props) {
  const { renderTransactionHistoryAnchor = false } = props;
  const navigate = useNavigate();
  const { user } = useAuth();
  return (
    <Stack spacing={1}>
      <Stack direction="row" spacing={10} alignItems="center">
        <Stack>
          <Typography variant="body2">Balance</Typography>
          <Stack direction="row" alignItems="flex-end" spacing={0.5}>
            <Typography variant="h4">{user?.credits}</Typography>
            <Typography variant="body2">credits</Typography>
          </Stack>
        </Stack>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          startIcon={<AddIcon />}
          size="small"
          onClick={() =>
            navigate(`${process.env.REACT_APP_BASE_ROUTE}/credits/buy`)
          }
        >
          Buy Credits
        </Button>
      </Stack>
      {renderTransactionHistoryAnchor ? (
        <Typography variant="body2">
          <Link
            component={RouterLink}
            to={`${process.env.REACT_APP_BASE_ROUTE}/credits/purchaseHistory`}
          >
            Purchase History
          </Link>
        </Typography>
      ) : null}
    </Stack>
  );
}

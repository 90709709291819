import React from "react";
import AuthLayout from "layouts/AuthLayout";
import { Link, TextField, Typography, Button, Stack } from "@mui/material";
import Form from "components/auth/Form";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { customAxios } from "hooks/useAxios.js";
import Checkbox from "@mui/material/Checkbox";
import validator from "validator";
import PasswordInput from "components/common/PasswordInput";

export default function RegisterStep2() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = React.useState(false);
  const [formData, setFormData] = React.useState({
    orgName: location?.state?.registrationData?.orgName || "",
    orgSite: location?.state?.registrationData?.orgSite || "",
    email: location?.state?.registrationData?.email || "",
    userName: location?.state?.registrationData?.userName || "",
    phone: location?.state?.registrationData?.phone || "",
    password: location?.state?.registrationData?.password || "",
    agree: location?.state?.registrationData?.agree || false,
  });

  const handleRegister = async () => {
    setIsLoading(true);
    const response = await customAxios.post("register/user", formData);
    if (response?.status === 200) {
      navigate(`${process.env.REACT_APP_BASE_ROUTE}/auth/register/step2`, {
        state: { registrationData: formData },
      });
    }
    setIsLoading(false);
  };

  return (
    <AuthLayout>
      <Form
        onSubmit={handleRegister}
        heading="Register to Issue Credentials"
        field1={
          <TextField
            label="Organization Name"
            variant="outlined"
            value={formData.orgName}
            onChange={(e) =>
              setFormData((prevState) => ({
                ...prevState,
                orgName: e.target.value,
              }))
            }
            required
          />
        }
        field2={
          <TextField
            label="Organization Website"
            variant="outlined"
            error={formData.orgSite && !validator.isURL(formData.orgSite)}
            helperText={
              formData.orgSite && !validator.isURL(formData.orgSite)
                ? "Invalid URL"
                : ""
            }
            value={formData.orgSite}
            onChange={(e) =>
              setFormData((prevState) => ({
                ...prevState,
                orgSite: e.target.value,
              }))
            }
            required
          />
        }
        field3={
          <TextField
            label="Your Email"
            variant="outlined"
            error={formData.email && !validator.isEmail(formData.email)}
            helperText={
              formData.email && !validator.isEmail(formData.email)
                ? "Invalid Email"
                : ""
            }
            value={formData.email}
            onChange={(e) =>
              setFormData((prevState) => ({
                ...prevState,
                email: e.target.value,
              }))
            }
            type="email"
            required
          />
        }
        field4={
          <TextField
            label="Your Name"
            variant="outlined"
            value={formData.userName}
            onChange={(e) =>
              setFormData((prevState) => ({
                ...prevState,
                userName: e.target.value,
              }))
            }
            required
          />
        }
        field5={
          <TextField
            label="Phone Number"
            variant="outlined"
            error={formData.phone && !validator.isMobilePhone(formData.phone)}
            helperText={
              formData.phone && !validator.isMobilePhone(formData.phone)
                ? "Invalid Phone Number"
                : ""
            }
            value={formData.phone}
            onChange={(e) =>
              setFormData((prevState) => ({
                ...prevState,
                phone: e.target.value.replace(/[^0-9]/g, ""),
              }))
            }
            required
          />
        }
        field6={
          <PasswordInput
            label="Password"
            variant="outlined"
            helperText="Password must be 8 characters minimum, with at least one lowercase letter and one numeric digit."
            value={formData.password}
            onChange={(e) =>
              setFormData((prevState) => ({
                ...prevState,
                password: e.target.value,
              }))
            }
            required
          />
        }
        field7={
          <Stack direction="row" alignItems="center">
            <Checkbox
              required
              checked={formData.agree}
              onChange={(e) =>
                setFormData((prevState) => ({
                  ...prevState,
                  agree: e.target.checked,
                }))
              }
            />
            <Typography variant="caption" component="span">
              I agree to{" "}
              <Link
                target="_blank"
                href="https://itscredible.com/terms-and-condition/"
              >
                Terms & Conditions
              </Link>{" "}
              and{" "}
              <Link
                target="_blank"
                href="https://itscredible.com/terms-and-condition/"
              >
                Privacy Policy
              </Link>
              .
            </Typography>
          </Stack>
        }
        primaryAction={
          <Button
            disabled={
              isLoading ||
              !validator.isURL(formData.orgSite) ||
              !validator.isEmail(formData.email) ||
              !validator.isMobilePhone(formData.phone) ||
              !formData.agree
            }
            variant="contained"
            size="large"
            disableElevation
            type="submit"
          >
            {isLoading ? "Registering..." : "Register"}
          </Button>
        }
        secondaryAction={
          <Typography variant="body2" align="center">
            Already have an account?&nbsp;
            <Link
              component={RouterLink}
              to={`${process.env.REACT_APP_BASE_ROUTE}/auth/login`}
            >
              Login
            </Link>
          </Typography>
        }
      />
    </AuthLayout>
  );
}

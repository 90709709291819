import React from "react";
import { Stack, Typography } from "@mui/material";

const CredentialNotFoundImg =
  "https://cdn-stage.itscredible.com/assets/images/credential-not-found.svg";

export default function CredentialNotFound() {
  return (
    <Stack
      height="100vh"
      justifyContent="center"
      alignItems="center"
      spacing={2}
      p={2}
    >
      <img
        src={CredentialNotFoundImg}
        alt="Credential-Not-Found"
        height="50%"
        style={{ maxWidth: "100%" }}
      />
      <Stack spacing={1}>
        <Typography variant="h2" align="center">
          Credential not found!
        </Typography>
        <Typography variant="body1" align="center">
          Please check the URL.
        </Typography>
      </Stack>
    </Stack>
  );
}

import React, { useState } from "react";
import { InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useSearchParams } from "react-router-dom";

export default function SearchBar(props) {
  const { autoFocus = false } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  let [searchText, setSearchText] = useState(searchParams.get("searchText"));
  React.useEffect(() => {
    setSearchText(searchParams.get("searchText") || "");
  }, [searchParams]);
  const handleSearch = (e) => {
    e.preventDefault();
    searchText = searchText.trim();
    if (searchText) {
      setSearchParams({ searchText });
    } else {
      setSearchParams({ filter: "SENT" });
    }
  };
  return (
    <form onSubmit={handleSearch}>
      <TextField
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        variant="outlined"
        className="extra-small"
        size="small"
        placeholder="Search Credentials..."
        fullWidth
        autoFocus={autoFocus}
        autoComplete="off"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </form>
  );
}

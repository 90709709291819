import React from "react";
import {
  Stack,
  Button,
  IconButton,
  TextField,
  MenuItem,
  Tooltip,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useAuth } from "hooks/useAuth";

export default function ActionBar(props) {
  const { table, onImport, callback, fetchDocs, isSeeAll, setIsSeeAll } = props;
  const { user } = useAuth();

  const handleImport = () => {
    const selectedRows = table
      .getSelectedRowModel()
      .rows.map(({ original }) => ({
        "Email Address": original.sharedWith.email,
        "WhatsApp Number": original.sharedWith.whatsappNumber,
        "Recipient Name": original?.metaData?.mappedName,
      }));
    onImport((prevState) => ({ ...prevState, csvData: selectedRows }));
    callback();
  };

  return (
    <Stack direction="row" spacing={1} alignItems="center" width="100%">
      <Tooltip title="Reload">
        <IconButton onClick={fetchDocs}>
          <RefreshIcon />
        </IconButton>
      </Tooltip>
      {onImport && (
        <Button variant="contained" onClick={handleImport}>
          Import
        </Button>
      )}
      {user?.role === "VM_COMPANY_ADMIN" && (
        <TextField
          select
          defaultValue={isSeeAll}
          size="small"
          className="extra-small"
          onChange={(e) => setIsSeeAll(e.target.value)}
          sx={{ marginLeft: "auto !important", minWidth: "130px" }}
        >
          <MenuItem value="false">My Records</MenuItem>
          <MenuItem value="true">All Records</MenuItem>
        </TextField>
      )}
    </Stack>
  );
}

import React, { useState, useMemo, useCallback } from "react";
import {
    Box,
    Paper,
    Tooltip,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    Typography,
    Grid,
    Avatar,
    AvatarGroup
} from "@mui/material";
import { DeleteOutlined, EditOutlined } from "@mui/icons-material";
import CustomDialog from "components/common/CustomDialog";
import AddOrEditDepartmentForm from "./AddOrEditDepartmentForm";
import SearchBar from "./SearchBar";
import MySwal from "utils/Swal";
import { customAxios, useAxios } from "hooks/useAxios";
import UserCard from "./UserCard";
import commonHelpers from "utils/commonHelpers";
import { useAuth } from "hooks/useAuth";

const ParentComponent = () => {
    const { user } = useAuth();
    const [userData] = useAxios(`user?companyId=${user.companyId}`);
    const users = userData?.response || [];

    return (
        <DepartmentsTab users={users} />
    );
};

const DepartmentsTab = ({ users }) => {
    const [data, isLoading, error, refresh] = useAxios('departments');
    const [searchedValue, setSearchValue] = useState(null);

    const departmentsArray = data?.response?.departments || [];
    const departments = useMemo(() => 
        searchedValue
            ? departmentsArray.filter(department => 
                Boolean(commonHelpers.searchValueInNestedObject(department, searchedValue, [], ['_id', 'userIds', 'adminIds', 'companyId', 'updatedAt', 'createdAt', '__v'])))
            : departmentsArray, 
        [departmentsArray, searchedValue]
    );

    const handleCreateDepartment = useCallback(async (formData, cb) => {
        await customAxios.post('department', formData);
        cb();
        refresh();
    }, [refresh]);

    const handleUpdateDepartment = useCallback(async (departmentId, formData) => {
        await customAxios.post(`department/${departmentId}`, formData);
        refresh();
    }, [refresh]);

    const handleDelete = useCallback(async (departmentId) => {
        const { isConfirmed } = await MySwal.fire({
            title: "Are you sure?",
            text: "Do you want to delete the selected department?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
        });
        if (isConfirmed) {
            await customAxios.delete(`department/${departmentId}`);
            refresh();
        }
    }, [refresh]);

    return (
        <>
            <Grid container spacing={1} justifyContent="space-between">
                <Grid item>
                    <CustomDialog
                        size="md"
                        AnchorComponent={anchorProps => (
                            <Button {...anchorProps} variant="contained" disableElevation>
                                Add New Department
                            </Button>
                        )}
                        title="Add Department"
                        Content={dialogProps => (
                            <AddOrEditDepartmentForm onSubmit={(p) => {handleCreateDepartment(p, dialogProps.handleClose)}} {...dialogProps} />
                        )}
                    />
                </Grid>
                <Grid item>
                    <SearchBar setSearchValue={setSearchValue} />
                </Grid>
            </Grid>
            <Box mt={2}>
                {isLoading ? (
                    "Loading..."
                ) : error ? (
                    "Unable to load data!"
                ) : (
                    <TableContainer component={Paper} variant="outlined">
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell width="40%">Department Name</TableCell>
                                    <TableCell width="20%">Users</TableCell>
                                    <TableCell width="20%">Admin Users</TableCell>
                                    <TableCell width="20%" align="right"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {!departments.length ? (
                                    <TableRow>
                                        <TableCell colSpan={4} align="center">
                                            No Data Found.
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    departments.map(department => (
                                        <TableRow key={department.departmentName}>
                                            <TableCell>
                                                <Typography variant="h5">{department.departmentName}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <UserList
                                                    title={`Users in ${department.departmentName}`}
                                                    userIds={department.userIds}
                                                    users={users}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <UserList
                                                    title={`Admin users in ${department.departmentName}`}
                                                    userIds={department.adminIds}
                                                    users={users}
                                                />
                                            </TableCell>
                                            <TableCell align="right">
                                                <ActionButtons
                                                    department={department}
                                                    onEdit={handleUpdateDepartment}
                                                    onDelete={handleDelete}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </Box>
        </>
    );
};

const stringToColor = (string) => {
    let hash = 0;
    for (let i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";
    for (let i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    return color;
};

const stringAvatar = (name) => ({
    sx: {
        bgcolor: stringToColor(name),
        width: "60px",
        height: "60px",
        fontSize: "2rem",
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")?.[1]?.[0] || ""}`.toUpperCase(),
});

const UserList = ({ title, userIds, users }) => {
    const usersToBeDisplay = useMemo(() => users.filter(user => (userIds || []).includes(user.uid)), [users, userIds]);

    return (
        <>
            {!usersToBeDisplay.length ?
                "-"
                : <CustomDialog
                    size="md"
                    AnchorComponent={anchorProps => (
                        <Tooltip title="Click to see list" placement="left">
                            <AvatarGroup {...anchorProps} max={4} sx={{ cursor: 'pointer', justifyContent: "flex-end", '& .MuiAvatar-root': { width: 24, height: 24, fontSize: 15 }, }}>
                                {usersToBeDisplay.map(user => (
                                    <Avatar key={user.uid} {...stringAvatar(user.displayName ?? 'Anonymous')} />
                                ))}
                            </AvatarGroup>
                        </Tooltip>
                    )}
                    title={title}
                    Content={dialogProps => (
                        <Box {...dialogProps}>
                            <Grid container spacing={2}>
                                {usersToBeDisplay.map((usr, index) => (
                                    <Grid item md={6} xs={12} key={index}>
                                        <UserCard
                                            name={usr.name}
                                            email={usr.email}
                                            role={"User"} // FixMe
                                            status={true} // FixMe
                                            statusText={"Enabled"} // FixMe
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>
                    )}
                />
            }
        </>
    )
};

const ActionButtons = ({ department, onEdit, onDelete }) => (
    <>
        <CustomDialog
            size="md"
            AnchorComponent={anchorProps => (
                <Tooltip title="Edit">
                    <IconButton {...anchorProps} size="small">
                        <EditOutlined fontSize="small" />
                    </IconButton>
                </Tooltip>
            )}
            title="Edit Department"
            Content={dialogProps => (
                <AddOrEditDepartmentForm initialValue={department} onSubmit={(formData) => onEdit(department._id, formData)} {...dialogProps} />
            )}
        />
        <Tooltip title="Delete" onClick={() => onDelete(department._id)}>
            <IconButton size="small">
                <DeleteOutlined fontSize="small" />
            </IconButton>
        </Tooltip>
    </>
);

export default ParentComponent;

import React, { useEffect, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import UploadButton from "components/common/UploadButton";
import { useAuth } from "hooks/useAuth";
import { customAxios } from "hooks/useAxios";
import Helpers from "utils/commonHelpers";
import MySwal from "utils/Swal";

const General = () => {
  const { user, reloadUser } = useAuth();
  const [orgLogo, setOrgLogo] = useState();

  const fetchOrgLogo = async () => {
    if (user.orgLogoS3Key) {
      const response = await customAxios.get(
        `s3/file/?s3Key=${user?.orgLogoS3Key}`,
        {
          responseType: "blob",
        },
      );
      if (response) {
        const logo = await Helpers.blobToDataUrl(response.data);
        setOrgLogo(logo);
      }
    }
  };

  useEffect(() => {
    fetchOrgLogo();
  }, []);

  const updateOrgLogo = async (logo) => {
    try {
      const formData = new FormData();
      if (logo) {
        formData.append("file", logo, "orgLogoFile");
      } else {
        formData.append("isOrgLogoFileRemoved", true);
      }
      const response = await customAxios.post(
        "profile/organisation/logo",
        formData,
      );
      response && (await reloadUser());
    } catch (error) {
      console.log(error);
    }
  };

  const onLogoChangeHandler = (files) => {
    if (files && !files?.length > 0) {
      MySwal.fire({
        text: "File format not supported!",
        icon: "error",
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        timer: 2000,
      });
      return;
    }
    const blobUrl = URL.createObjectURL(files[0]);
    setOrgLogo(blobUrl);
    updateOrgLogo(files[0]);
  };

  const deleteLogoHandler = () => {
    setOrgLogo();
    updateOrgLogo();
  };

  return (
    <Stack spacing={3} py={3}>
      <Box>
        <Typography variant="h6">Your Organisation Logo</Typography>
        <Typography variant="body2">
          Recommended size: Square, at least 400 pixels per side. File type:
          JPG, JPEG,or PNG
        </Typography>
        <Box mt={2}>
          <UploadButton
            text="Upload Logo"
            value={orgLogo}
            onChange={onLogoChangeHandler}
            onDelete={deleteLogoHandler}
          />
        </Box>
      </Box>
    </Stack>
  );
};

export default General;

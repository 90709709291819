import React, { useState } from "react";
import { Box, Popover, Tooltip } from "@mui/material";
import { ChromePicker } from "react-color";

const ColorPicker = (props) => {
  const { value, tooltipText, onChange } = props;
  const [pickerEl, setPickerEl] = useState(false);
  return (
    <Box>
      <Tooltip title={tooltipText}>
        <Box
          bgcolor={value}
          onClick={(e) => setPickerEl(e.currentTarget)}
          width={32}
          height={32}
          sx={{
            borderRadius: "5px",
            border: "1px solid",
            borderColor: "primary.border",
            cursor: "pointer",
          }}
        />
      </Tooltip>
      <Popover
        open={Boolean(pickerEl)}
        anchorEl={pickerEl}
        onClose={() => setPickerEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <ChromePicker color={value} onChange={(color) => onChange(color.hex)} />
      </Popover>
    </Box>
  );
};

export default ColorPicker;

import React from "react";
import { Box } from "@mui/material";
import TabLayout from "layouts/TabLayout";
import CredentialsTable from "components/CredentialsTable";
import RecipientsTable from "components/RecipientsTable";
import { useSearchParams } from "react-router-dom";
import Analytics from "./Analytics";
import DraftsTable from "components/DraftsTable";

export default function DocBox() {
  const [searchParams, setSearchParams] = useSearchParams();
  const filter = searchParams.get("filter");
  const searchText = searchParams.get("searchText");
  const [isSeeAll, setIsSeeAll] = React.useState("false");

  React.useEffect(() => {
    if (!filter && !searchText) {
      searchParams.set("filter", "SENT");
      setSearchParams(searchParams);
    }
  }, []);

  return (
    <TabLayout>
      <Box p={2} height="100%">
        {filter === "RECIPIENTS" ? (
          <RecipientsTable isSeeAll={isSeeAll} setIsSeeAll={setIsSeeAll} />
        ) : filter === "ANALYTICS" ? (
          <Analytics isSeeAll={isSeeAll} setIsSeeAll={setIsSeeAll} />
        ) : filter === "DRAFT" ? (
          <DraftsTable />
        ) : filter === "SCHEDULED" ? (
          <DraftsTable isScheduled />
        ) : (
          <CredentialsTable
            {...{ filter }}
            {...{ searchText }}
            isSeeAll={isSeeAll}
            setIsSeeAll={setIsSeeAll}
          />
        )}
      </Box>
    </TabLayout>
  );
}

import React from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import TextSnippetIcon from "@mui/icons-material/TextSnippetOutlined";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import QrCode2OutlinedIcon from "@mui/icons-material/QrCode2Outlined";
import UploadIcon from "@mui/icons-material/Upload";
import FormatShapesIcon from "@mui/icons-material/FormatShapes";
import constants from "constants/english/builder";
import CustomTabs from "components/common/customTabs";
import TemplateLibrary from "./TemplateLibrary";
import { useLocation, useSearchParams } from "react-router-dom";

const ToolTabs = (props) => {
  const {
    width,
    imageInputChangeHandler,
    insertElement,
    templateType,
    fetchTemplateHtml,
  } = props;
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const filter = searchParams.get("filter");

  const BUILDER_TOOL_TABS = [
    ...(location?.state?.backgroundImg
      ? []
      : [
        {
          icon: TextSnippetIcon,
          label: "Library",
          id: "PUB_TMPLT",
        },
      ]),
    {
      icon: LocalOfferOutlinedIcon,
      label: "Variables",
      id: "VARS",
    },
    {
      icon: QrCode2OutlinedIcon,
      label: "QR Code",
      id: "QR",
    },
    {
      icon: TextFieldsIcon,
      label: "Text",
      id: "TEXT",
    },
    {
      icon: FormatShapesIcon,
      label: "Elements",
      id: "ELEMENTS",
    },
  ];

  const VARIABLES = [
    "Recipient Name",
    "Reason",
    "Issue Date",
    "Expiry Date",
    "Reference Number",
    "Other",
  ];

  const imageVariables = [
    {
      label: "Logo",
      image: "https://cdn-stage.itscredible.com/global-assets/placeholder-logo.png"
    },
    {
      label: "Signature",
      image: "https://cdn-stage.itscredible.com/global-assets/placeholder-sign.png"
    }
  ]

  const tabContent = {
    ...(location?.state?.backgroundImg
      ? {}
      : {
        PUB_TMPLT: (
          <TemplateLibrary
            templateType={templateType}
            fetchTemplateHtml={fetchTemplateHtml}
          />
        ),
      }),
    TEXT: (
      <Stack direction="column" spacing={1}>
        <Typography>TEXT</Typography>
        <Button
          variant="contained"
          startIcon={<TextFieldsIcon />}
          fullWidth
          disableElevation
          onClick={() => insertElement("ADD TEXT", true)}
        >
          Add Text
        </Button>
      </Stack>
    ),
    ELEMENTS: (
      <Stack direction="column" spacing={1}>
        <Typography>ELEMENTS</Typography>
        <Button
          variant="contained"
          startIcon={<UploadIcon />}
          fullWidth
          disableElevation
          onClick={() => document.getElementById("imageInput").click()}
        >
          Upload Image
        </Button>
        <input
          type="file"
          id="imageInput"
          accept="image/*"
          style={{ display: "none" }}
          onChange={imageInputChangeHandler}
        />
      </Stack>
    ),
    VARS: (
      <Stack direction="column" spacing={3}>
        <Stack direction="column" spacing={1}>
          <Typography>TEXT VARIABLES</Typography>
          {VARIABLES.map((variable) => (
            <Button
              key={variable}
              variant="dark"
              fullWidth
              disableElevation
              onClick={() => insertElement(variable)}
            >
              {variable}
            </Button>
          ))}
        </Stack>
        <Stack direction="column" spacing={1}>
          <Typography>IMAGE VARIABLES</Typography>
          {imageVariables.map((variable) => (
            <Button
              key={variable.label}
              variant="dark"
              fullWidth
              disableElevation
              onClick={() => insertElement(variable.label, false, variable.image, 240, 120, 'image-variable')}
            >
              {variable.label}
            </Button>
          ))}
        </Stack>
      </Stack>
    ),
    QR: (
      <Stack direction="column" spacing={2}>
        <Typography>QR CODE</Typography>
        <Button
          variant="contained"
          startIcon={<QrCode2OutlinedIcon />}
          fullWidth
          disableElevation
          onClick={() =>
            insertElement("CREDENTIAL_QR", false, constants.QR_ICON, 60, 60)
          }
        >
          Add QR
        </Button>
        <Typography variant="body2">
          When displayed on the recipient&apos;s certificate this will point to
          the live version of their credential.
        </Typography>
        <Typography variant="body2">
          Printed copies and PDFs will link back to the recipient&apos;s live
          credential to verify it&apos;s authenticity.
        </Typography>
      </Stack>
    ),
  };

  return (
    <Box display="flex" width={width}>
      <CustomTabs variant="dark" filters={BUILDER_TOOL_TABS}>
        <Box p={2}>{tabContent[filter || "PUB_TMPLT"]}</Box>
      </CustomTabs>
    </Box>
  );
};

export default ToolTabs;

import React, { useState } from "react";
import IssuanceLayout from "layouts/IssuanceLayout";
import {
  Box,
  Stack,
  Typography,
  Dialog,
  IconButton,
  Hidden,
} from "@mui/material";
import { useAuth } from "hooks/useAuth";
import TemplateCarousel from "components/issuance/TemplateCarousel";
import NewTemplate from "components/issuance/NewTemplate";
import useMediaQuery from "@mui/material/useMediaQuery";
import MySwal from "utils/Swal";
import NewBlankCarouselSlide from "components/issuance/NewBlankCarouselSlide";
import SearchIcon from "@mui/icons-material/Search";
import SearchTemplateBar from "components/issuance/SearchTemplateBar";
import CustomPopover from "components/common/customPopover";

export default function Design() {
  const isMobile = useMediaQuery("(max-width:600px)");
  const { user } = useAuth();
  const [templateDialog, setTemplateDialog] = useState();

  const mobileWarning = () => {
    MySwal.fire({
      title: "Feature not available on this device.",
      text: "Our template design feature needs a bigger screen, please switch to desktop to use it.",
      icon: "info",
    });
  };

  return (
    <IssuanceLayout>
      <Box p={4}>
        <Stack spacing={3}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack spacing={0}>
              <Typography variant="h4">Select A Template</Typography>
              <Typography variant="subtitle1">
                Select a template for the issuance of your credentials.
              </Typography>
            </Stack>

            <Hidden mdUp>
              <CustomPopover
                AnchorComponent={(syntax) => (
                  <IconButton {...syntax}>
                    <SearchIcon />
                  </IconButton>
                )}
                PopoverComponent={(syntax) => (
                  <Box m={2} {...syntax}>
                    <SearchTemplateBar autoFocus />
                  </Box>
                )}
              />
            </Hidden>
            <Hidden mdDown>
              <SearchTemplateBar />
            </Hidden>
          </Stack>

          <Stack spacing={4}>
            <TemplateCarousel
              isPrivate
              title="Your Templates"
              fetchEndpoint={`templates?companyId=${user.company.id}`}
              firstSlide={
                <NewBlankCarouselSlide
                  text="Create New Certificate Template"
                  onClick={() => {
                    if (isMobile) {
                      mobileWarning();
                    } else {
                      setTemplateDialog("certificate");
                    }
                  }}
                />
              }
            />
            {/* <TemplateCarousel
              isPrivate
              title="Your Badge Templates"
              fetchEndpoint={`templates?companyId=${user.company.id}`}
              filter={(template) => {
                if (template?.dimensions) {
                  const aspectRatio =
                    template.dimensions.width / template.dimensions.height;
                  return aspectRatio < 1.1 && aspectRatio > 0.9;
                }
                return false;
              }}
              firstSlide={
                <NewBlankCarouselSlide
                  text="Create New Badge Template"
                  onClick={() => {
                    if (isMobile) {
                      mobileWarning();
                    } else {
                      setTemplateDialog("badge");
                    }
                  }}
                />
              }
            /> */}
            <TemplateCarousel
              title="Public Certificate Templates"
              fetchEndpoint="templates"
              type="Certificate"
            />
            <TemplateCarousel
              title="Public Badge Templates"
              fetchEndpoint="templates"
              type="Badge"
            />
          </Stack>
        </Stack>
        <Dialog
          maxWidth="lg"
          open={Boolean(templateDialog)}
          onClose={() => setTemplateDialog()}
        >
          <NewTemplate templateType={templateDialog} />
        </Dialog>
      </Box>
    </IssuanceLayout>
  );
}

import React from "react";
import DatePicker from "components/common/DatePicker";
import { Stack, Button, Typography } from "@mui/material";
import Editor from "components/common/Editor";
import { customAxios } from "hooks/useAxios";
import MySwal from "utils/Swal";
import SkillsInput from "components/common/SkillsInput";

export default function EditDocumentForm(props) {
  const { docIds, initialValues, callback = () => {} } = props;
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [formData, setFormData] = React.useState({
    docIds: docIds,
    expiryDate: initialValues.expiryDate,
    text: initialValues.text,
    skills: initialValues.skills,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const response = await customAxios.post("document/info", formData);
    if (response) {
      props.handleClose();
      callback();
      MySwal.fire({
        title: "Saved!",
        text: "Document details has been saved successfully!",
        icon: "success",
        // showConfirmButton: false,
        // timer: 2000
      });
    }

    setIsSubmitting(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={4} mt={2}>
        {docIds.length > 1 && (
          <Typography variant="body2">
            You have selected: {docIds.length} documents.
          </Typography>
        )}
        <DatePicker
          label={"Expiry Date"}
          minDate="TODAY"
          value={formData.expiryDate}
          onChange={(newValue) =>
            setFormData((prevState) => ({
              ...prevState,
              expiryDate: newValue === "Invalid Date" ? null : newValue,
            }))
          }
        />
        <Editor
          label="Document Description"
          helperText="Additional information you want to provide to about the credential holder"
          value={formData.text}
          onChange={(newValue) =>
            setFormData((prevState) => ({
              ...prevState,
              text: newValue,
            }))
          }
        />
        <SkillsInput
          defaultValue={formData.skills}
          onChange={(e, newValue) =>
            setFormData((prevState) => ({
              ...prevState,
              skills: newValue,
            }))
          }
        />
        <Stack direction="row" spacing={2} justifyContent="flex-end">
          <Button onClick={props.handleClose}>Cancel</Button>
          <Button variant="contained" type="submit" disabled={isSubmitting}>
            {isSubmitting ? "Saving..." : "Save"}
          </Button>
        </Stack>
      </Stack>
    </form>
  );
}

import React, { useEffect, useRef, useState } from "react";
import IssuanceLayout from "../../layouts/IssuanceLayout";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import Dropzone from "components/common/Dropzone";
import FileUploadOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import CancelIcon from "@mui/icons-material/Cancel";
import { Document, Page } from "react-pdf";
import NewBlankCarouselSlide from "components/issuance/NewBlankCarouselSlide";
import { customAxios } from "hooks/useAxios";
import Helpers from "utils/commonHelpers";
import ImageIcon from "@mui/icons-material/Image";
import { useLocation, useNavigate } from "react-router-dom";
import { useIssuanceDataProvider } from "hooks/useIssuanceDataProvider";

function EmptyUploadZone() {
  return (
    <Paper
      variant="outlined"
      sx={{
        height: "100%",
        borderStyle: "dashed",
        borderWidth: "2px",
        cursor: "pointer",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        p: 4,
      }}
    >
      <Stack spacing={2} alignItems="center">
        <FileUploadOutlinedIcon
          sx={{ fontSize: "100px", color: "border.main" }}
        />
        <Stack alignItems="center">
          <Typography variant="body1">
            Drag and drop files here to upload or
          </Typography>
          <Typography variant="body1" color="primary.main">
            browse from your computer
          </Typography>
        </Stack>
      </Stack>
    </Paper>
  );
}

function Thumbnail(props) {
  const { src, path, type, status, deleteFn, fileS3Key } = props;
  const [srcUrl, setSrcUrl] = useState(src);

  const fetchFile = async () => {
    const res = await customAxios.get(`s3/file/?s3Key=${fileS3Key}`, {
      responseType: "blob",
    });
    const preview = await Helpers.blobToDataUrl(res.data);
    setSrcUrl(preview);
  };

  useEffect(() => {
    if (!src) {
      fetchFile();
    }
  }, []);

  return (
    <>
      <Paper
        variant="outlined"
        sx={{
          position: "relative",
          aspectRatio: 1.2,
          width: "100%",
          p: 1,
        }}
      >
        {(!status || status === "INPROGRESS") && (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              bgcolor: "border.main",
              opacity: 0.7,
              position: "absolute",
              top: 0,
              left: 0,
              zIndex: 10,
            }}
          >
            <CircularProgress />
          </Box>
        )}

        <Box
          width="100%"
          height="100%"
          overflow="hidden"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {type.toLowerCase().includes("image") ? (
            <img
              src={srcUrl}
              alt={path}
              style={{ maxHeight: "100%", maxWidth: "100%" }}
            />
          ) : srcUrl ? (
            <Document file={srcUrl} loading={<CircularProgress />}>
              <Page pageNumber={1} width={100} />
            </Document>
          ) : (
            <ImageIcon
              sx={{ width: "100%", height: "100%", color: "border.main" }}
            />
          )}
        </Box>

        {status && status === "UPLOAD_COMPLETED" && (
          <IconButton
            size="small"
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              transform: "translate(45%, -45%)",
            }}
            onClick={deleteFn}
          >
            <CancelIcon />
          </IconButton>
        )}
      </Paper>
      <Typography
        variant="body2"
        sx={{
          display: "-webkit-box",
          "-webkit-line-clamp": "2",
          "-webkit-box-orient": "vertical",
          overflow: "hidden",
          mt: 0.5,
        }}
        align="center"
      >
        {path}
      </Typography>
    </>
  );
}

export default function UploadExistingCredentials() {
  const { issuanceData, setIssuanceData } = useIssuanceDataProvider();
  const navigate = useNavigate();
  const location = useLocation();
  const [files, setFiles] = useState(issuanceData?.uploadedDoc?.creds || []);
  const [errorMsg, setErrorMsg] = useState();
  const dropzoneRef = useRef();

  useEffect(() => {
    const creds = issuanceData?.uploadedDoc?.creds || [];
    if (creds?.length) {
      setFiles(creds);
    }
  }, [issuanceData?.uploadedDoc?.ids]);

  const uploadCredentialFile = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("isFromFE", true);
      const res = await customAxios.post("docs/upload", formData);
      return res?.data?.response;
    } catch (error) {
      setErrorMsg(
        file?.path +
          ": " +
          (error?.response?.data?.message ||
            error?.message ||
            "Cannot upload file!"),
      );
      setTimeout(() => {
        setErrorMsg();
      }, 5000);
      return null;
    }
  };

  const uploadHandler = async (acceptedFiles) => {
    const MAX_SIZE = 10485760; // 10 MB in bytes
    const validFiles = [];
    acceptedFiles.map((file) => {
      if (file.size > MAX_SIZE) {
        setErrorMsg(file?.path + ": File size cannot be more than 10mb.");
        setTimeout(() => {
          setErrorMsg();
        }, 5000);
      } else {
        Object.assign(file, { preview: URL.createObjectURL(file) });
        Object.assign(file, { status: "INPROGRESS" });
        validFiles.push(file);
      }
    });
    setFiles((prevState) => [...prevState, ...validFiles]);

    for (let i = 0; i < validFiles.length; i++) {
      const file = validFiles[i];
      const uploadRes = await uploadCredentialFile(file);
      if (uploadRes) {
        setFiles((prevState) => {
          const fileObj = prevState.find((obj) => obj.path === file.path);
          if (fileObj) {
            fileObj.status = "UPLOAD_COMPLETED";
            fileObj._id = uploadRes?.docEntryid;
          }
          return [...prevState];
        });
      } else {
        setFiles((prevState) => {
          const fileObj = prevState.find((obj) => obj.path === file.path);
          if (fileObj) {
            fileObj.status = "UPLOAD_FAILED";
          }
          return [...prevState];
        });
      }
    }
  };

  const removeFiles = async (fileIds) => {
    try {
      setFiles((prevState) =>
        prevState.filter((item) => !fileIds.includes(item._id)),
      );
      await customAxios.delete("signedDocs/uploaded", {
        data: { documentIds: fileIds },
      });
    } catch (error) {
      setErrorMsg(
        error?.response?.data?.message ||
          error?.message ||
          "Cannot delete file!",
      );
      setTimeout(() => {
        setErrorMsg();
      }, 5000);
    }
  };

  const handleSave = (e) => {
    e.target.innerText = "Saving...";
    const callback = () => {
      e.target.innerText = "Saved!";
      setTimeout(() => {
        e.target.innerText = "Save";
      }, 5000);
    };
    const ids = files.flatMap((file) => file?._id || []);
    setIssuanceData(
      { uploadedDoc: { ids }, credentialDetails: { title: files?.[0]?.path } },
      callback,
    );
  };

  const handleNext = () => {
    const ids = files.flatMap((file) => file?._id || []);
    setIssuanceData(
      {
        uploadedDoc: { ids, isCompleted: true },
        credentialDetails: { title: files?.[0]?.path },
      },
      () =>
        navigate(
          `${process.env.REACT_APP_BASE_ROUTE}/issuance/existing/settings/${location.search}`,
        ),
    );
  };

  return (
    <IssuanceLayout>
      <Stack
        spacing={3}
        p={4}
        flexGrow={1}
        sx={{
          height: "calc(100dvh - 57px)",
          "& > section": { flexGrow: 1 },
          "& > section > * ": { height: "100%" },
        }}
      >
        <Stack spacing={0}>
          <Typography variant="h4">Upload Your Credentials</Typography>
          <Typography variant="subtitle1">
            Upload your existing credentials. {"("}Any PDF, PNG or JPG, up to
            10MB{")"}
          </Typography>
        </Stack>
        <Dropzone
          dropzoneRef={dropzoneRef}
          accept={{
            "image/png": [],
            "image/jpg": [],
            "image/jpeg": [],
            "application/pdf": [],
          }}
          noClick={Boolean(files?.length)}
          onDrop={uploadHandler}
        >
          {files.length ? (
            <>
              <Grid container spacing={4} alignItems="stretch" mb={4}>
                <Grid item xs={6} sm={4} md={3} lg={2}>
                  <NewBlankCarouselSlide
                    text="Upload New Credential"
                    onClick={() => dropzoneRef.current.open()}
                    sx={{ aspectRatio: 1.2 }}
                  />
                </Grid>
                {files.map((file) => (
                  <Grid key={file?.path} item xs={6} sm={4} md={3} lg={2}>
                    <Thumbnail
                      src={file?.preview}
                      fileS3Key={file?.fileS3Key}
                      path={file?.path}
                      type={file?.type}
                      status={file?.status}
                      deleteFn={() => removeFiles([file?._id])}
                    />
                  </Grid>
                ))}
              </Grid>

              <Stack direction="row" mb={2} spacing={2} justifyContent="center">
                <Button
                  variant="outlined"
                  onClick={handleSave}
                  sx={{ minWidth: 100 }}
                >
                  Save
                </Button>
                <Button
                  variant="contained"
                  disableElevation
                  sx={{ minWidth: 100 }}
                  disabled={
                    files.filter(
                      (file) => !file.status || file.status === "INPROGRESS",
                    ).length
                  }
                  onClick={handleNext}
                >
                  Next
                </Button>
              </Stack>
            </>
          ) : (
            <EmptyUploadZone />
          )}
        </Dropzone>

        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={Boolean(errorMsg)}
          message={errorMsg}
        />
      </Stack>
    </IssuanceLayout>
  );
}

import React from "react";
import { TextField, Typography } from "@mui/material";

export default function EditableCell(props) {
  const { getValue, row, onSave, validationFunc, placeholder } = props;
  const initialValue = getValue();
  const [value, setValue] = React.useState(initialValue);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isEditing, setIsEditing] = React.useState(false);

  const handleSave = async () => {
    setIsLoading(true);
    await onSave({ docId: row.original._id, value });
    setIsLoading(false);
    setIsEditing(false);
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  React.useEffect(() => {
    if (isEditing) {
      inputRef?.current?.focus();
    }
  }, [isEditing]);

  const inputRef = React.useRef();

  return isLoading ? (
    <Typography variant="body1">Saving...</Typography>
  ) : isEditing ? (
    <TextField
      inputRef={inputRef}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onBlur={handleSave}
      onKeyDown={(e) => e.key === "Enter" && handleSave()}
      size="small"
      sx={{ my: -1.1, mx: -1.8, width: "228px" }}
      fullWidth={true}
    />
  ) : (
    <div onClick={handleEdit}>
      {value ? (
        <Typography
          variant="body1"
          noWrap
          sx={{ width: "200px" }}
          color={!validationFunc(value || "") && "error"}
        >
          {value}
        </Typography>
      ) : (
        <Typography
          variant="body2"
          color="error"
          noWrap
          sx={{ width: "200px", cursor: "pointer" }}
        >
          {placeholder}
        </Typography>
      )}
    </div>
  );
}

import { useAxios } from "hooks/useAxios";
import {
  Box,
  Grid,
  Typography,
  Popover,
  MenuList,
  MenuItem,
  TextField,
  Paper,
  Skeleton,
} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutlineOutlined";
import React from "react";
import { useAuth } from "hooks/useAuth";

export default function Analytics(props) {
  const { isSeeAll, setIsSeeAll } = props;
  const { user } = useAuth();
  const [data, isLoading] = useAxios("user/analytics");
  const [anchorEl, setAnchorEl] = React.useState();
  const classes = {};

  const analyticsData = isSeeAll ? data?.companyAnalytics : data?.userAnalytics;

  const analytics = [
    {
      Header: "Issuance Report:",
      Content: [
        {
          Header: "Issued all time",
          Data:
            analyticsData?.overallIssuedCount +
            analyticsData?.overallDeletedCount,
          Info: {
            Icon: HelpOutlineIcon,
            Content: [
              { Header: "Issued", Data: analyticsData?.overallIssuedCount },
              { Header: "Deleted", Data: analyticsData?.overallDeletedCount },
            ],
          },
        },
        {
          Header: "Issued this month",
          Data:
            analyticsData?.currentMonthIssuedCount +
            analyticsData?.currentMonthDeletedCount,
          Info: {
            Icon: HelpOutlineIcon,
            Content: [
              {
                Header: "Issued",
                Data: analyticsData?.currentMonthIssuedCount,
              },
              {
                Header: "Deleted",
                Data: analyticsData?.currentMonthDeletedCount,
              },
            ],
          },
        },
        {
          Header: "Issued last month",
          Data:
            analyticsData?.lastMonthIssuedCount +
            analyticsData?.lastMonthDeletedCount,
          Info: {
            Icon: HelpOutlineIcon,
            Content: [
              { Header: "Issued", Data: analyticsData?.lastMonthIssuedCount },
              { Header: "Deleted", Data: analyticsData?.lastMonthDeletedCount },
            ],
          },
        },
      ],
    },
    {
      Header: "Open Report:",
      Content: [
        { Header: "Opened all time", Data: analyticsData?.overallOpenedCount },
        {
          Header: "Opened this month",
          Data: analyticsData?.currentMonthOpenedCount,
        },
        {
          Header: "Opened last month",
          Data: analyticsData?.lastMonthOpenedCount,
        },
        {
          Header: "Not opened all time",
          Data: analyticsData?.overallNotOpenedCount,
        },
      ],
    },
    {
      Header: "Recipient Engagement Report:",
      Content: [
        {
          Header: "Shared all time",
          Data: analyticsData?.overallShared,
          Info: {
            Icon: HelpOutlineIcon,
            Content: [
              { Header: "LinkedIn", Data: analyticsData?.overallLinkedinShare },
              { Header: "Facebook", Data: analyticsData?.overallFacebookShare },
              { Header: "Whatsapp", Data: analyticsData?.overallWhatsappShare },
              { Header: "Twitter", Data: analyticsData?.overallTwitterShare },
            ],
          },
        },
        {
          Header: "Post clicks all time",
          Data: analyticsData?.overallPostClicked,
          Info: {
            Icon: HelpOutlineIcon,
            Content: [
              {
                Header: "LinkedIn",
                Data: analyticsData?.overallLinkedinPostClick,
              },
              {
                Header: "Facebook",
                Data: analyticsData?.overallFacebookPostClick,
              },
              {
                Header: "Whatsapp",
                Data: analyticsData?.overallWhatsappPostClick,
              },
              {
                Header: "Twitter",
                Data: analyticsData?.overallTwitterPostClick,
              },
            ],
          },
        },
      ],
    },
    {
      Header: "Verification Report:",
      Content: [
        { Header: "QR all time", Data: analyticsData?.qrVerification },
        { Header: "Widget all time", Data: analyticsData?.widgetVerification },
      ],
    },
    {
      Header: "Brand Impressions Report:",
      Content: [
        { Header: "Traffic sent to website", Data: analyticsData?.overallCtr },
        {
          Header: "Brand Impressions per document",
          Data: analyticsData?.overallAverageBrandImpressionCount,
        },
        {
          Header: "Total Brand Impressions",
          Data: analyticsData?.overallBrandImpressionCount,
        },
      ],
    },
  ];

  return (
    <Box p={2}>
      <Grid container spacing={4} direction="column">
        {user?.role === "VM_COMPANY_ADMIN" && (
          <Grid item xs={12}>
            {isLoading ? (
              <Skeleton variant="rounded" width={130} height={32} />
            ) : (
              <TextField
                select
                defaultValue={isSeeAll}
                size="small"
                className="extra-small"
                onChange={(e) => setIsSeeAll(e.target.value)}
                sx={{ marginLeft: "auto !important", minWidth: "130px" }}
              >
                <MenuItem value={false}>My Records</MenuItem>
                <MenuItem value={true}>All Records</MenuItem>
              </TextField>
            )}
          </Grid>
        )}
        {analytics.map((field) => (
          <Grid
            key={field.Header}
            item
            container
            direction="row"
            xs={12}
            spacing={2}
          >
            <Grid item xs={12}>
              <Typography variant="h5">{field.Header}</Typography>
            </Grid>
            {field.Content.map((value) => (
              <Grid item lg={3} md={4} sm={6} xs={12} key={value.Header}>
                {isLoading ? (
                  <Skeleton variant="rounded" height={86} />
                ) : (
                  <Paper variant="outlined" sx={{ p: 2 }}>
                    <Typography variant="h3" className={classes.subHeading}>
                      {value.Data || 0}{" "}
                      {value?.Info ? (
                        <>
                          <value.Info.Icon
                            id={value?.Header?.replaceAll(" ", "_")}
                            color="primary"
                            style={{
                              cursor: "pointer",
                              marginBottom: "-2.5px",
                            }}
                            fontSize="small"
                            onClick={(e) => setAnchorEl(e.currentTarget)}
                          />
                          <Popover
                            open={
                              anchorEl?.id ===
                              value?.Header?.replaceAll(" ", "_")
                            }
                            onClose={() => setAnchorEl(null)}
                            anchorEl={anchorEl}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "center",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "center",
                            }}
                          >
                            <MenuList>
                              {value?.Info?.Content.map((subValue) => (
                                <MenuItem
                                  className={classes.menuItem}
                                  key={subValue?.Header}
                                >
                                  <span>{subValue?.Header}</span>
                                  :&nbsp;
                                  <span>{subValue?.Data}</span>
                                </MenuItem>
                              ))}
                            </MenuList>
                          </Popover>
                        </>
                      ) : null}
                    </Typography>
                    <Typography className={classes.footer} gutterBottom>
                      {value.Header}
                    </Typography>
                  </Paper>
                )}
              </Grid>
            ))}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

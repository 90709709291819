import React from "react";
import IssuanceLayout from "layouts/IssuanceLayout";
import {
  Button,
  Stack,
  MenuList,
  MenuItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import ParallelSection from "components/issuance/parallelSections";
import Spreadsheet from "components/Spreadsheet";
import MySwal from "utils/Swal";
import validator from "validator";
import useMediaQuery from "@mui/material/useMediaQuery";
import CustomPopover from "components/common/customPopover";
import Dropzone from "react-dropzone";
import CustomDialog from "components/common/CustomDialog";
import RecipientsTable from "components/RecipientsTable";
import { useAuth } from "hooks/useAuth";
// import _ from "lodash";
import { useIssuanceDataProvider } from "hooks/useIssuanceDataProvider";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";

const initialRows = [{}, {}, {}, {}, {}, {}, {}, {}, {}];

export default function Recipients() {
  const { issuanceData, setIssuanceData } = useIssuanceDataProvider();
  const { issuanceType } = useParams();
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery("(max-width:600px)");
  const [csvFile, setCsvFile] = React.useState();
  const [shouldShowValidation, setShouldShowValidation] = React.useState(false);
  const [formData, setFormData] = React.useState({
    templateId: issuanceData?.templateId,
    csvData: issuanceData?.recipients?.csvData.length
      ? issuanceData?.recipients?.csvData
      : initialRows,
    mappedFileNameColumn: "Recipient Name",
    metaData: [],
    imageData: {},
    tags: issuanceData?.tags,
    skills: issuanceData?.skills,
    isFromFE: true,
  });
  const [drawer, setDrawer] = React.useState(false);

  const notificationPlatform =
    issuanceData?.credentialDetails?.notificationPlatform;

  const columns = [
    ...new Set([
      ...(issuanceType === "existing" ? ["File Name"] : []),
      "Recipient Name",
      ...(notificationPlatform?.email ? ["Email Address"] : []),
      ...(notificationPlatform?.whatsapp ? ["WhatsApp Number"] : []),
      ...(issuanceData?.template?.placeholders?.textPlaceholders?.filter(
        (p) => p !== "Reference Number",
      ) ?? []),
    ]),
  ];

  const validateData = (formData) => {
    const csvData = [];
    formData?.csvData?.forEach((row) => {
      let isEmptyRow = true;
      for (let key in row) {
        if (row[key]) {
          isEmptyRow = false;
          break;
        }
      }
      if (!isEmptyRow) {
        csvData.push({ ...row });
      }
    });

    if (csvData.length === 0) {
      return {
        title: "Please fill data",
        text: "No data has been inserted into the sheet",
      };
    }

    const recipientsNamesMissing = !csvData.every(
      (obj) => obj?.["Recipient Name"],
    );
    const emailAddressesMissing =
      notificationPlatform?.email &&
      !csvData.every((obj) => validator.isEmail(obj["Email Address"] ?? ""));

    const whatsappNumMissing =
      notificationPlatform?.whatsapp &&
      !csvData.every((obj) =>
        validator.isMobilePhone(obj["WhatsApp Number"] ?? ""),
      );

    if (recipientsNamesMissing || emailAddressesMissing || whatsappNumMissing) {
      return {
        title: "Incomplete Information",
        text: recipientsNamesMissing
          ? "One or more recipients are missing or incorrect."
          : emailAddressesMissing
          ? "One or more email addresses are missing or incorrect."
          : "One or more whatsapp numbers are missing or incorrect.",
      };
    }

    return null;
  };

  React.useEffect(() => {
    setShouldShowValidation(false);
  }, [formData]);

  const handleNext = async () => {
    setShouldShowValidation(true);
    const reqData = { ...formData };
    reqData.csvData = reqData.csvData.filter(
      (obj) => Object.keys(obj).length > 0,
    );

    if (user.plan !== "FREE" && reqData.csvData.length > user.credits) {
      MySwal.fire({
        title: "Not enough credits",
        icon: "error",
      });
      return;
    }

    const validationError = validateData(reqData);

    if (validationError) {
      MySwal.fire({
        title: validationError.title,
        text: validationError.text,
        icon: "error",
      });
    } else {
      setIssuanceData(
        {
          recipients: {
            csvData: formData.csvData,
            isCompleted: true,
          },
        },
        () =>
          navigate(
            `${process.env.REACT_APP_BASE_ROUTE}/issuance/${issuanceType}/send/${location.search}`,
          ),
      );
    }
  };

  const downloadSampleCsv = () => {
    let csvContent = "";
    let titles = columns.filter(
      (title) => title.toLowerCase() !== "eligibility",
    );
    csvContent += titles.map((title) => `"${title}"`).join(",") + "\n";
    const emptyData = titles.map(() => "");
    csvContent += emptyData.join(",") + "\n";
    const bom = "\uFEFF"; // UTF-8 BOM
    const encodedCSV = bom + encodeURIComponent(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", `data:text/csv;charset=utf-8,${encodedCSV}`);
    link.setAttribute("download", "sampleCSV.csv");
    document.body.appendChild(link);
    link.click();
  };

  const cellValidationFormula = ({ rowData, columnId }) => {
    let isRequiredColumn = false;
    if (
      (notificationPlatform?.email && columnId === "Email Address") ||
      (notificationPlatform?.whatsapp && columnId === "WhatsApp Number")
    ) {
      isRequiredColumn = true;
    }
    const hasData = Object.keys(rowData).length > 0;
    const isInvalidValue = !rowData?.[columnId];
    let isInvalid = false;
    if (notificationPlatform?.email && columnId === "Email Address") {
      isInvalid = !validator.isEmail(rowData[columnId] ?? "");
    } else if (
      notificationPlatform?.whatsapp &&
      columnId === "WhatsApp Number"
    ) {
      isInvalid = !validator.isMobilePhone(rowData[columnId] ?? "");
    }
    const isInvalidRecipientName = columnId === "Recipient Name";

    return (
      shouldShowValidation &&
      hasData &&
      (isInvalidValue || (isRequiredColumn && isInvalid)) &&
      (isInvalidRecipientName || isRequiredColumn)
    );
  };

  const handleCSVUpload = (files) => {
    setCsvFile(files[0]);
  };

  // Save on unmount
  const oldPathName = location.pathname;
  React.useEffect(() => {
    return () => {
      if (oldPathName !== window.location.pathname) {
        setIssuanceData({
          recipients: {
            csvData: formData.csvData,
          },
        });
      }
    };
  }, [formData]);

  const handleSave = (e) => {
    e.target.innerText = "Saving...";
    const callback = () => {
      e.target.innerText = "Saved!";
      setTimeout(() => {
        e.target.innerText = "Save";
      }, 5000);
    };
    setIssuanceData(
      {
        recipients: {
          csvData: formData.csvData,
        },
      },
      callback,
    );
  };

  return (
    <IssuanceLayout>
      <ParallelSection
        data={{
          recipients: {
            csvData: formData.csvData,
          },
        }}
        drawer={drawer}
        setDrawer={setDrawer}
      >
        <>
          <Stack spacing={2} data-tut="AddRecipients">
            {issuanceType === "create" && (
              <Stack spacing={2} direction="row">
                <CustomDialog
                  AnchorComponent={({ onClick }) => (
                    <Button onClick={onClick} variant="outlined">
                      Recipients
                    </Button>
                  )}
                  title="Recipient History"
                  Content={(dialogProps) => {
                    const [isSeeAll, setIsSeeAll] = React.useState("false");
                    return (
                      <RecipientsTable
                        {...dialogProps}
                        isSeeAll={isSeeAll}
                        setIsSeeAll={setIsSeeAll}
                        onImport={setFormData}
                        callback={() => dialogProps?.handleClose()}
                        tableStyle={{ height: "calc(100dvh - 200px)" }}
                      />
                    );
                  }}
                />
                <CustomPopover
                  AnchorComponent={(anchorProps) => (
                    <Button
                      {...anchorProps}
                      variant="outlined"
                      endIcon={<KeyboardArrowDownOutlinedIcon />}
                    >
                      Import CSV
                    </Button>
                  )}
                  PopoverComponent={(compProps) => (
                    <MenuList {...compProps}>
                      <Dropzone
                        onDrop={(files) => {
                          handleCSVUpload(files);
                          compProps.handleClose();
                        }}
                        accept={{ "text/plain": [".csv"] }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <MenuItem {...getRootProps()}>
                            <input {...getInputProps()} />
                            <ListItemText disableTypography>
                              <Typography variant="body2">
                                Import CSV
                              </Typography>
                            </ListItemText>
                          </MenuItem>
                        )}
                      </Dropzone>
                      <MenuItem onClick={downloadSampleCsv}>
                        <ListItemText disableTypography>
                          <Typography variant="body2">
                            Download Sample CSV
                          </Typography>
                        </ListItemText>
                      </MenuItem>
                    </MenuList>
                  )}
                />
              </Stack>
            )}
            <Spreadsheet
              csvFile={csvFile}
              data={formData.csvData}
              notificationPlatform={notificationPlatform}
              setData={(csvData) =>
                setFormData((prevState) => ({ ...prevState, csvData }))
              }
              columns={columns}
              cellValidationFormula={cellValidationFormula}
            />

            <Stack direction="row" spacing={2} justifyContent="center">
              {isMobile && (
                <Button
                  variant="outlined"
                  disableElevation
                  sx={{ minWidth: 150 }}
                  onClick={() => setDrawer(true)}
                >
                  Preview
                </Button>
              )}
              <Button
                variant="outlined"
                onClick={handleSave}
                sx={{ minWidth: 100 }}
              >
                Save
              </Button>
              <Button
                variant="contained"
                disableElevation
                sx={{ minWidth: 150 }}
                onClick={handleNext}
                data-tut="AddRecipientsNextBtn"
              >
                Next
              </Button>
            </Stack>
          </Stack>
        </>
      </ParallelSection>
    </IssuanceLayout>
  );
}

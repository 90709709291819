import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Stack,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

export default function CustomDialog(props) {
  const [open, setOpen] = React.useState(false);
  const { AnchorComponent, title, Content, closeOnOutsideClick=true } = props;

  const handleOpen = (e) => {
    e?.stopPropagation();
    setOpen(true);
  };

  const handleClose = (e, reason) => {
    if (closeOnOutsideClick || reason !== "backdropClick") {
      e?.stopPropagation();
      setOpen(false);
    }
  };

  return (
    <>
      <AnchorComponent onClick={handleOpen} />

      <Dialog
        onClose={handleClose}
        open={open}
        fullWidth={true}
        maxWidth={props?.size || "sm"}
      >
        <DialogTitle sx={{ fontSize: "1.1rem" }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            {title}
            <IconButton onClick={handleClose} sx={{ m: -1, mt: -0.8 }}>
              <CloseOutlinedIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Content {...{ handleClose }} />
        </DialogContent>
      </Dialog>
    </>
  );
}

import React from "react";
import Helpers from "utils/commonHelpers";
import { Popover } from "@mui/material";

export default function CustomPopover(props) {
  const { AnchorComponent, PopoverComponent } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    event?.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event?.stopPropagation?.();
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? Helpers.getRandomInt(10) : undefined;

  return (
    <>
      <AnchorComponent
        aria-describedby={id}
        onClick={handleClick}
        style={
          open ? { boxShadow: "inset 0 0 200px 0 rgba(13,152,186,.1)" } : {}
        }
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        onClick={(e) => e?.stopPropagation()}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        disableEnforceFocus={true}
      >
        <PopoverComponent handleClose={handleClose} autoFocusItem={open} />
      </Popover>
    </>
  );
}

import React from "react";
import { Button, Stack } from "@mui/material";
import { customAxios } from "hooks/useAxios";
import MySwal from "utils/Swal";
import PasswordInput from "components/common/PasswordInput";

export default function ResetPasswordForm(props) {
  const { handleClose } = props;
  const [formData, setFormData] = React.useState({
    oldPassword: null,
    newPassword: null,
    confirmPassword: null,
  });
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const isValid = ({ newPassword, confirmPassword }) => {
    return newPassword === confirmPassword;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isValid(formData)) {
      setIsSubmitting(true);
      const response = await customAxios.put(
        "resetpassword",
        {
          oldPassword: formData.oldPassword,
        },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `itscredible ${btoa(formData.newPassword)}`,
          },
        },
      );
      if (response) {
        props.handleClose();
        MySwal.fire({
          title: "Password reset successfully!",
          text: "",
          icon: "success",
          allowEscapeKey: false,
          showConfirmButton: false,
          timer: 4000,
        });
      }
      setIsSubmitting(false);
    } else {
      MySwal.fire({
        title: "Invalid Data!",
        text: "Please check your enteries!",
        icon: "error",
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={2} mt={0.5}>
        <PasswordInput
          required
          label="Current Password"
          variant="outlined"
          fullWidth
          helperText="Enter your existing password."
          onChange={(e) =>
            setFormData((prevState) => ({
              ...prevState,
              oldPassword: e.target.value,
            }))
          }
        />
        <PasswordInput
          required
          label="New Password"
          variant="outlined"
          fullWidth
          error={
            formData.newPassword &&
            formData.newPassword === formData.oldPassword
          }
          helperText={
            formData.newPassword &&
            formData.newPassword === formData.oldPassword
              ? "New password must be different from current password."
              : "Password must be 8 characters minimum, with at least one lowercase letter and one numeric digit."
          }
          onChange={(e) =>
            setFormData((prevState) => ({
              ...prevState,
              newPassword: e.target.value,
            }))
          }
        />
        <PasswordInput
          required
          label="Confirm New Password"
          variant="outlined"
          fullWidth
          error={
            formData.confirmPassword &&
            formData.newPassword !== formData.confirmPassword
          }
          helperText={
            formData.confirmPassword &&
            formData.newPassword !== formData.confirmPassword
              ? "New password does not match."
              : "Enter new password again here."
          }
          onChange={(e) =>
            setFormData((prevState) => ({
              ...prevState,
              confirmPassword: e.target.value,
            }))
          }
        />
        <Stack direction="row" spacing={2} justifyContent="flex-end">
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            variant="contained"
            type="submit"
            disabled={
              isSubmitting ||
              !formData.oldPassword ||
              !formData.newPassword ||
              !formData.confirmPassword ||
              formData.newPassword === formData.oldPassword ||
              formData.newPassword !== formData.confirmPassword
            }
          >
            {isSubmitting ? "Reseting..." : "Reset Password"}
          </Button>
        </Stack>
      </Stack>
    </form>
  );
}

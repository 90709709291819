import React from "react";
import {
  Box,
  Typography,
  Stack,
  Paper,
  Card,
  CardActionArea,
} from "@mui/material";
import Badge from "assets/images/badge.png";
import { useNavigate } from "react-router-dom";
// import { ReactComponent as Logo } from "assets/images/logo.svg";

export default function AuthLayout(props) {
  const navigate = useNavigate();

  return (
    <Box p={2} pt={6} sx={{ minHeight: "100dvh", backgroundColor: "#f4f2f0" }}>
      <Stack spacing={6} alignItems="center">
        {/* <Logo
          style={{
            transform: "translateY(-2px)",
            width: "134px",
            height: "32px",
          }}
        /> */}
        <img
          src="https://itscredible.com/wp-content/uploads/2019/10/Itscredible-Logo-3.webp"
          style={{ maxWidth: "184px" }}
        />

        <Paper sx={{ p: 4, width: 1, maxWidth: "416px" }}>
          {props.children}
        </Paper>

        <Card sx={{ mt: 6, width: 1, maxWidth: "416px" }}>
          <CardActionArea onClick={() => navigate("//credulocker.com")}>
            <Box py={3} px={4}>
              <Stack direction="row" alignItems="center" spacing={2}>
                <img
                  src={Badge}
                  style={{ display: "block", maxWidth: "40px" }}
                />
                <Typography variant="h5">
                  Click here to access your Credentials
                </Typography>
              </Stack>
            </Box>
          </CardActionArea>
        </Card>
      </Stack>
    </Box>
  );
}

import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Chip, Divider, Stack, Typography } from "@mui/material";
import { useAxios } from "hooks/useAxios";
import { useAuth } from "hooks/useAuth";

export default function EmailTemplates(props) {
  const { onApply } = props;
  const { user } = useAuth();
  const [data] = useAxios(
    `signedDocs/notifyEmailContent?Organization=${user?.companyDisplayName}&Sender=${user?.displayName}&SenderEmail=${user?.email}`,
  );
  const emailTemplatesData = data?.value;
  const [open, setOpen] = React.useState(false);
  const [emailTemplateContent, setEmailTemplateContent] = React.useState({});

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const showPreview = (content) => {
    setOpen(true);
    setEmailTemplateContent(content);
  };

  return (
    <>
      <Box sx={{ mb: "-12px !important" }}>
        {Object.entries(emailTemplatesData || {}).map(
          ([emailTemplateTitle, emailTemplateContent]) => (
            <Box
              key={emailTemplateTitle}
              mr={1.5}
              mb={1.5}
              display="inline-block"
            >
              <Chip
                color="primary"
                variant="outlined"
                label={emailTemplateTitle}
                onClick={() =>
                  showPreview({
                    title: emailTemplateTitle,
                    ...emailTemplateContent,
                  })
                }
              />
            </Box>
          ),
        )}
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          Preview - {emailTemplateContent.title}
        </DialogTitle>
        <DialogContent dividers={true} sx={{ p: 2 }}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <Stack spacing={2}>
              <Typography variant="h5">
                Subject: {emailTemplateContent.subject}
              </Typography>
              <Divider />
              <Box sx={{ "*": { all: "revert" } }}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: emailTemplateContent.body,
                  }}
                />
              </Box>
            </Stack>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button onClick={handleClose}>Back</Button>
          <Button
            variant="contained"
            onClick={() => {
              onApply(emailTemplateContent);
              handleClose();
            }}
          >
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

import React, { useState } from "react";
import { Stack, TextField, Button } from "@mui/material";
import { customAxios } from "hooks/useAxios";
import { useAuth } from "hooks/useAuth";

export default function CreateLabelForm(props) {
  const { labelName = "", handleClose, cb = () => {} } = props;
  const { user, reloadUser } = useAuth();
  const [formData, setFormData] = useState({
    labelName: labelName,
    error: "",
  });
  const [saving, setSaving] = useState(false);

  const handleSubmit = async () => {
    if (formData.labelName.trim().length === 0) {
      setFormData((prevState) => ({
        ...prevState,
        error: "Please enter a non-empty value",
      }));
    } else {
      setSaving(true);
      let response;
      if (labelName) {
        response = await customAxios.put("tag", {
          oldTag: labelName,
          newTag: formData.labelName,
        });
      } else {
        response = await customAxios.post("profile/addTagToProfile", {
          uid: user?.uid,
          tag: formData.labelName,
        });
      }

      if (response) {
        cb(formData.labelName);
        await reloadUser();
        handleClose();
      }
      setSaving(false);
    }
  };

  return (
    <Stack spacing={2} pt={1} onClick={(e) => e?.stopPropagation()}>
      <TextField
        label="Label Name"
        required
        error={Boolean(formData.error)}
        helperText={formData.error || "Please enter a value"}
        value={formData.labelName}
        onChange={(e) => setFormData({ labelName: e.target.value })}
      />
      <Stack direction="row" justifyContent="flex-end" spacing={1}>
        <Button disableElevation color="primary" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          type="submit"
          disableElevation
          variant="contained"
          color="primary"
          disabled={saving}
          sx={{ minWidth: "80px" }}
          onClick={handleSubmit}
        >
          {saving ? "Processing..." : "Add"}
        </Button>
      </Stack>
    </Stack>
  );
}

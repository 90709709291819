import React from "react";
import AuthLayout from "layouts/AuthLayout";
import { TextField, Stack, Link, Typography, Button } from "@mui/material";
import Form from "components/auth/Form";
import { Link as RouterLink, useNavigate, useLocation } from "react-router-dom";
import { customAxios } from "hooks/useAxios.js";

export default function ForgotPasswordStep2() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = React.useState(false);
  const [formData, setFormData] = React.useState({
    otp: null,
    newPassword: null,
    confirmPassword: null,
  });

  React.useEffect(() => {
    if (!location.state?.email) {
      navigate(`${process.env.REACT_APP_BASE_ROUTE}/auth/forgotPassword/step1`);
    }
  }, []);

  const handleResend = async (e) => {
    e.target.innerText = "Sending...";

    const response = await customAxios.put(
      `forgotpassword/${location.state?.email}`,
    );

    if (response?.data?.codeSent) {
      e.target.innerText = "Sent!";
      setTimeout(() => {
        e.target.innerText = "Resend";
      }, 5000);
    } else {
      e.target.innerText = "Resend";
      console.log(response);
    }
  };

  const doLogin = async ({ email, password }) => {
    const response = await customAxios.get("login", {
      headers: {
        authorization: `Basic ${email}:${password}`,
      },
    });

    const accessToken = response?.data?.response?.accessToken;

    if (accessToken) {
      window.localStorage.setItem("jwt", accessToken);
      navigate(`${process.env.REACT_APP_BASE_ROUTE}/user/home/?filter=SENT`);
      return;
    } else {
      console.log(response);
    }
  };

  const handleForgotPassword = async () => {
    setIsLoading(true);
    if (formData.newPassword !== formData.confirmPassword) {
      throw new Error("Passwords do not match");
    }
    const response = await customAxios.put("confirmResetPassword", {
      email: location.state.email,
      code: formData.otp,
      newPassword: formData.newPassword,
    });
    if (response) {
      await doLogin({
        email: location.state.email,
        password: formData.newPassword,
      });
    }
    setIsLoading(false);
  };

  return (
    <AuthLayout>
      <Form
        onSubmit={handleForgotPassword}
        heading="Reset Password"
        field1={
          <Typography variant="body1" align="center">
            An Email with 6 digit OTP was just sent to{" "}
            <b>{location.state?.email}</b>
          </Typography>
        }
        field2={
          <Stack spacing={1} alignItems="center">
            <TextField
              label="Enter the OTP"
              variant="outlined"
              fullWidth
              onChange={(e) =>
                setFormData((prevState) => ({
                  ...prevState,
                  otp: e.target.value,
                }))
              }
              required
            />
            <Typography variant="body2" component="i">
              Did not receive the OTP?{" "}
              <Link sx={{ cursor: "pointer" }} onClick={handleResend}>
                Resend OTP
              </Link>
            </Typography>
          </Stack>
        }
        field3={
          <TextField
            label="New Password"
            variant="outlined"
            fullWidth
            onChange={(e) =>
              setFormData((prevState) => ({
                ...prevState,
                newPassword: e.target.value,
              }))
            }
            required
          />
        }
        field4={
          <TextField
            label="Confirm Password"
            variant="outlined"
            fullWidth
            helperText="Password must be 8 characters minimum, with at least one lowercase letter and one numeric digit."
            onChange={(e) =>
              setFormData((prevState) => ({
                ...prevState,
                confirmPassword: e.target.value,
              }))
            }
            required
          />
        }
        primaryAction={
          <Button
            variant="contained"
            size="large"
            type="submit"
            disabled={
              isLoading || !Object.values(formData).every((f) => Boolean(f))
            }
          >
            {isLoading ? "Resetting..." : "Reset Password"}
          </Button>
        }
        secondaryAction={
          <Link
            component={RouterLink}
            sx={{ alignSelf: "center" }}
            onClick={() => navigate(-1)}
          >
            {"< Back"}
          </Link>
        }
      />
    </AuthLayout>
  );
}

import React from "react";
import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDateTimePicker } from "@mui/x-date-pickers/StaticDateTimePicker";

export default function SchedulePopup({
  defaultValue = moment(),
  handleClose,
  handleSubmit,
}) {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <StaticDateTimePicker
        orientation="landscape"
        localeText={{ okButtonLabel: "Schedule" }}
        disablePast
        minutesStep={5}
        defaultValue={defaultValue}
        onClose={handleClose}
        onAccept={(momentDate) => handleSubmit(momentDate.toDate())}
      />
    </LocalizationProvider>
  );
}

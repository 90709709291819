import React from "react";
import TabLayout from "layouts/TabLayout";
import { AppBar, Box, Button, Grid, Stack, Typography } from "@mui/material";
import WatchLaterOutlinedIcon from "@mui/icons-material/WatchLaterOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import { useAxios } from "hooks/useAxios";
import moment from "moment";

const TransactionListItem = (props) => {
  const { data } = props;
  const STATUS = {
    SUCCESS: {
      icon: <CheckCircleOutlinedIcon fontSize="large" color="success" />,
      text: "Success",
    },
    FAILURE: {
      icon: <CancelOutlinedIcon fontSize="large" color="error" />,
      text: "Failed",
    },
    PENDING: {
      icon: <WatchLaterOutlinedIcon fontSize="large" color="warning" />,
      text: "Pending",
    },
  };

  const downloadInvoice = async () => {
    window.open(
      `${
        window.location.origin.includes("localhost")
          ? "http://localhost:3000"
          : window.location.origin
      }/api/v1/invoice/${data.merchantTransactionId}`,
      "_blank",
    );
  };

  return (
    <Grid container item xs={12}>
      <Grid item xs={2}>
        <Stack direction="row" alignItems="center" spacing={1}>
          {STATUS[data.status].icon}
          <Stack>
            <Typography variant="body1">{STATUS[data.status].text}</Typography>
            {data.status === "FAILURE" && (
              <Typography variant="caption">{data.message}</Typography>
            )}
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={3}>
        <Stack>
          <Typography variant="body1">
            {moment(data.createdAt).format("lll")}
          </Typography>
          <Typography variant="caption">
            {data.merchantTransactionId}
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="body1" align="center">
          &#8377;
          {parseFloat(data.amount).toFixed(2)}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="body1" align="center">
          {data.credits}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="body1">
          {data?.paymentInstrument?.type || "-"}
        </Typography>
      </Grid>
      <Grid item xs={1}>
        {data.status === "SUCCESS" && (
          <Button
            variant="outlined"
            size="small"
            startIcon={<DownloadOutlinedIcon />}
            onClick={downloadInvoice}
          >
            Invoice
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default function PurchaseHistory() {
  const [data, isLoading] = useAxios("transactions");
  return (
    <TabLayout>
      <Stack p={2}>
        <Typography variant="h4">Purchase History</Typography>

        <Box width="100%" overflow="auto">
          <Grid
            container
            rowGap={2}
            sx={{
              py: 2,
              width: "max-content",
              maxWidth: "100%",
              minWidth: "800px",
            }}
          >
            <Grid
              container
              item
              xs={12}
              component={AppBar}
              position="sticky"
              top={0}
              py={2}
              elevation={0}
              bgcolor="common.white"
              color="inherit"
              borderBottom="1px solid"
              borderColor="border.main"
            >
              <Grid item xs={2.5}>
                <Typography variant="h6">Status</Typography>
              </Grid>
              <Grid item xs={3.5}>
                <Typography variant="h6">Transaction</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="h6" align="center">
                  Amount
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="h6" align="center">
                  Credits Requested
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography variant="h6">Mode</Typography>
              </Grid>
              <Grid item xs={1}></Grid>
            </Grid>
            {!isLoading && data?.transactions?.length ? (
              data?.transactions?.map((transaction) => (
                <TransactionListItem
                  key={transaction?._id}
                  data={transaction}
                />
              ))
            ) : (
              <Grid item xs={12}>
                <Typography align="center">No Data Available</Typography>
              </Grid>
            )}
          </Grid>
        </Box>
      </Stack>
    </TabLayout>
  );
}

import React from "react";
import { useTour } from "hooks/useTour";
import {
  Box,
  Button,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ExploreOutlinedIcon from "@mui/icons-material/ExploreOutlined";
import CustomPopover from "components/common/customPopover";

export default function AppTourPopover() {
  const { setShowTour } = useTour();
  return (
    <CustomPopover
      AnchorComponent={(syntax) => (
        <Tooltip title="Take a guided tour">
          <IconButton {...syntax}>
            <HelpOutlineIcon />
          </IconButton>
        </Tooltip>
      )}
      PopoverComponent={(syntax) => (
        <Stack direction="row" spacing={1} p={2.5} {...syntax}>
          <ExploreOutlinedIcon />
          <Stack spacing={1}>
            <Typography variant="h5">
              Explore our app with a guided tour!
            </Typography>
            <Typography variant="body2">
              Click below to take a guided tour of the app.
            </Typography>
            <Box pt={1} textAlign="end">
              <Button
                variant="contained"
                disableElevation
                onClick={() => {
                  syntax.handleClose();
                  setShowTour(true);
                }}
              >
                Start Tour
              </Button>
            </Box>
          </Stack>
        </Stack>
      )}
    />
  );
}

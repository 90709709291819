import React from "react";
import { Stack, Typography, Paper } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";

export default function NewBlankCarouselSlide(props) {
  const { text, onClick, sx } = props;

  return (
    <Paper
      variant="dashed-outlined"
      onClick={onClick}
      sx={{ cursor: "pointer" }}
    >
      <Stack
        alignItems="center"
        justifyContent="center"
        spacing={1}
        p={1}
        sx={sx || { height: "136px" }}
      >
        <AddCircleIcon fontSize="large" color="primary" />
        <Typography variant="body1" textAlign="center">
          {text}
        </Typography>
      </Stack>
    </Paper>
  );
}

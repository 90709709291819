import React from "react";
import {
  Button,
  Box,
  Typography,
  Divider,
  Icon,
  CircularProgress,
} from "@mui/material";
import CustomDialog from "components/common/CustomDialog";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import moment from "moment";
import { keyframes } from "@mui/system";

const opacity = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const opacityReversed = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

function VerifyCredential(props) {
  const data = [
    {
      label: "Issued by",
      value: props?.issuerName,
      error: !props?.isIssuerVerified && "Unverified issuer on Itscredible",
    },
    ...(props?.recipientName
      ? [
          {
            label: "Issued to",
            value: props?.recipientName,
          },
        ]
      : []),
    ...(props?.issueDate
      ? [
          {
            label: "Issued on",
            value: props?.issueDate,
          },
        ]
      : []),
    {
      label: "Expiry on",
      value: props?.expiryDate || "NA",
      error:
        props?.expiryDate &&
        new Date(moment(props.expiryDate, "DD-MM-YYYY").format("YYYY-MM-DD")) <
          new Date() &&
        "Expired!",
    },
    {
      label: "Issued using",
      value: "ItsCredible",
    },
  ];

  return (
    <>
      <CustomDialog
        AnchorComponent={({ onClick }) => (
          <div style={{ minHeight: "60px" }}>
            <Button
              onClick={onClick}
              variant="contained"
              style={{
                position: "fixed",
                bottom: "10px",
                right: "20px",
                transition: "all 0.15s ease",
                padding: "0 20px",
                height: "40px",
                lineHeight: "40px",
                borderRadius: "40px",
                border: 0,
                boxShadow: "0px 0px 9px 2px rgba(0,0,0,.1)",
                backgroundColor: "#757575",
                color: "#FFFFFF",
                opacity: "1 !important",
                "&:hover": {
                  backgroundColor: "#757575",
                  color: "#FFFFFF",
                },
                "&:active": {
                  backgroundColor: "#757575",
                  color: "#FFFFFF",
                  transform: "translateY(1px)",
                },
              }}
            >
              Verify Credential
            </Button>
          </div>
        )}
        title="Credential Verification"
        size="xs"
        Content={(dialogProps) => (
          <>
            <Box mb={-1.5} {...dialogProps}>
              {data.map((d, idx) => (
                <React.Fragment key={idx}>
                  <Box
                    py={1.5}
                    sx={{
                      mb: -8,
                      animation: `${opacityReversed} 0.15s linear ${
                        idx * 2
                      }s forwards`,
                    }}
                  >
                    <CircularProgress />
                  </Box>
                  <Box
                    sx={{
                      opacity: 0,
                      animation: `${opacity} 0.3s linear ${idx * 2}s forwards`,
                    }}
                  >
                    <Box py={1.5} display="flex">
                      <Box mr={1}>
                        {d?.error ? (
                          <CloseOutlinedIcon color="error" />
                        ) : (
                          <CheckOutlinedIcon color="success" />
                        )}
                      </Box>
                      <Box>
                        <Typography variant="body2">{d.label}</Typography>
                        <Typography variant="h5">{d.value}</Typography>
                        <Typography variant="body2" color="error">
                          {d.error}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Divider />
                </React.Fragment>
              ))}
              <Box
                py={2}
                display="flex"
                sx={{
                  opacity: 0,
                  animation: `${opacity} 0.3s linear ${
                    (data.length - 1) * 2
                  }s forwards`,
                }}
              >
                {data.find((d) => Boolean(d.error)) ? (
                  <>
                    <Box mr={1}>
                      <Icon className="icon-close" color="error" />
                    </Box>
                    <Typography variant="h4" color="error" mt={1}>
                      Credential is UNVERIFIED
                    </Typography>
                  </>
                ) : (
                  <>
                    <Box mr={1}>
                      <Icon className="icon-check" color="success" />
                    </Box>
                    <Typography variant="h4" sx={{ color: "#2e7d32" }} mt={1}>
                      Credential is VERIFIED
                    </Typography>
                  </>
                )}
              </Box>
            </Box>
          </>
        )}
      />
    </>
  );
}

export default VerifyCredential;

import React, { useState } from "react";
import { Button, Stack, TextField, Typography } from "@mui/material";
import Codebox from "components/common/Codebox";
import { customAxios } from "hooks/useAxios";
import validator from "validator";
import { useAuth } from "hooks/useAuth";

export default function DocumentViewerWidgetBody() {
  const { user } = useAuth();
  const [urlForQr, setUrlForQr] = useState(user?.config?.urlForQr || "");
  const [isSaving, setIsSaving] = useState(false);
  const REACT_APP_BASE_URL = "https://portal.itscredible.com";
  const DOCUMENT_VIEWER_WIDGET_CODE = `<div id="vmDocumentViewerWidget"></div>
<script>
  var docid = new URLSearchParams(window.location.search).get("docid");
  document.getElementById("vmDocumentViewerWidget").dataset.docid = docid;
</script>
<script src="${REACT_APP_BASE_URL}/widgets/templates${REACT_APP_BASE_URL}/library.js"></script>
<link rel="stylesheet" href="${REACT_APP_BASE_URL}/widgets/templates${REACT_APP_BASE_URL}/library.css">`;

  const saveUrlForQr = async () => {
    setIsSaving(true);
    await customAxios.post("company/updateCompanyConfiguration", {
      updatedConfig: { urlForQr },
    });
    setIsSaving(false);
  };

  return (
    <>
      <Stack direction="column" spacing={1} mt={1}>
        <Typography variant="body2">
          Please enter the URL of the page on which you have integrated the
          above code.
        </Typography>
        <Stack direction="row" spacing={1} alignItems="flex-start">
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            helperText={
              Boolean(urlForQr) && !validator.isURL(urlForQr)
                ? "Invalid URL"
                : "www.domain.com/?docid="
            }
            placeholder="www.domain.com/?docid="
            value={urlForQr}
            onChange={(e) => setUrlForQr(e.target.value)}
            error={Boolean(urlForQr) && !validator.isURL(urlForQr)}
          />
          <Button
            variant="contained"
            size="large"
            onClick={saveUrlForQr}
            disabled={isSaving}
            sx={{ wordBreak: "normal" }}
          >
            {isSaving ? "Saving..." : "Save"}
          </Button>
        </Stack>
      </Stack>
      <Codebox mt={2}>{DOCUMENT_VIEWER_WIDGET_CODE}</Codebox>
    </>
  );
}

import React, { useEffect, useRef, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { useAuth } from "hooks/useAuth";
import { customAxios } from "hooks/useAxios";
import moment from "moment";

const General = () => {
  const { user, reloadUser } = useAuth();
  const passwordRef = useRef();
  const [smtp, setSmtp] = useState(user?.smtpConfig);
  const [smtpValidity, setSmtpValidity] = useState({
    valid: user?.smtpConfig?.valid,
    lastValidTime: user?.smtpConfig?.lastValidTime,
  });
  const [password, setPassword] = useState(user?.smtpConfig?.password);
  const [isPasswordEditable, setIsPasswordEditable] = useState(false);
  const [isEmailSending, setIsEmailSending] = useState(false);

  useEffect(() => {
    if (isPasswordEditable) {
      setPassword("");
      passwordRef?.current?.focus();
    } else {
      setPassword(user?.smtpConfig?.password);
    }
  }, [isPasswordEditable]);

  useEffect(() => {
    (async () => {
      if (smtp !== user?.smtpConfig) {
        const response = await customAxios.post("profile/setupSMTP", smtp);
        response && reloadUser();
      }
    })();
  }, [smtp]);

  const sendTestEmail = async () => {
    setIsEmailSending(true);
    const response = await customAxios.post("profile/sendTestEmail", {
      ...smtp,
      from: smtp?.username,
      to: "test@itscredible.com",
    });
    if (response) {
      setSmtpValidity({
        valid: true,
        lastValidTime: Date.now(),
      });
    } else {
      setSmtpValidity({
        valid: false,
        lastValidTime: Date.now(),
        error: "Something went wrong",
      });
    }
    await reloadUser();
    setIsEmailSending(false);
  };

  return (
    <Stack spacing={3} py={3}>
      <TextField
        label="Mail Server"
        variant="outlined"
        size="small"
        fullWidth
        helperText="smtp.gmail.com, smtp-mail.outlook.com, etc"
        defaultValue={smtp?.server}
        onBlur={(e) =>
          setSmtp((prevState) => ({ ...prevState, server: e.target.value }))
        }
      />
      <TextField
        label="Username"
        variant="outlined"
        size="small"
        fullWidth
        helperText="UserName provided by SMTP provider"
        defaultValue={smtp?.username}
        onBlur={(e) =>
          setSmtp((prevState) => ({ ...prevState, username: e.target.value }))
        }
      />
      <Stack direction="row" alignItems="flex-start" spacing={1}>
        <TextField
          inputRef={passwordRef}
          type={isPasswordEditable ? "text" : "password"}
          disabled={!isPasswordEditable}
          label="Password"
          variant="outlined"
          size="small"
          fullWidth
          helperText="Password provided by SMTP provider"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        {isPasswordEditable ? (
          <>
            <Button
              variant="contained"
              size="large"
              disableElevation
              startIcon={<CheckIcon />}
              onClick={() => {
                setSmtp((prevState) => ({ ...prevState, password: password }));
                setIsPasswordEditable(false);
              }}
            >
              Update
            </Button>
            <Button
              variant="outlined"
              size="large"
              disableElevation
              startIcon={<ClearIcon />}
              onClick={() => setIsPasswordEditable(false)}
            >
              Cancel
            </Button>
          </>
        ) : (
          <Button
            variant="outlined"
            size="large"
            disableElevation
            startIcon={<EditIcon />}
            onClick={() => setIsPasswordEditable(true)}
          >
            Edit
          </Button>
        )}
      </Stack>
      <TextField
        label="Port"
        variant="outlined"
        size="small"
        fullWidth
        helperText="587, 25, 465, 2525, or etc"
        defaultValue={smtp?.port}
        onBlur={(e) =>
          setSmtp((prevState) => ({ ...prevState, port: e.target.value }))
        }
      />
      <Paper
        variant="outlined"
        sx={{
          backgroundColor: "primary.surface",
          display: "flex",
          alignItems: "center",
          p: 2,
        }}
      >
        <Button
          variant="contained"
          onClick={sendTestEmail}
          disabled={isEmailSending}
          size="large"
          disableElevation
          sx={{ px: 2, flexShrink: 0 }}
        >
          Validate
        </Button>
        {isEmailSending ? (
          <Box display="flex" alignItems="center" mx={2}>
            {/* <CircularProgress size={20} /> */}
            <Box ml={1}>Validating...</Box>
          </Box>
        ) : smtpValidity?.valid === true ? (
          <Stack direction="column" mx={2} spacing={0.5}>
            <Typography variant="body1" color="success.main" noWrap>
              SMTP setup successfully done.
            </Typography>
            <Typography variant="body2" color="text.primary" noWrap>
              {moment(smtpValidity?.lastValidTime).format("lll")}
            </Typography>
          </Stack>
        ) : smtpValidity?.valid === false ? (
          <Stack direction="column" mx={2} spacing={0.5}>
            <Typography variant="body1" color="error.main" noWrap>
              SMTP setup failed.
            </Typography>
            <Typography variant="body2" color="text.primary" noWrap>
              Click the Validate button to retry
            </Typography>
            <Typography variant="body2" color="text.primary" noWrap>
              {moment(smtpValidity?.lastValidTime).format("lll")}
            </Typography>
          </Stack>
        ) : null}
        {!isEmailSending && smtpValidity?.error ? (
          <Alert icon={false} severity="error">
            {smtpValidity?.error}
          </Alert>
        ) : null}
      </Paper>
    </Stack>
  );
};

export default General;

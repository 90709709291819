import React from "react";
import LoadingBar from "react-top-loading-bar";

export default function Loader() {
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    setProgress(40);
    setTimeout(() => {
      setProgress(60);
    }, 700);
    setTimeout(() => {
      setProgress(100);
    }, 1400);
  }, []);

  return (
    <LoadingBar
      color="#0d98ba"
      progress={progress}
      onLoaderFinished={() => setProgress(0)}
      height={3}
    />
  );
}

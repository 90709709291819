import React, { useState } from "react";
import { Stack, Switch, TextField, Typography } from "@mui/material";
import Codebox from "components/common/Codebox";
import { useAuth } from "hooks/useAuth";
import validator from "validator";

export default function AccessDocumentWidgetBody() {
  const { user } = useAuth();
  const [logoBranding, setLogoBranding] = useState(true);
  const [helpUrl, setHelpUrl] = useState("");
  const REACT_APP_BASE_URL = "https://portal.itscredible.com";
  const VERIFICATION_WIDGET_CODE = `<div 
  id="vmVerificationWidget" 
  data-widget-key="${user.companyId}" 
  data-widget-branding="${logoBranding}" 
  data-widget-help-url=${
    helpUrl && validator.isURL(helpUrl) ? `"${helpUrl}"` : '""'
  }
></div>
<script src="${REACT_APP_BASE_URL}/library.js"></script>`;

  return (
    <>
      <Stack direction="row" alignItems="center" spacing={1} mt={1}>
        <Typography variant="body1">ItsCredible logo on the widget</Typography>
        <Switch
          checked={logoBranding}
          onChange={() => setLogoBranding((prevState) => !prevState)}
        />
      </Stack>
      <Stack direction="column" spacing={1} mt={1}>
        <Typography variant="body2">
          Please enter the URL of the page you want to redirect the users once
          they click on &quot;Need Help?&quot; link present on your verification
          page.
        </Typography>
        <TextField
          variant="outlined"
          size="small"
          placeholder="https://yourdomain.com/contact"
          helperText="https://yourdomain.com/contact"
          value={helpUrl}
          onChange={(e) => setHelpUrl(e.target.value)}
        />
      </Stack>
      <Codebox mt={2}>{VERIFICATION_WIDGET_CODE}</Codebox>
    </>
  );
}

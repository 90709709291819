import { Button, Stack } from "@mui/material";
import { useAuth } from "hooks/useAuth";
import { customAxios } from "hooks/useAxios";
import React, { useState } from "react";
import MySwal from "utils/Swal";

const finalizeEmail = (body) => {
  const style =
    '"text-decoration: none; padding: 10px 15px; border-radius: 3px; font-size: 16px; display: inline-block; background: #0d98ba; color: #fff;"';
  const hrefPosition = body.indexOf('href="{{DocumentLink}}"');
  let mailBody = `${body.slice(0, hrefPosition)}style=${style}${body.slice(
    hrefPosition,
  )}`;
  mailBody = mailBody.replaceAll("<p>", '<p style="margin: 0">');
  return mailBody;
};

export default function NotificationPopup(props) {
  const { NotificationForm, data, method, callback = () => {} } = props;
  const { user } = useAuth();
  const smtpConfig = user?.smtpConfig;
  const lastSavedSmtpConfig = user?.notifyConfig;
  const [formData, setFormData] = useState({
    emailSettings: {
      from: {
        name: user.website,
        address:
          smtpConfig?.username || process.env.REACT_APP_NOTIFICATION_EMAIL,
      },
      subject: lastSavedSmtpConfig?.subject,
      body: lastSavedSmtpConfig?.emailContent,
      isBranding: true,
      replyTo: lastSavedSmtpConfig?.replyTo,
    },
  });
  const [message, setMessage] = useState(
    `Hi {{receiver}}, ${user.companyDisplayName} has issued you a credential using ItsCredible Platform.`,
  );

  const handleSubmit = async () => {
    MySwal.showLoader("", "Credentials are getting sent..");
    if (method === "email") {
      const to = data.map((d) => ({
        email: d?.email,
        docId: d?.docId,
        displayId: d?.displayId,
      }));
      await customAxios.post("signedDocs/notifyViaEmail", {
        ...formData.emailSettings,
        body: finalizeEmail(formData.emailSettings.body),
        to,
      });
    } else {
      const displayIds = data.map((d) => d?.displayId);
      await customAxios.post("notifyViaWhatsapp", {
        displayIds,
        message,
      });
    }
    callback();
    MySwal.fire({
      title: "Success!",
      allowEscapeKey: false,
      allowOutsideClick: false,
      icon: "success",
      showConfirmButton: false,
      timer: 2000,
    });
  };

  const childProps =
    method === "email"
      ? {
          formData: formData.emailSettings,
          setFormData,
        }
      : {
          message,
          setMessage,
        };
  return (
    <Stack alignItems="center" spacing={3}>
      <NotificationForm
        to={data.map((d) => d?.email || d?.num)}
        {...childProps}
      />
      <Button
        variant="contained"
        disableElevation
        sx={{ minWidth: 150 }}
        onClick={handleSubmit}
      >
        Send
      </Button>
    </Stack>
  );
}

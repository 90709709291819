import React from "react";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import TextFieldLayout from "components/common/TextfieldLayout";
import { Box } from "@mui/material";

export default function Editor(props) {
  const { value, onChange } = props;

  return (
    <>
      <TextFieldLayout {...props}>
        <Box
          sx={{
            "& .ql-toolbar.ql-snow": { borderWidth: "0 0 1px" },
            "& .ql-container.ql-snow": { border: 0 },
          }}
        >
          <ReactQuill
            theme="snow"
            value={value}
            onChange={onChange}
            // onBlur={(range, source, quill) => {
            //   // FixMe
            //   onChange(quill.getHTML());
            // }}
          />
        </Box>
      </TextFieldLayout>
    </>
  );
}

import { useLocation } from "react-router-dom";

export default function UserGuideSteps() {
  const location = useLocation()

  return [
    {
      target: "#root",
      title: "Welcome to ItsCredible!",
      content:
        "We're excited to have you here. Let's take a quick tour to help you get started.",
      placement: "center",
      locale: { next: "Let's get started" },
    },
    {
      target: '[data-tut="SentTab"]',
      title: "Sent Tab",
      content: "View all the credentials you have sent to recipients here.",
      placement: "auto",
    },
    {
      target: '[data-tut="DraftTab"]',
      title: "Draft Tab",
      content:
        "Check your saved and in progress drafts for credentials you are still working on.",
      placement: "auto",
    },
    {
      target: '[data-tut="OutboxTab"]',
      title: "Outbox Tab",
      content: "View credentials that are waiting to be sent.",
      placement: "auto",
    },
    {
      target: '[data-tut="RecipientsTab"]',
      title: "Recipients Tab",
      content:
        "Review the history and details of recipients who have been sent credentials before.",
      placement: "auto",
    },
    {
      target: '[data-tut="AnalyticsTab"]',
      title: "Analytics Tab",
      content: "Analyze data related to your issued credentials.",
      placement: "auto",
    },
    {
      target: '[data-tut="NewCredentialBtn"]',
      title: "New Credential Button",
      content: 'Click on the "New" button and start issung credentials.',
      placement: "auto",
      spotlightClicks: true,
      spotlightPadding: 10,
      styles: {
        buttonNext: {
          display: "none",
        },
      },
    },
    {
      target: '[data-tut="IssuanceStepper"]',
      title: "Issuance Steps",
      content: "Follow these steps to issue a credential.",
      placement: "auto",
      spotlightPadding: 10,
    },
    {
      target: '[data-tut="TemplateLibraryWrapper"]',
      title: "Select a Template",
      content:
        "Choose a template for your credential from the library by clicking on it to select.",
      placement: "bottom",
      spotlightClicks: true,
      styles: {
        buttonNext: {
          display: "none",
        },
      },
    },
    {
      target: '[data-tut="CredentialLivePreview"]',
      title: "Real-time Preview of Credential",
      content: "See a live preview of how your credential will look.",
      placement: "auto",
      data: {
        previous: `${
          process.env.REACT_APP_BASE_ROUTE
        }/issuance/create/selectTemplate/${location.search}`,
      },
    },
    {
      target: '[data-tut="CredentialSetting"]',
      title: "Credential Settings",
      content: "Configure the settings for your credential.",
      placement: "left-start",
      spotlightPadding: 16,
      spotlightClicks: true,
    },
    {
      target: '[data-tut="CredentialSettingNextBtn"]',
      content: 'Click on the "Next" button to proceed to the next step.',
      placement: "top",
      spotlightPadding: 10,
      disableScrolling: false,
      spotlightClicks: true,
      styles: {
        buttonNext: {
          display: "none",
        },
      },
    },
    {
      target: '[data-tut="AddRecipients"]',
      title: "Add Recipients",
      content:
        'Enter the details of the recipients who will receive the credential, then click on the "Next" button to continue.',
      placement: "left-start",
      spotlightPadding: 16,
      spotlightClicks: true,
      styles: {
        buttonNext: {
          display: "none",
        },
      },
      data: {
        previous: `${
          process.env.REACT_APP_BASE_ROUTE
        }/issuance/create/settings/${location.search}`,
      },
    },
    // {
    //   target: '[data-tut="AddRecipientsNextBtn"]',
    //   content: 'Click on the "Next" button to proceed to the next step.',
    //   placement: "top",
    //   spotlightPadding: 10,
    //   disableScrolling: false,
    //   spotlightClicks: true,
    //   styles: {
    //     buttonNext: {
    //       display: "none",
    //     },
    //   },
    // },
    {
      target: '[data-tut="SendCredential"]',
      title: "Send Credential",
      content:
        "Please review all the notification configurations for sending the credentials to the recipients.",
      placement: "left-start",
      spotlightPadding: 16,
      spotlightClicks: true,
      data: {
        previous: `${
          process.env.REACT_APP_BASE_ROUTE
        }/issuance/create/recipients/${location.search}`,
      },
    },
    {
      target: '[data-tut="SendCredentialSendBtn"]',
      content:
        'Click on the "Send" button to send the credentials to the recipients.',
      placement: "top",
      spotlightPadding: 10,
      disableScrolling: false,
    },
  ];
}

import React from "react";
import AuthLayout from "layouts/AuthLayout";
import { TextField, Stack, Link, Typography, Button } from "@mui/material";
import Form from "components/auth/Form";
import { Link as RouterLink, useNavigate } from "react-router-dom";

export default function LoginOtp() {
  const navigate = useNavigate();

  return (
    <AuthLayout>
      <Form
        heading="Log In to Issue Credentials"
        field1={
          <Typography variant="body1" align="center">
            An Email with 6 digit OTP was just sent to <b>test@test.com</b>
          </Typography>
        }
        field2={
          <Stack spacing={2} alignItems="center">
            <TextField label="Enter the OTP" variant="outlined" fullWidth />
            <Typography variant="body2" component="i">
              Didnt receive the OTP? <Link>Resend OTP</Link>
            </Typography>
          </Stack>
        }
        primaryAction={
          <Button variant="contained" size="large">
            Login
          </Button>
        }
        secondaryAction={
          <Link
            component={RouterLink}
            sx={{ alignSelf: "center" }}
            onClick={() => navigate(-1)}
          >
            {"< Back"}
          </Link>
        }
      />
    </AuthLayout>
  );
}

import { IconButton, Tooltip } from "@mui/material";
import React from "react";
import RefreshIcon from "@mui/icons-material/Refresh";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { customAxios } from "hooks/useAxios";
import MySwal from "utils/Swal";

export default function ActionBar(props) {
  const { refresh, table, isScheduled } = props;

  const handleDelete = async () => {
    const selectedRows = table.getSelectedRowModel().rows;
    const ids = selectedRows.map((row) => row.original._id);

    if (ids.length) {
      const { isConfirmed } = await MySwal.fire({
        title: "Are you sure?",
        text: `Do you want to delete the selected ${
          isScheduled ? "scheduled batch" : "draft"
        }(s)?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      });

      if (isConfirmed) {
        MySwal.showLoader("Processing...");
        let response;
        if (isScheduled) {
          response = await customAxios.delete(`docs/scheduled`, {
            data: { scheduledBatchIds: ids },
          });
        } else {
          response = await customAxios.delete("drafts", {
            data: { draftIds: ids },
          });
        }

        if (response) {
          refresh();
          table.resetRowSelection();

          MySwal.fire({
            title: `${isScheduled ? "Scheduled Batch" : "Draft"}(s) Deleted!`,
            text: `The selected ${
              isScheduled ? "scheduled batch" : "draft"
            }(s) has been deleted!`,
            icon: "success",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
    } else {
      MySwal.fire({
        title: "No Rows Selected",
        text: "Please select rows to perform delete action.",
        icon: "info",
      });
    }
  };

  return (
    <>
      <Tooltip title="Reload">
        <IconButton onClick={refresh}>
          <RefreshIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete">
        <IconButton onClick={handleDelete}>
          <DeleteOutlineIcon />
        </IconButton>
      </Tooltip>
    </>
  );
}

import React from "react";
import { Stack, TextField, MenuItem, Typography } from "@mui/material";
import {
  DynamicDataSheetGrid,
  textColumn,
  keyColumn,
} from "react-datasheet-grid";
import "react-datasheet-grid/dist/style.css";
import Papa from "papaparse";
import "./spreadsheet.css";
import { useParams } from "react-router-dom";

export default function Spreadsheet(props) {
  const {
    data,
    setData,
    notificationPlatform,
    cellValidationFormula,
    csvFile,
  } = props;
  const { issuanceType } = useParams();
  const [csvFileData, setCsvFileData] = React.useState([]);
  const [csvFileColumns, setCsvFileColumns] = React.useState([]);
  const columns = React.useMemo(
    () =>
      props.columns.map((column) => ({
        ...keyColumn(column, textColumn),
        title: column,
        headerClassName: column.replaceAll(" ", "-"),
        ...(issuanceType === "existing" &&
          column === "File Name" && { disabled: true }),
      })),
    [props.columns],
  );

  React.useEffect(() => {
    if (csvFile) {
      Papa.parse(csvFile, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          setCsvFileData(results.data);
          setCsvFileColumns(results.meta.fields);
          const exactMatchColNames = props.columns.filter((column) =>
            results.meta.fields.includes(column),
          );
          const newData = results.data.map((csvRow) =>
            exactMatchColNames.reduce((row, col) => {
              row[col] = csvRow[col];
              return row;
            }, {}),
          );
          setData(newData);
        },
      });
    }
  }, [csvFile]);

  const handleMapping = (displayColumnName, csvFileColumnName) => {
    const dataTemp = data.map((d, i) => {
      d[displayColumnName] = csvFileData[i][csvFileColumnName];
      return d;
    });
    setData(dataTemp);
  };

  return (
    <>
      {Boolean(csvFileColumns.length) && (
        <>
          <Typography variant="h6">Select Source Column</Typography>
          <Stack direction="row" spacing={2} pl={6} pr={1}>
            {props.columns.map((column) => (
              <TextField
                key={column}
                select
                fullWidth
                label={column}
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => handleMapping(column, e.target.value)}
              >
                {csvFileColumns.map((csvFileColumn) => (
                  <MenuItem key={csvFileColumn} value={csvFileColumn}>
                    {csvFileColumn}
                  </MenuItem>
                ))}
              </TextField>
            ))}
          </Stack>
        </>
      )}
      <DynamicDataSheetGrid
        value={data}
        onChange={setData}
        columns={columns}
        height={99999999}
        className={`datasheet ${issuanceType} ${
          notificationPlatform?.email && notificationPlatform?.whatsapp
            ? "both-notify-method"
            : ""
        }`}
        cellClassName={({ rowData, columnId }) =>
          cellValidationFormula({ rowData, columnId }) && "has-error"
        }
        {...(issuanceType === "existing" ? { addRowsComponent: false } : {})}
      />
    </>
  );
}

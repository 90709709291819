import React from "react";
import { Button, Box, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import Dropzone from "./Dropzone";

const UploadButton = (props) => {
  const { text, value, onChange, onDelete } = props;
  return (
    <Dropzone
      accept={{ "image/png": [], "image/jpg": [], "image/jpeg": [] }}
      onDrop={onChange}
    >
      {value ? (
        <Button
          variant="outlined"
          color="border"
          disableRipple
          sx={{
            "&:hover": {
              "& > #delete-btn": { display: "initial" },
            },
          }}
        >
          <img
            src={value}
            alt="ORG_LOGO"
            style={{ maxWidth: "160px", maxHeight: "120px" }}
          />
          <DeleteIcon
            id="delete-btn"
            sx={{
              display: "none",
              position: "absolute",
              top: 0,
              right: 0,
              cursor: "pointer",
              color: "text.main",
              transform: "translate(50%, -50%)",
            }}
            onClick={(e) => {
              e?.stopPropagation();
              onDelete();
            }}
          />
        </Button>
      ) : (
        <Button variant="dashed">
          <Box px={4} py={2}>
            <AddIcon color="primary" fontSize="large" />
            <Typography variant="body2">{text}</Typography>
          </Box>
        </Button>
      )}
    </Dropzone>
  );
};

export default UploadButton;

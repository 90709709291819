import {
  Avatar,
  Box,
  Card,
  Chip,
  Stack,
  Typography,
  Grid,
  IconButton,
} from "@mui/material";
import React from "react";
import CustomPopover from "components/common/customPopover";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const stringToColor = (string) => {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
};

const stringAvatar = (name) => {
  return {
    sx: {
      bgcolor: stringToColor(name),
      width: "60px",
      height: "60px",
      fontSize: "2rem",
    },
    children: `${name.split(" ")[0][0]}${
      name.split(" ")?.[1]?.[0] || ""
    }`.toUpperCase(),
  };
};

const UserCard = (props) => {
  const { name, email, role, status, statusText, more } = props;
  return (
    <Box width="100%" sx={{ wordBreak: "break-word" }}>
      <Card>
        <Box p={2}>
          <Grid
            container
            spacing={2}
            direction="row"
            alignItems="center"
            flexWrap="nowrap"
          >
            <Grid item xs="auto">
              <Avatar {...stringAvatar(name)} />
            </Grid>
            <Grid item xs>
              <Box sx={{ wordWrap: "break-word" }}>
                <Stack
                  direction="row"
                  spacing={1}
                  justifyContent="space-between"
                >
                  <Typography variant="h6">{name}</Typography>
                  {more && (
                    <CustomPopover
                      AnchorComponent={(syntax) => (
                        <IconButton
                          {...syntax}
                          size="small"
                          style={{ margin: "-4px -4px -4px 0" }}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      )}
                      PopoverComponent={(syntax) => (
                        <Box {...syntax} autoFocus={false}>
                          {more}
                        </Box>
                      )}
                    />
                  )}
                </Stack>
                <Typography variant="body2">{email}</Typography>
              </Box>
            </Grid>
          </Grid>
          <Stack
            ml="76px"
            spacing={1}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="body2">{role}</Typography>
            <Chip label={statusText} color={status ? "success" : "error"} />
          </Stack>
        </Box>
      </Card>
    </Box>
  );
};

export default UserCard;

import React from "react";
import AuthLayout from "layouts/AuthLayout";
import { TextField, Stack, Box, Link, Typography, Button } from "@mui/material";
import Form from "components/auth/Form";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { customAxios } from "hooks/useAxios.js";

export default function ForgotPasswordStep2() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState();
  const [email, setEmail] = React.useState();

  const handleForgotPassword = async () => {
    setIsLoading(true);
    const response = await customAxios.put(`forgotpassword/${email}`);
    if (response?.data?.codeSent) {
      navigate(
        `${process.env.REACT_APP_BASE_ROUTE}/auth/forgotPassword/step2`,
        { state: { email } },
      );
    } else {
      console.log(response);
    }
    setIsLoading(false);
  };

  return (
    <AuthLayout>
      <Form
        heading="Forgot Password"
        onSubmit={handleForgotPassword}
        field1={
          <Box py={0.5}>
            <Typography variant="body2" align="center">
              Enter the email associated with your account and we will send you
              a <b>confirmation code</b> to reset your password
            </Typography>
          </Box>
        }
        field2={
          <Stack spacing={2} alignItems="center">
            <TextField
              label="Email Address"
              variant="outlined"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              required
            />
          </Stack>
        }
        primaryAction={
          <Button
            variant="contained"
            size="large"
            disabled={isLoading}
            required
            type="submit"
          >
            {isLoading ? "Sending..." : "Send"}
          </Button>
        }
        secondaryAction={
          <Link
            component={RouterLink}
            sx={{ alignSelf: "center" }}
            onClick={() => navigate(-1)}
          >
            {"< Back"}
          </Link>
        }
      />
    </AuthLayout>
  );
}

import React, { useState } from "react";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import General from "components/settings/General";
import Smtp from "components/settings/Smtp";
import Nft from "components/settings/Nft";
import UserManagement from "components/settings/UserManagement";
import ApiIntegration from "components/settings/ApiIntegration";
import WhiteLabel from "components/settings/WhiteLabel";
import TabLayout from "layouts/TabLayout";
import { useAuth } from "hooks/useAuth";

const Settings = () => {
  const { user } = useAuth();
  const isAdmin = user?.role === "VM_COMPANY_ADMIN";

  const tabs = [
    ...(isAdmin
      ? [
          {
            id: "GENERAL",
            label: "General",
            content: <General />,
          },
        ]
      : []),
    {
      id: "SMTP",
      label: "Email/SMTP",
      content: <Smtp />,
    },
    ...(isAdmin
      ? [
          {
            id: "NFT",
            label: "NFT",
            content: <Nft />,
          },
          {
            id: "USERS",
            label: "User Management",
            content: <UserManagement />,
          },
          {
            id: "API",
            label: "API Integration",
            content: <ApiIntegration />,
          },
          {
            id: "WHITE_LABEL",
            label: "White Label",
            content: <WhiteLabel />,
          },
        ]
      : []),
  ];
  const [activeTab, setActiveTab] = useState(tabs[0].id);

  return (
    <TabLayout>
      <Box bgcolor="#fff">
        <Box p={2}>
          <Typography variant="h4">Settings</Typography>
        </Box>
        <Tabs
          variant="scrollable"
          value={activeTab}
          onChange={(e, newValue) => setActiveTab(newValue)}
          scrollButtons="auto"
          allowScrollButtonsMobile
          sx={{
            position: "sticky",
            top: 0,
            backgroundColor: "#fff",
            borderBottom: "1px solid #eee",
            zIndex: 9,
          }}
        >
          {tabs.map((tab) => (
            <Tab key={tab.id} label={tab.label} value={tab.id} />
          ))}
        </Tabs>
        <Box p={2}>{tabs.find((tab) => tab.id === activeTab).content}</Box>
      </Box>
    </TabLayout>
  );
};

export default Settings;

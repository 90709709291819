import React from "react";
import { Box } from "@mui/material";
import Highlight from "react-highlight";
import "highlight.js/styles/atom-one-dark.css";
import CopyButton from "./CopyButton";

function Codebox(props) {
  return (
    <Box position="relative" {...props}>
      <CopyButton
        value={props.children}
        color="border"
        sx={{ position: "absolute", top: 4, right: 4 }}
      />
      <Highlight className="js">{props.children}</Highlight>
    </Box>
  );
}

export default Codebox;

import { Skeleton } from "@mui/material";
import React from "react";
import { debounce } from "lodash";

export default function LivePreview(props) {
  const { data, imageData, templateHtml } = props;
  const [credHtml, setCredHtml] = React.useState();

  const parentDiv = React.useCallback((el) => {
    if (el !== null) {
      const { width: parentWidth } = el.getBoundingClientRect();
      const { width: childWidth, height: childHeight } =
        el.childNodes[0].getBoundingClientRect();

      const ratio = parentWidth / childWidth;
      el.style.transform = `scale(${ratio})`;
      el.style.transformOrigin = "top left";
      el.style.height = childHeight * ratio + "px";
    }
  }, []);

  React.useEffect(() => {
    const updateCredHtml = () => {
      if (templateHtml && data) {
        setCredHtml(
          Object.entries(data).reduce(
            (temp, [key, value]) => temp.replaceAll(`{${key}}`, value || ""),
            templateHtml,
          ),
        );
      }
    };
    const debouncedUpdate = debounce(updateCredHtml, 500);
    debouncedUpdate();
    return () => debouncedUpdate.cancel();
  }, [templateHtml, data]);

  React.useEffect(() => {
    if (credHtml && imageData) {
      Object.entries(imageData).length && Object.entries(imageData).map(([label, url]) => {
        const elements = document.querySelectorAll(`#template-container [alt="${label}"]`);
        if (elements.length > 0 && url) {
          elements.forEach(element => {
            element.style.backgroundImage = `url(${url})`;
          });
        }
      })
    }
  }, [credHtml, imageData])

  return credHtml ? (
    <div
      ref={parentDiv}
      id="template-container"
      style={{ pointerEvents: "none" }}
      dangerouslySetInnerHTML={{ __html: credHtml }}
    />
  ) : (
    <Skeleton variant="rectangular" height={300} />
  );
}

import React from "react";
import _ from "lodash";
import { useAxios } from "hooks/useAxios";
import { Box, Fab, Grid, Skeleton, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CredentialCard from "./CredentialCard";
import MySwal from "utils/Swal";
import { customAxios } from "hooks/useAxios";

export default function Credentials({ email, myUpload = false }) {
  const inputRef = React.useRef();

  const [data, isLoading, , refetch] = useAxios(
    myUpload
      ? "credulocker/users/docs/upload"
      : `credulocker/user/docs/${email}`,
    {
      attachCredulockerToken: true,
    },
  );

  const uploadChangeHandler = async (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile.size > 10485760) {
      MySwal.fire({
        title: "File Too Large",
        text: "The file exceeds the 10MB size limit. Please upload a smaller file.",
        icon: "error",
      });
      return;
    }
    MySwal.showLoader("Uploading...");
    const formData = new FormData();
    formData.append("file", selectedFile);
    const response = await customAxios.post(
      "/credulocker/users/docs/upload",
      formData,
      {
        attachCredulockerToken: true,
      },
    );
    if (response.status === 200) {
      refetch();
      MySwal.fire({
        title: "Successfully Uploaded!",
        text: "",
        icon: "success",
        allowEscapeKey: false,
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const cardSize = {
    xs: 12,
    md: 6,
    lg: 4,
  };

  return (
    <Box pb={!isLoading && !data?.response?.length ? 0 : 5}>
      {!isLoading && !data?.response?.length ? (
        ""
      ) : (
        <React.Fragment>
          <Typography variant="h5">
            {myUpload ? "My Uploads" : email}
          </Typography>
          <Grid container spacing={2} pt={2}>
            {isLoading
              ? _.times(3, (i) => (
                  <Grid key={`cred-${i}`} item {...cardSize}>
                    <Skeleton variant="rectangular" width="100%" height={280} />
                  </Grid>
                ))
              : data?.response?.map((cred) => (
                  <Grid key={cred._id} item {...cardSize}>
                    <CredentialCard
                      {...cred}
                      showDelete={myUpload}
                      refetch={refetch}
                    />
                  </Grid>
                ))}
          </Grid>
        </React.Fragment>
      )}
      {myUpload && (
        <React.Fragment>
          <Fab
            variant="extended"
            color="primary"
            sx={{ position: "fixed", bottom: 16, right: 16, zIndex: 999 }}
            onClick={function () {
              inputRef.current?.click();
            }}
          >
            <AddIcon /> Upload
          </Fab>
          <input
            ref={inputRef}
            type="file"
            accept="image/*,application/pdf"
            style={{ display: "none" }}
            onChange={uploadChangeHandler}
          />
        </React.Fragment>
      )}
    </Box>
  );
}

import React from "react";
import { Box } from "@mui/material";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

export default function CustomDatePicker(props) {
  const { label, minDate, value, onChange } = props;

  return (
    <Box sx={{ "& .MuiStack-root": { mt: -1 } }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={["DatePicker"]}>
          <DatePicker
            minDate={minDate === "TODAY" && dayjs()}
            slotProps={{
              textField: {
                size: "small",
                fullWidth: true,
                InputLabelProps: { shrink: true },
              },
              field: { clearable: true },
            }}
            label={label}
            value={value && dayjs(value)}
            onChange={(newValue) =>
              onChange(dayjs(newValue).format("YYYY-MM-DDTHH:mm:ssZ"))
            }
          />
        </DemoContainer>
      </LocalizationProvider>
    </Box>
  );
}

import React from "react";
import { Divider, Box, Grid, Typography, Paper } from "@mui/material";
// import NotificationBrandingBody from "./NotificationBrandingBody";
import AccessDocumentWidgetBody from "./AccessDocumentWidgetBody";
import VerificationWidgetBody from "./VerificationWidgetBody";
import DocumentViewerWidgetBody from "./DocumentViewerWidgetBody";
import AccessYourDocumentWidgetPreview from "assets/images/access_your_document_widget_preview.png";
import DocumentViewerWidgetPreview from "assets/images/document_viewer_widget_preview.png";
import VerificationWidgetPreview from "assets/images/verification_widget_preview.png";

export default function WhiteLabel() {
  const CONTENT = [
    // {
    //   label: "ItsCredible branding in notification email",
    //   description:
    //     "Your logo appears on your issuer page, Coursefinder, and with all published credentials on Itscredible.",
    //   body: <NotificationBrandingBody />,
    //   preview: (
    //     <img
    //       src="https://images.softwaresuggest.com/latest_screenshots/1688713489_Software%20Screenshot.jpg"
    //       width="100%"
    //     />
    //   ),
    // },
    {
      label: "Access Your Document Widget",
      description:
        "Your logo appears on your issuer page, Coursefinder, and with all published credentials on Itscredible.",
      body: <AccessDocumentWidgetBody />,
      preview: (
        <img
          src={AccessYourDocumentWidgetPreview}
          alt="Access Your document Widget"
          width="100%"
        />
      ),
    },
    {
      label: "Verification Widget",
      description:
        "Your logo appears on your issuer page, Coursefinder, and with all published credentials on Itscredible.",
      body: <VerificationWidgetBody />,
      preview: (
        <img
          src={VerificationWidgetPreview}
          alt="Verification Widget"
          width="100%"
        />
      ),
    },
    {
      label: "Document Viewer Widget",
      description:
        "Your logo appears on your issuer page, Coursefinder, and with all published credentials on Itscredible.",
      body: <DocumentViewerWidgetBody />,
      preview: (
        <img
          src={DocumentViewerWidgetPreview}
          alt="Document Viewer Widget"
          width="100%"
        />
      ),
    },
  ];

  return (
    <Box px={2}>
      <Grid container columnSpacing={4} alignItems="flex-start">
        {CONTENT.map((elem) => (
          <>
            <Grid item xs={12} sm={8}>
              <Typography variant="h4">{elem.label}</Typography>
              <Typography variant="body2">{elem.description}</Typography>
              {elem.body}
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Paper variant="outlined">{elem.preview}</Paper>
            </Grid>
            <Grid xs={12}>
              <Divider sx={{ my: 6 }} />
            </Grid>
          </>
        ))}
      </Grid>
    </Box>
  );
}

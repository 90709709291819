import React from "react";
import { Dialog, IconButton, Paper } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Credentials from "./Credentials";
import { useCredulockerAuth } from "hooks/useCredulockerAuth";
import Preview from "components/Preview";
import { useSearchParams } from "react-router-dom";

export default function CredentialsWrapper() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { user } = useCredulockerAuth();
  return (
    <Paper
      variant="outlined"
      minHeight="100dvh"
      id="credulockerWrapper"
      sx={{
        bgcolor: "#f8f9fe",
        borderRadius: "10px",
        overflow: "hidden",
        p: 3,
        minHeight: { sm: "calc(100dvh - 32px)" },
      }}
    >
      <Credentials myUpload />
      {user.email.map((email) => (
        <Credentials key={email} email={email} />
      ))}

      {searchParams.get("preview") ? (
        <Dialog
          fullScreen
          disableEscapeKeyDown
          sx={{ zIndex: 1000 }}
          open
          onClose={() => {
            searchParams.delete("preview");
            setSearchParams(searchParams);
          }}
        >
          <IconButton
            size="small"
            sx={{ position: "absolute", right: "16px", top: "10px" }}
            onClick={() => {
              searchParams.delete("preview");
              setSearchParams(searchParams);
            }}
          >
            <CloseOutlinedIcon />
          </IconButton>
          <Preview
            displayId={searchParams.get("preview")}
            showAddToProfile
            hideVerifyButton
          />
        </Dialog>
      ) : (
        ""
      )}
    </Paper>
  );
}

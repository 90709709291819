import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';

const JsonViewer = ({ jsonData }) => {
  const jsonString = JSON.stringify(jsonData, null, 2);

  return (
    <div>
      <SyntaxHighlighter language="json">
        {jsonString}
      </SyntaxHighlighter>
    </div>
  );
};

export default JsonViewer;

import { Box, IconButton } from '@mui/material'
import React from 'react'
import TextFieldLayout from "components/common/TextfieldLayout";
import Dropzone from "components/common/Dropzone";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

export default function UploadField(props) {
    const { label, value, onChange } = props;

    return (
        <Box sx={{ position: 'relative' }}>
            <TextFieldLayout label={label}>
                {value ?
                    <Box p={1} display="flex" alignItems="center" justifyContent="center" sx={{ height: '80px', cursor: 'pointer' }}>
                        <img
                            style={{ height: '100%', width: '100%', objectFit: 'contain' }}
                            src={value}
                        />
                        <Dropzone
                            accept={{ "image/png": [], "image/jpg": [], "image/jpeg": [] }}
                            onDrop={(files) => onChange(files[0])}
                        >
                            <IconButton variant="outlined" size="small" sx={{ border: '1px solid rgba(0, 0, 0, 0.23)', background: '#fff !important', zIndex: 2, position: 'absolute', top: -16, right: 70 }}>
                                <AddOutlinedIcon sx={{ opacity: 0.7 }} />
                            </IconButton>
                        </Dropzone>
                        <IconButton onClick={() => onChange(null)} variant="outlined" size="small" sx={{ border: '1px solid rgba(0, 0, 0, 0.23)', background: '#fff !important', zIndex: 2, position: 'absolute', top: -16, right: 20 }}>
                            <DeleteOutlineOutlinedIcon sx={{ opacity: 0.7 }} />
                        </IconButton>
                    </Box>
                    :
                    <Dropzone
                        accept={{ "image/png": [], "image/jpg": [], "image/jpeg": [] }}
                        onDrop={(files) => onChange(files[0])}
                    >
                        <Box p={1} display="flex" alignItems="center" justifyContent="center" sx={{ height: '80px', cursor: 'pointer' }}>
                            <AddOutlinedIcon fontSize="large" sx={{ opacity: 0.7 }} />
                        </Box>
                    </Dropzone>
                }
            </TextFieldLayout>
        </Box>
    )
}

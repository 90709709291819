import React, { createContext, useContext } from "react";
import { customAxios } from "./useAxios.js";
import { useSearchParams } from "react-router-dom";
import _ from "lodash";
import { useAuth } from "./useAuth.js";
import { Box, Grid, Paper, Skeleton } from "@mui/material";
import IssuanceLayout from "layouts/IssuanceLayout.js";

const IssuanceContext = createContext();

export const IssuanceDataProvider = (props) => {
  const { user } = useAuth();

  const [searchParams] = useSearchParams();
  const draftId = searchParams.get("draftId");

  const [data, setData] = React.useState();

  const fetchData = async () => {
    const smtpConfig = user?.smtpConfig;
    const lastSavedSmtpConfig = user?.notifyConfig;

    let dataTemp = {
      credentialDetails: {
        title: null,
        expiryDate: null,
        docInfo: user?.contentSettings?.docInfo,
        tags: [],
        skills: [],
        notificationPlatform: {
          email: true,
          whatsapp: false,
        },
        isPrivateDoc: false,
        isNft: false,
      },
      emailSettings: {
        from: {
          name: user.website,
          address:
            smtpConfig?.username || process.env.REACT_APP_NOTIFICATION_EMAIL,
        },
        subject: lastSavedSmtpConfig?.subject,
        body: lastSavedSmtpConfig?.emailContent,
        isBranding: true,
        replyTo: lastSavedSmtpConfig?.replyTo,
      },
      whatsappSettings: {
        message: `Hi {{receiver}}, ${user.companyDisplayName} has issued you a credential using ItsCredible Platform.`,
      },
    };

    const { data: draft } = await customAxios.get(`draft/${draftId}`);
    dataTemp = _.merge(dataTemp, draft);

    if (draft?.templateId) {
      const { data: template } = await customAxios.get(
        `template/${draft.templateId}`,
      );
      dataTemp.template = template?.response;
    } else if (draft?.uploadedDoc?.ids?.length) {
      const ids = draft?.uploadedDoc?.ids;

      const uploadedCreds = await customAxios.post("getSignedDocs", {
        searchText: { _id: ids },
      });

      const uploadedCredsRes = uploadedCreds?.data?.response || [];

      const creds = uploadedCredsRes.map((cred) => ({
        path: cred.fileName,
        _id: cred._id,
        status: cred.status,
        type: "pdf",
        fileS3Key: cred?.fileS3Key,
      }));

      dataTemp.uploadedDoc.creds = creds;
      if (!dataTemp.recipients.csvData.length) {
        dataTemp.recipients.csvData = uploadedCredsRes.map((cred) => ({
          "File Name": cred.fileName,
          _id: cred._id,
        }));
      }
    }

    if (!dataTemp?.credentialDetails?.title && dataTemp?.template?.name) {
      dataTemp.credentialDetails.title = dataTemp.template.name;
      await customAxios.put(`draft/${draftId}`, {
        draft: {
          credentialDetails: { title: dataTemp.credentialDetails.title },
        },
      });
    }

    setData((prevState) => ({ ...prevState, ...dataTemp }));
  };

  React.useEffect(() => {
    if (draftId && !data) {
      fetchData();
    }
  }, [draftId]);

  const setIssuanceData = async (reqObj, callback) => {
    const dataTemp = _.merge(data, reqObj);
    setData({ ...dataTemp });
    draftId && (await customAxios.put(`draft/${draftId}`, { draft: dataTemp }));
    typeof callback === "function" && callback();
  };

  return data ? (
    <IssuanceContext.Provider
      value={{ issuanceData: { ...data }, setIssuanceData }}
    >
      {props.children}
    </IssuanceContext.Provider>
  ) : (
    <IssuanceLayout>
      <Grid container sx={{ height: "100%" }}>
        <Grid
          item
          xs={0}
          sm={4}
          sx={{ backgroundColor: "#f4f2f0", height: "100%" }}
        >
          <Box p={2}>
            <Skeleton variant="rectangular" height={300} />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={8}
          component={Paper}
          elevation={0}
          square={true}
          sx={{ height: "100%", overflow: "auto", p: 2 }}
        ></Grid>
      </Grid>
    </IssuanceLayout>
  );
};

export const useIssuanceDataProvider = () => {
  return useContext(IssuanceContext);
};

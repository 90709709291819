import React from "react";
import { Grid } from "@mui/material";
import Profile from "components/Credulocker/Profile";
import CredentialsWrapper from "components/Credulocker/CredentialsWrapper";

export default function Dashboard() {
  return (
    <Grid container spacing={2} p={2}>
      <Grid item xs={12} sm={4} md={3.5} lg={3}>
        <Profile />
      </Grid>
      <Grid item xs={12} sm={8} md={8.5} lg={9}>
        <CredentialsWrapper />
      </Grid>
    </Grid>
  );
}

import React from "react";
import { useSearchParams } from "react-router-dom";
import { Stack, Typography } from "@mui/material";
import validator from "validator";
import { customAxios } from "hooks/useAxios";
import AddEmailForm from "./AddEmailForm";
import VerifyOtp from "./VerifyOtp";
import { useCredulockerAuth } from "hooks/useCredulockerAuth";
import MySwal from "utils/Swal";

export default function Login() {
  const [searchParams] = useSearchParams();
  const { reloadUser } = useCredulockerAuth();
  const [email, setEmail] = React.useState(searchParams.get("email"));
  const [otp, setOtp] = React.useState("");
  const [step, setStep] = React.useState(1);
  const [isSending, setIsSending] = React.useState(false);
  const [isVerifying, setIsVerifying] = React.useState(false);

  const generateOtp = async (e) => {
    e.preventDefault();
    if (!validator.isEmail(email) && !validator.isMobilePhone(email)) {
      MySwal.fire({
        text: "Invalid Email Address / WhatsApp Number",
        icon: "error",
      });
      return;
    }
    setIsSending(true);
    const response = await customAxios.post(
      "otp",
      {
        loginId: email,
        config: {
          sendVia: [
            {
              deliveryMethod: validator.isMobilePhone(email)
                ? "whatsapp"
                : "email",
              deliveryAddress: email,
            },
          ],
        },
        firstTime: true,
      },
      { attachCredulockerToken: true },
    );

    if (response?.status === 200) {
      setStep(2);
    }
    setIsSending(false);
  };

  const verifyOtp = async (e) => {
    e.preventDefault();
    setIsVerifying(true);
    const response = await customAxios.post(
      "otp/verify",
      {
        loginId: email,
        otp,
        firstTime: true,
      },
      { attachCredulockerToken: true },
    );

    if (response?.status === 200) {
      localStorage.setItem("cjwt", response?.data?.value?.token);
      reloadUser();
    }
    setIsVerifying(false);
  };

  const getForm = () => {
    if (step === 1) {
      return (
        <AddEmailForm
          {...{ email, setEmail, handleSubmit: generateOtp, isSending }}
        />
      );
    } else {
      return (
        <VerifyOtp
          {...{
            email,
            otp,
            setOtp,
            handleBack: () => setStep(1),
            handleResend: generateOtp,
            handleSubmit: verifyOtp,
            isSending,
            isVerifying,
          }}
        />
      );
    }
  };

  return (
    <Stack px={4} py={6} spacing={4} alignItems="center">
      <Typography variant="h1">Access Your Documents</Typography>
      {getForm()}
    </Stack>
  );
}

import React from "react";
import { Typography, Stack, Skeleton } from "@mui/material";
import {
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  XIcon,
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import _ from "lodash";
import Helpers from "utils/commonHelpers";
import { customAxios } from "hooks/useAxios";

const SOCIAL_SHARE_PLATFORMS = [
  {
    button: LinkedinShareButton,
    icon: LinkedinIcon,
    platformName: "LINKEDIN",
  },
  {
    button: TwitterShareButton,
    icon: XIcon,
    platformName: "TWITTER",
  },
  {
    button: FacebookShareButton,
    icon: FacebookIcon,
    platformName: "FACEBOOK",
  },
  {
    button: WhatsappShareButton,
    icon: WhatsappIcon,
    platformName: "WHATSAPP",
  },
];

const DEFAULT_TITLE =
  "Hey everyone! Glad to share this latest accomplishment with you all, powered by ItsCredible. Every step forward is a win. Cheers to celebrating success together! #Itscredible #ClickCelebrateShare #CredentialsForLife #SuccessStory";

export default function SocialShare(props) {
  const { isLoading, displayId, userId } = props;

  const getSharableUrl = ({ platformName }) => {
    return `https://portal.itscredible.com/api/v1/social/share/${displayId}/${userId}/${platformName}/${Helpers.randomString(
      12,
    )}`;
  };

  const updateShareAnalytics = async (platformName) => {
    await customAxios.patch(`docs/analytics/${displayId}`, {
      update: {
        $inc: { [`analytics.share.${platformName.toLowerCase()}`]: 1 },
      },
    });
  };

  return (
    <Stack spacing={1}>
      <Typography variant="caption">Share your Achievement!</Typography>
      <Stack direction="row" spacing={1.5}>
        {isLoading
          ? _.times(4, (i) => (
              <Skeleton key={i} variant="rectangular" width={32} height={32} />
            ))
          : SOCIAL_SHARE_PLATFORMS.map((SocialPlatform) => (
              <SocialPlatform.button
                key={SocialPlatform.platformName}
                onClick={(e) => {
                  e.stopPropagation();
                  updateShareAnalytics(SocialPlatform.platformName);
                }}
                url={getSharableUrl({
                  platformName: SocialPlatform.platformName,
                })}
                title={DEFAULT_TITLE}
              >
                <SocialPlatform.icon size="32" borderRadius="5" />
              </SocialPlatform.button>
            ))}
      </Stack>
    </Stack>
  );
}

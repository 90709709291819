import React from "react";
import {
  Grid,
  Paper,
  Box,
  Stack,
  Typography,
  SwipeableDrawer,
  IconButton,
  Divider,
  Chip,
} from "@mui/material";
import LivePreview from "components/issuance/LivePreview";
import { useParams } from "react-router-dom";
import Carousel from "components/common/carousel";
import moment from "moment";
import useMediaQuery from "@mui/material/useMediaQuery";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { useIssuanceDataProvider } from "hooks/useIssuanceDataProvider";
import { useTour } from "hooks/useTour";
import { customAxios } from "hooks/useAxios";

// Extract LivePreviewWrapper as a named functional component
const LivePreviewWrapper = ({
  issuanceType,
  template,
  credentialsData,
  issuanceData,
  templateHtml,
  setTemplateHtml,
}) => {
  const { setTourStepIdx } = useTour();

  React.useEffect(() => {
    setTourStepIdx((prevState) => prevState + 1);
  }, []);

  const fetchTemplateHtml = async () => {
    if (template?._id && !templateHtml) {
      const response = await customAxios.get(`/template/file/${template?._id}`);
      response && setTemplateHtml(response?.data?.response);
    }
  };

  React.useEffect(() => {
    fetchTemplateHtml();
  }, [template?._id]);

  return (
    <Box
      p={2}
      sx={{
        height: "100%",
        overflow: "auto",
      }}
      data-tut="CredentialLivePreview"
    >
      <Stack spacing={2}>
        {issuanceType === "create" ? (
          template?.templateType === "DOCX" ? (
            <img
              src={template?.thumb?.large}
              style={{ width: "100%" }}
              alt="Template Thumbnail"
            />
          ) : (
            <Carousel
              slides={(credentialsData?.length ? credentialsData : [{}]).map(
                (rowData) => (
                  <LivePreview
                    data={rowData}
                    imageData={issuanceData?.credentialDetails?.imageData}
                    key={rowData}
                    {...{ templateHtml }}
                  />
                ),
              )}
            />
          )
        ) : (
          <Box textAlign="center">
            <DescriptionOutlinedIcon
              sx={{ fontSize: "20vw", color: "border.main" }}
            />
          </Box>
        )}
        <Typography variant="body1">
          <div
            dangerouslySetInnerHTML={{
              __html: issuanceData?.credentialDetails?.docInfo,
            }}
          />
        </Typography>
        <Stack direction="row" spacing={4}>
          <Stack>
            <Typography variant="caption">Issued Date:</Typography>
            <Typography variant="body1">
              {moment().format("DD-MM-YYYY")}
            </Typography>
          </Stack>
          {issuanceData?.credentialDetails?.expiryDate ? (
            <Stack>
              <Typography variant="caption">Expiry Date:</Typography>
              <Typography variant="body1">
                {moment(issuanceData?.credentialDetails?.expiryDate).format(
                  "DD-MM-YYYY",
                )}
              </Typography>
            </Stack>
          ) : null}
        </Stack>
        {issuanceData?.credentialDetails?.skills?.length ? (
          <Box>
            <Typography variant="caption">Skills: </Typography>
            <Box direction="row" alignItems="center" flexWrap="wrap">
              {issuanceData.credentialDetails.skills.map((skill) => (
                <Chip key={skill} label={skill} sx={{ mt: 1.5, mr: 1.5 }} />
              ))}
            </Box>
          </Box>
        ) : null}
      </Stack>
    </Box>
  );
};

export default function ParallelSection(props) {
  const { issuanceData: initialIssuanceData } = useIssuanceDataProvider();
  const issuanceData = { ...initialIssuanceData, ...(props?.data || {}) };
  const { issuanceType } = useParams();
  const { drawer, setDrawer } = props;
  const [templateHtml, setTemplateHtml] = React.useState();
  const isMobile = useMediaQuery("(max-width:600px)");
  const template = issuanceData?.template;

  const credentialsData = issuanceData?.recipients?.csvData?.filter(
    (obj) => Object.keys(obj).length > 0,
  );

  return (
    <>
      <Grid container sx={{ height: "100%" }}>
        <Grid
          item
          xs={0}
          sm={4}
          sx={{ backgroundColor: "#f4f2f0", height: "100%" }}
        >
          {isMobile ? (
            <SwipeableDrawer
              open={drawer}
              onClose={() => setDrawer(false)}
              onOpen={() => setDrawer(true)}
              sx={{ ".MuiDrawer-paper": { width: "100%" } }}
            >
              <Box p={1} display="flex">
                <IconButton onClick={() => setDrawer(false)}>
                  <ArrowBackIcon />
                </IconButton>
              </Box>
              <Divider />
              <LivePreviewWrapper
                issuanceType={issuanceType}
                template={template}
                credentialsData={credentialsData}
                issuanceData={issuanceData}
                templateHtml={templateHtml}
                setTemplateHtml={setTemplateHtml}
              />
            </SwipeableDrawer>
          ) : (
            <LivePreviewWrapper
              issuanceType={issuanceType}
              template={template}
              credentialsData={credentialsData}
              issuanceData={issuanceData}
              templateHtml={templateHtml}
              setTemplateHtml={setTemplateHtml}
            />
          )}
        </Grid>
        <Grid
          item
          xs={12}
          sm={8}
          component={Paper}
          elevation={0}
          square={true}
          sx={{ height: "100%", overflow: "auto", p: 2 }}
        >
          {props.children}
        </Grid>
      </Grid>
    </>
  );
}

import React from "react";
import { Box, Stack, Skeleton } from "@mui/material";
import _ from "lodash";
import useResponsive from "hooks/useResponsive";

export default function TableSkeleton(props) {
  const { compactActionIcons } = props;
  const { isTablet, isDesktop } = useResponsive();
  return (
    <>
      <Box
        pt={0.5}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack direction="row" spacing={2}>
          {_.times(
            (isDesktop || isTablet) && !compactActionIcons ? 6 : 1,
            (i) => (
              <Skeleton key={i} variant="circular" width={32} height={32} />
            ),
          )}
        </Stack>
        <Stack direction="row" spacing={2.5}>
          <Stack direction="row" spacing={5}>
            {(isDesktop || isTablet) && (
              <Skeleton variant="rectangular" width={130} height={32} />
            )}
            <Skeleton variant="text" width={66} />
          </Stack>
          <Stack direction="row" spacing={1}>
            <Skeleton variant="circular" width={32} height={32} />
            <Skeleton variant="circular" width={32} height={32} />
          </Stack>
        </Stack>
      </Box>
      <Stack direction="column" spacing={1.5} mt={4}>
        {_.times(15, (i) => (
          <Skeleton key={`table-row-${i}`} variant="rectangular" height={30} />
        ))}
      </Stack>
    </>
  );
}

import React from "react";
import ReactJoyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import Steps from "constants/english/user-guide-steps";
import { useTheme } from "@mui/material/styles";
import { useTour } from "hooks/useTour";
import { customAxios } from "hooks/useAxios";
import { useNavigate } from "react-router-dom";
import { useAuth } from "hooks/useAuth";
import useResponsive from "hooks/useResponsive";

export default function Joyride() {
  const { user } = useAuth();
  const theme = useTheme();
  const { isMobile } = useResponsive();
  const navigate = useNavigate();
  const { tourStepIdx, setTourStepIdx, showTour, setShowTour } = useTour();

  React.useEffect(() => {
    if (!user?.appTourCompleted) {
      setShowTour(true);
    }
  }, []);

  const handleJoyrideCallback = async (data) => {
    const { action, index, status, type, step } = data;
    if (status === STATUS.FINISHED || action === ACTIONS.SKIP) {
      setTourStepIdx(0);
      setShowTour(false);
      await customAxios.put("profile/updateUserProfile", {
        query: {},
        data: { appTourCompleted: true },
      });
    } else if (action === ACTIONS.CLOSE) {
      setTourStepIdx(0);
      setShowTour(false);
    } else if (action === ACTIONS.PREV) {
      if (step?.data?.previous) {
        navigate(step.data.previous);
      }
    } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      setTourStepIdx(nextStepIndex);
    }
  };

  return (
    <ReactJoyride
      run={!isMobile && showTour}
      steps={Steps()}
      stepIndex={tourStepIdx}
      callback={handleJoyrideCallback}
      continuous
      locale={{ last: "Finish", skip: "Don't show me again" }}
      scrollToSteps
      showSkipButton
      hideBackButton={true}
      disableScrolling
      disableCloseOnEsc
      disableOverlayClose
      disableScrollParentFix
      spotlightPadding={0}
      styles={{
        options: {
          primaryColor: theme.palette.primary.main,
        },
      }}
    />
  );
}

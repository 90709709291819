import React from "react";
import Table from "components/Table";
import { useAxios } from "hooks/useAxios";
import ActionBar from "components/RecipientsTable/ActionBar";
import moment from "moment";
import { createColumnHelper } from "@tanstack/react-table";
import { Chip, Typography } from "@mui/material";
import TableSkeleton from "components/common/TableSkeleton";

const columnHelper = createColumnHelper();

const columnDefinitions = [
  columnHelper.accessor((row) => row.sharedWith.email, {
    id: "email",
    header: () => "Email Address",
    cell: (info) => (
      <Typography
        variant="body1"
        noWrap
        sx={{ minWidth: "200px", width: "30vw" }}
      >
        {info.getValue() || "-"}
      </Typography>
    ),
  }),
  columnHelper.accessor((row) => row?.sharedWith?.whatsappNumber, {
    id: "whatsAppNumber",
    header: () => "Whatsapp Number",
    cell: (info) => info.getValue() || "-",
  }),
  columnHelper.accessor((row) => row.metaData.mappedName, {
    id: "name",
    header: () => "Recipient Name",
    cell: (info) => info.getValue() || "-",
  }),
  columnHelper.accessor((row) => row.signer.signedOn, {
    id: "last-issued",
    meta: {
      filterVariant: "date-range",
    },
    filterFn: "inDateRange",
    header: () => "Last Issued",
    cell: (info) => moment(info.getValue()).format("DD-MM-YYYY"),
  }),
  columnHelper.accessor((row) => row?.profile?.[0]?.email || "", {
    id: "contact-owner",
    header: () => "Contact Owner",
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor((row) => row.skills, {
    id: "skill",
    meta: {
      filterVariant: "multi-select",
    },
    filterFn: "arrIncludesSome",
    header: () => "Skills",
    cell: (info) =>
      info.getValue()?.length
        ? info
            .getValue()
            .map((skill) => <Chip key={skill} label={skill} sx={{ mx: 0.5 }} />)
        : "-",
  }),
];
export default function RecipientsTable(props) {
  const { onImport, tableStyle, callback, isSeeAll, setIsSeeAll } = props;
  const [recipientData, isLoading, , fetchDocs] = useAxios(
    `importedRecords?isSeeAll=${isSeeAll}`,
  );

  return (
    <>
      {isLoading ? (
        <TableSkeleton compactActionIcons />
      ) : (
        <Table
          columnDefinitions={columnDefinitions}
          data={recipientData?.response?.filter(
            ({ sharedWith: { email } }, index, self) =>
              self.findIndex((d) => d.sharedWith.email === email) === index,
          )}
          tableStyle={tableStyle}
          actionBar={(table) => (
            <ActionBar
              {...{
                table,
                onImport,
                fetchDocs,
                isSeeAll,
                setIsSeeAll,
                callback,
              }}
            />
          )}
        />
      )}
    </>
  );
}

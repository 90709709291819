import React from "react";
import AuthLayout from "layouts/AuthLayout";
import { TextField, Stack, Link, Button, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import Form from "components/auth/Form";
import { customAxios } from "hooks/useAxios.js";
import { useNavigate, useLocation } from "react-router-dom";
import PasswordInput from "components/common/PasswordInput";

export default function Login() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = React.useState(false);
  const [formData, setFormData] = React.useState({
    email: null,
    password: null,
  });

  const handleSubmit = async () => {
    setIsLoading(true);
    const response = await customAxios.get("login", {
      headers: {
        authorization: `Basic ${formData.email}:${formData.password}`,
      },
    });
    const accessToken = response?.data?.response?.accessToken;
    if (accessToken) {
      localStorage.setItem("jwt", accessToken);

      if (location?.state?.redirectTo) {
        navigate(location.state.redirectTo);
      } else {
        navigate(`${process.env.REACT_APP_BASE_ROUTE}/user/home/?filter=SENT`);
      }
    } else {
      // Must be handle by axios global catch.
      console.log(response);
    }
    setIsLoading(false);
  };

  return (
    <AuthLayout>
      <Form
        onSubmit={handleSubmit}
        heading="Log In to Issue Credentials"
        field1={
          <TextField
            type="email"
            required
            label="Email Address"
            value={formData.email}
            onChange={(e) =>
              setFormData((prevState) => ({
                ...prevState,
                email: e.target.value,
              }))
            }
          />
        }
        field2={
          <Stack spacing={2}>
            <PasswordInput
              label="Password"
              required
              value={formData.password}
              onChange={(e) =>
                setFormData((prevState) => ({
                  ...prevState,
                  password: e.target.value,
                }))
              }
            />
            <Typography variant="body2">
              <Stack direction="row" justifyContent="flex-end">
                <Link
                  component={RouterLink}
                  to={`${process.env.REACT_APP_BASE_ROUTE}/auth/forgotPassword/step1`}
                >
                  Forgot Password?
                </Link>
              </Stack>
            </Typography>
          </Stack>
        }
        primaryAction={
          <Button
            variant="contained"
            size="large"
            type="submit"
            disableElevation
            disabled={isLoading}
          >
            {isLoading ? "Logging In..." : "Login"}
          </Button>
        }
        secondaryAction={
          <Typography variant="body2" align="center">
            Don&#39;t have an account?&nbsp;
            <Link
              component={RouterLink}
              to={`${process.env.REACT_APP_BASE_ROUTE}/auth/register/step1`}
            >
              Register
            </Link>
          </Typography>
        }
      />
    </AuthLayout>
  );
}

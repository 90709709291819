import React from "react";
import AuthLayout from "layouts/AuthLayout";
import { TextField, Stack, Link, Typography, Button } from "@mui/material";
import Form from "components/auth/Form";
import { Link as RouterLink, useNavigate, useLocation } from "react-router-dom";
import { customAxios } from "hooks/useAxios.js";

export default function RegisterStep1() {
  const [isLoading, setIsLoading] = React.useState(false);
  const [otp, setOtp] = React.useState();
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    if (!location.state?.registrationData) {
      navigate(`${process.env.REACT_APP_BASE_ROUTE}/auth/register/step1`);
    }
  }, []);

  const doLogin = async ({ email, password }) => {
    const response = await customAxios.get("login", {
      headers: {
        authorization: `Basic ${email}:${password}`,
      },
    });

    const accessToken = response?.data?.response?.accessToken;

    if (accessToken) {
      window.localStorage.setItem("jwt", accessToken);
      navigate(`${process.env.REACT_APP_BASE_ROUTE}/user/home/?filter=SENT`, {
        state: {},
      });
      return;
    } else {
      console.log(response);
    }
  };

  const handleRegister = async () => {
    setIsLoading(true);
    const response = await customAxios.post("confirmUser", {
      userData: location.state.registrationData,
      email: location.state.registrationData.email,
      code: otp,
    });
    response &&
      (await doLogin({
        email: location.state.registrationData.email,
        password: location.state.registrationData.password,
      }));
    setIsLoading(false);
  };

  const handleResend = async (e) => {
    e.target.innerText = "Sending...";

    const response = await customAxios.post("register/resendCode", {
      email: location.state.registrationData.email,
    });

    if (response?.data?.response) {
      e.target.innerText = "Sent!";
      setTimeout(() => {
        e.target.innerText = "Resend";
      }, 5000);
    } else {
      e.target.innerText = "Resend";
      console.log(response);
    }
  };

  return (
    <AuthLayout>
      <Form
        onSubmit={handleRegister}
        heading="Email Verification"
        field1={
          <Typography variant="body1" align="center">
            An Email with 6 digit OTP was just sent to{" "}
            <b>{location.state?.registrationData?.email}</b>
          </Typography>
        }
        field2={
          <Stack spacing={2} alignItems="center">
            <TextField
              label="Enter the OTP"
              variant="outlined"
              fullWidth
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              required
            />
            <Typography variant="body2" component="i">
              Didnt receive the OTP?{" "}
              <Link sx={{ cursor: "pointer" }} onClick={handleResend}>
                Resend OTP
              </Link>
            </Typography>
          </Stack>
        }
        primaryAction={
          <Button
            variant="contained"
            size="large"
            disabled={isLoading || !otp}
            onClick={handleRegister}
          >
            Submit
          </Button>
        }
        secondaryAction={
          <Link
            component={RouterLink}
            sx={{ alignSelf: "center" }}
            to={`${process.env.REACT_APP_BASE_ROUTE}/auth/register/step1`}
            state={location.state}
          >
            {"< Back"}
          </Link>
        }
      />
    </AuthLayout>
  );
}

import React from "react";
import { Box, Paper, Stack, IconButton, Typography, Link } from "@mui/material";
import PaletteOutlinedIcon from "@mui/icons-material/PaletteOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

export default function OldUiPopover() {
  const [isVisible, setIsVisible] = React.useState(
    (localStorage.getItem("UiPopoverCount") || 0) <= 3,
  );

  const toggleVisibility = () => {
    if (isVisible) {
      localStorage.setItem(
        "UiPopoverCount",
        Number(localStorage.getItem("UiPopoverCount") || 0) + 1,
      );
    }
    setIsVisible((prevState) => !prevState);
  };

  return (
    <Box style={{ position: "relative" }}>
      <IconButton
        onClick={toggleVisibility}
        sx={
          isVisible
            ? { boxShadow: "inset 0 0 200px 0 rgba(13,152,186,.1)" }
            : {}
        }
      >
        <PaletteOutlinedIcon />
      </IconButton>
      {isVisible && (
        <Paper
          elevation={4}
          style={{
            position: "absolute",
            top: "100%",
            left: "20px",
            transform: "translateX(-50%)",
          }}
        >
          <Box
            p={2}
            display="flex"
            alignItems="flex-start"
            width="340px"
            justifyContent="space-between"
            style={{ maxWidth: "80vw" }}
          >
            <Stack direction="row" spacing={1}>
              <span>🎉</span>
              <Stack spacing={1}>
                <Typography variant="h5">
                  {"You're currently experiencing the new UI."}
                </Typography>
                <Typography variant="caption">
                  You can{" "}
                  <Link
                    href={`https://${
                      window.location.host.split(".")[0].toLowerCase() ===
                      "portal"
                        ? "legacy"
                        : "dev1"
                    }.itscredible.com`}
                  >
                    click here
                  </Link>{" "}
                  to switch to the old UI.
                </Typography>
              </Stack>
            </Stack>
            <IconButton onClick={toggleVisibility} sx={{ m: -1, ml: 0 }}>
              <CloseOutlinedIcon />
            </IconButton>
          </Box>
        </Paper>
      )}
    </Box>
  );
}

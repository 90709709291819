import React from "react";
import { customAxios } from "hooks/useAxios.js";
import EditableCell from "./EditaleCell";
import validator from "validator";

export default function EditableEmailCell(props) {
  const { row, table } = props;

  const handleSave = async ({ docId, value }) => {
    const response = await customAxios.patch(`signedDocs/whatsapp/${docId}`, {
      whatsappNumber: value,
    });
    if (response) {
      const newRow = {
        ...row.original,
        sharedWith: {
          ...row.sharedWith,
          whatsappNumber: value
        }
      }
      table.options.meta?.updateData(row.index, newRow);
    }
  };

  return (
    <EditableCell
      {...props}
      onSave={handleSave}
      validationFunc={validator.isMobilePhone}
      placeholder="Add WhatsApp Number"
    />
  );
}

import React from "react";
import { IconButton } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

export default function CopyButton(props) {
  const { value } = props;
  return (
    <IconButton onClick={() => navigator.clipboard.writeText(value)} {...props}>
      <ContentCopyIcon fontSize="small" />
    </IconButton>
  );
}

import React from "react";
import { Box, Stack, Switch, Typography } from "@mui/material";
import { useAuth } from "hooks/useAuth";
import { customAxios } from "hooks/useAxios";

const General = () => {
  const { user, reloadUser } = useAuth();

  const handleToggle = async (e) => {
    try {
      const { checked } = e.currentTarget;
      const response = await customAxios.post(
        "company/updateCompanyConfiguration",
        {
          updatedConfig: {
            issueNft: checked,
          },
        },
      );
      if (response) {
        reloadUser();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Stack py={3} spacing={3}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Typography variant="h6">NFT</Typography>
          <Typography variant="body2">
            Enhance trust and combat fraud by securely recording credential
            issuances in a tamper-proof digital ledger
          </Typography>
        </Box>
        <Switch checked={user?.config?.issueNft} onChange={handleToggle} />
      </Box>
    </Stack>
  );
};

export default General;

import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation, Mousewheel } from "swiper/modules";
import useResponsive from "hooks/useResponsive";

export default function Carousel(props) {
  const { isMobile } = useResponsive();
  const { slides, slidesPerView } = props;

  const enableLazyLoad = slidesPerView !== "auto";

  const batchSize = enableLazyLoad ? 10 : slides.length; // Number of slides to load at a time
  const [visibleSlides, setVisibleSlides] = useState([]);
  const [loadedIndex, setLoadedIndex] = useState(batchSize);

  useEffect(() => {
    // Load the initial batch of slides
    setVisibleSlides(slides.slice(0, batchSize));
  }, [slides]);

  const loadMoreSlides = () => {
    // Load more slides if available
    const newIndex = loadedIndex + batchSize;
    setVisibleSlides((prev) => [
      ...prev,
      ...slides.slice(loadedIndex, newIndex),
    ]);
    setLoadedIndex(newIndex);
  };

  return (
    <>
      <Swiper
        pagination={false}
        navigation={true}
        modules={[Pagination, Navigation, Mousewheel]}
        className="mySwiper"
        mousewheel={{ forceToAxis: true }}
        spaceBetween={slidesPerView === 1 ? 0 : 20}
        slidesPerView={slidesPerView || 1}
        centeredSlides={isMobile}
        onSlideChange={(swiper) => {
          // Load more slides when reaching the end of the current batch
          if (
            enableLazyLoad &&
            swiper.activeIndex >= visibleSlides.length - 1
          ) {
            loadMoreSlides();
          }
        }}
      >
        {visibleSlides.map((slide, index) => (
          <SwiperSlide key={index}>{slide}</SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}

import React from "react";
import { Stack, IconButton, Tooltip } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { customAxios } from "hooks/useAxios.js";
import CustomPopover from "components/common/customPopover";
import MySwal from "utils/Swal";
import EditDocumentForm from "../CredentialsTable/EditDocumentForm";
import CustomDialog from "components/common/CustomDialog";
import useResponsive from "hooks/useResponsive";
// import LabelOffOutlinedIcon from "@mui/icons-material/LabelOffOutlined";
// import LabelOutlinedIcon from "@mui/icons-material/LabelOutlined";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
// import { useAuth } from "hooks/useAuth";

export default function ActionBar(props) {
  const { table, fetchDocs, credentialsReady } = props;
  const { isDesktop, isTablet } = useResponsive();
  // const { user } = useAuth();
  // const tags = Array.from(new Set(user?.tags || []));

  const handleDelete = async () => {
    const selectedRows = table.getSelectedRowModel().rows;
    const ids = selectedRows.map((row) => row.original._id);

    if (ids.length) {
      const { isConfirmed } = await MySwal.fire({
        title: "Are you sure?",
        text: "Do you want to delete the selected documents?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      });

      if (isConfirmed) {
        const response = await customAxios.delete(`signedDocs`, {
          data: {
            documentIds: ids,
            docTag: "vm_removed_from_all",
          },
        });

        if (response) {
          fetchDocs();
          table.resetRowSelection();

          MySwal.fire({
            title: "Document(s) Deleted!",
            text: "The selected Document(s) has been deleted!",
            icon: "success",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
    } else {
      MySwal.fire({
        title: "No Documents Selected",
        text: "Please select documents to perform delete action.",
        icon: "info",
      });
    }
  };

  const noSelectionMsg = () => {
    MySwal.fire({
      title: "No Documents Selected",
      text: "Please select documents to perform action.",
      icon: "info",
    });
  };

  // const removeFromLabel = async (filter) => {
  //   const selectedRows = table.getSelectedRowModel().rows;
  //   const docIds = selectedRows.map(({ original }) => original._id);
  //   const isEmpty = !docIds.length;
  //   if (isEmpty) {
  //     MySwal.fire({
  //       title: "No Documents Selected",
  //       text: "Please select documents to remove from label.",
  //       icon: "info",
  //     });
  //     return;
  //   }
  //   const response = await customAxios.post(
  //     "signedDocs/removeTagFromDocRouter",
  //     {
  //       tag: filter,
  //       docIds,
  //     },
  //   );
  //   if (response) {
  //     fetchDocs();
  //     table.resetRowSelection();

  //     MySwal.fire({
  //       title: "Document(s) removed!",
  //       text: "The selected Document(s) has been removed from label!",
  //       icon: "success",
  //       showConfirmButton: false,
  //       timer: 2000,
  //     });
  //   }
  // };

  // const addToLabel = async (tag) => {
  //   const selectedRows = table.getSelectedRowModel().rows;
  //   const docIds = selectedRows.map(({ original }) => original._id);
  //   const isEmpty = !docIds.length;
  //   if (isEmpty) {
  //     MySwal.fire({
  //       title: "No Documents Selected",
  //       text: "Please select documents to add label.",
  //       icon: "info",
  //     });
  //     return;
  //   }
  //   const response = await customAxios.post(
  //     "signedDocs/addTagToDocumentRouter",
  //     {
  //       tag: tag,
  //       docIds,
  //     },
  //   );
  //   if (response) {
  //     fetchDocs();
  //     table.resetRowSelection();

  //     MySwal.fire({
  //       title: "Label attached!",
  //       text: "The selected Document(s) has been added to the label!",
  //       icon: "success",
  //       showConfirmButton: false,
  //       timer: 2000,
  //     });
  //   }
  // };

  const downloadCreds = async () => {
    const selectedRows = table.getSelectedRowModel().rows;
    const docIds = selectedRows.map(({ original }) => original._id);
    const isEmpty = !docIds.length;
    if (isEmpty) {
      MySwal.fire({
        title: "No Documents Selected",
        text: "Please select documents to download.",
        icon: "info",
      });
      return;
    }
    MySwal.showLoader("Preparing to download...");
    const response = await customAxios.post(
      "signedDocs/download_zip",
      {
        docIds,
      },
      { responseType: "blob" },
    );
    if (response) {
      const url = URL.createObjectURL(response.data);
      const a = document.createElement("a");
      a.href = url;
      a.download = "download.zip";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      table.resetRowSelection();

      MySwal.fire({
        title: "Download started!",
        text: "Download for the selected document(s) has been started!",
        icon: "success",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  const actionButtons = () => {
    return (
      <>
        <Tooltip title="Reload">
          <IconButton onClick={fetchDocs}>
            <RefreshIcon />
          </IconButton>
        </Tooltip>
        {/* <CustomPopover
          AnchorComponent={(syntax) => (
            <Tooltip title="Add Label">
              <IconButton {...syntax}>
                <LabelOutlinedIcon />
              </IconButton>
            </Tooltip>
          )}
          PopoverComponent={(syntax) => (
            <MenuList {...syntax}>
              {tags.map(
                (tag) =>
                  tag !== filter && (
                    <MenuItem
                      key={tag}
                      onClick={() => {
                        syntax?.handleClose();
                        addToLabel(tag);
                      }}
                    >
                      {tag}
                    </MenuItem>
                  ),
              )}
            </MenuList>
          )}
        /> */}
        {/* <Tooltip title="Remove Label">
          <IconButton
            onClick={
              table.getSelectedRowModel().rows.length
                ? removeFromLabel
                : noSelectionMsg
            }
          >
            <LabelOffOutlinedIcon />
          </IconButton>
        </Tooltip> */}
        <Tooltip title="Download">
          <IconButton
          disabled={!credentialsReady}
            onClick={
              table.getSelectedRowModel().rows.length
                ? () => downloadCreds()
                : noSelectionMsg
            }
          >
            <DownloadOutlinedIcon />
          </IconButton>
        </Tooltip>
        <CustomDialog
          AnchorComponent={({ onClick }) => (
            <Tooltip title="Edit Details">
              <IconButton
                onClick={
                  table.getSelectedRowModel().rows.length
                    ? onClick
                    : noSelectionMsg
                }
              >
                <EditOutlinedIcon />
              </IconButton>
            </Tooltip>
          )}
          title="Edit Document"
          Content={(dialogProps) => (
            <EditDocumentForm
              {...dialogProps}
              docIds={table
                .getSelectedRowModel()
                .rows.map(({ original }) => original._id)}
              initialValues={
                table.getSelectedRowModel().rows.length === 1 && {
                  expiryDate:
                    table.getSelectedRowModel().rows[0]?.original?.docInfo
                      ?.expiryDate,
                  text: table.getSelectedRowModel().rows[0]?.original?.docInfo
                    ?.text,
                  skills: table.getSelectedRowModel().rows[0]?.original?.skills,
                }
              }
              callback={fetchDocs}
            />
          )}
          closeOnOutsideClick={false}
        />
        <Tooltip title="Delete">
          <IconButton onClick={handleDelete}>
            <DeleteOutlineIcon />
          </IconButton>
        </Tooltip>
      </>
    );
  };

  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      width="100%"
      disableEnforceFocus
    >
      {isDesktop || isTablet ? (
        actionButtons()
      ) : (
        <CustomPopover
          AnchorComponent={(syntax) => (
            <Tooltip title="More">
              <IconButton {...syntax}>
                <MoreVertIcon />
              </IconButton>
            </Tooltip>
          )}
          PopoverComponent={(syntax) => (
            <Stack {...syntax} direction="row" spacing={1}>
              {actionButtons()}
            </Stack>
          )}
        />
      )}
    </Stack>
  );
}

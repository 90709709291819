import React from "react";
import { Typography, Stack } from "@mui/material";

export default function Form(props) {
  const {
    heading,
    subHeading,
    field1,
    field2,
    field3,
    field4,
    field5,
    field6,
    field7,
    primaryAction,
    secondaryAction,
  } = props;

  const handleSubmit = (e) => {
    e.preventDefault();
    props.onSubmit();
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={4}>
        <Stack>
          <Typography variant="h2" align="center" sx={{ fontWeight: 500 }}>
            {heading}
          </Typography>
          <Typography variant="subtitle1" align="center">
            {subHeading}
          </Typography>
        </Stack>

        <Stack spacing={3}>
          {field1}
          {field2}
          {field3}
          {field4}
          {field5}
          {field6}
          {field7}
        </Stack>
        <Stack spacing={2}>
          {primaryAction}
          {secondaryAction}
        </Stack>
      </Stack>
    </form>
  );
}
